import React, { useState, useEffect } from 'react';

const LoadingPage = () => {
  const [dots, setDots] = useState('');
  const [progressWidth, setProgressWidth] = useState(0);
  const [values, setValues] = useState({ neural: 0, data: 0, system: 0 });

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots(prev => (prev.length >= 3 ? '' : prev + '.'));
    }, 500);

    const progressInterval = setInterval(() => {
      setProgressWidth(prev => {
        if (prev >= 100) clearInterval(progressInterval);
        return prev < 100 ? prev + 1 : 100;
      });
    }, 50);

    const valueInterval = setInterval(() => {
      setValues(prev => ({
        neural: prev.neural < 92 ? prev.neural + 1 : 92,
        data: prev.data < 78 ? prev.data + 1 : 78,
        system: prev.system < 64 ? prev.system + 1 : 64
      }));
    }, 50);

    return () => {
      clearInterval(dotInterval);
      clearInterval(progressInterval);
      clearInterval(valueInterval);
    };
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.glowBackground}></div>
      <div style={styles.particleField}>
        {[...Array(30)].map((_, i) => (
          <div key={i} style={styles.particle(i)} className="particle"></div>
        ))}
      </div>
      
      <div style={styles.contentWrapper}>
        <div style={styles.neuralSphere}>
          <div style={styles.innerSphere}>
            <div style={styles.circuitPattern}></div>
          </div>
          <div style={styles.energyPulse}></div>
        </div>

        <h1 style={styles.title}>
          <span style={styles.titleGradient}>SYSTEM INITIALIZATION</span>
          <span style={styles.dots}>{dots}</span>
        </h1>
        
        <p style={styles.subtitle}>
          <span style={styles.glitchText}>ENGAGING COGNITIVE PROCESSORS</span>
        </p>

        <div style={styles.progressContainer}>
          <div style={{ ...styles.progressBar, width: `${progressWidth}%` }}>
            <div style={styles.progressGlow}></div>
          </div>
          <div style={styles.progressPulse}></div>
        </div>

        <div style={styles.infoContainer}>
          <div style={styles.infoCard}>
            <div style={styles.infoHeader}>
              <div style={styles.statusLed}></div>
              <span>NEURAL MATRIX</span>
            </div>
            <div style={styles.infoValue}>{values.neural}%</div>
            <div style={styles.graphLine}></div>
          </div>

          <div style={styles.infoCard}>
            <div style={styles.infoHeader}>
              <div style={styles.statusLed}></div>
              <span>DATA STREAMS</span>
            </div>
            <div style={styles.infoValue}>{values.data}%</div>
            <div style={styles.graphLine}></div>
          </div>

          <div style={styles.infoCard}>
            <div style={styles.infoHeader}>
              <div style={styles.statusLed}></div>
              <span>SYSTEM INTEGRITY</span>
            </div>
            <div style={styles.infoValue}>{values.system}%</div>
            <div style={styles.graphLine}></div>
          </div>
        </div>

        <div style={styles.codeMatrix}>
          {[...Array(30)].map((_, i) => (
            <span key={i} style={styles.codeCharacter}>
              {Math.random() > 0.5 ? '0' : '1'}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0a0a1a',
    overflow: 'hidden',
    color: '#fff',
    fontFamily: "'IBM Plex Mono', monospace",
  },
  glowBackground: {
    position: 'absolute',
    width: '150%',
    height: '150%',
    background: 'radial-gradient(circle at center, rgba(82, 52, 226, 0.2) 0%, transparent 60%)',
    animation: 'pulse 4s infinite alternate',
  },
  particleField: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  particle: (i) => ({
    position: 'absolute',
    width: 2,
    height: 2,
    backgroundColor: `hsl(${Math.random() * 360}, 100%, 70%)`,
    borderRadius: '50%',
    left: `${Math.random() * 100}%`,
    top: `${Math.random() * 100}%`,
    animation: `float ${10 + Math.random() * 20}s infinite`,
  }),
  neuralSphere: {
    position: 'relative',
    width: 120,
    height: 120,
    margin: '0 auto 30px',
    perspective: 1000,
  },
  innerSphere: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    background: 'radial-gradient(circle at 30% 30%, #4a22ff, #0a0a1a)',
    transformStyle: 'preserve-3d',
    animation: 'rotate 8s linear infinite',
  },
  circuitPattern: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: 'radial-gradient(circle, #fff 10%, transparent 20%)',
    backgroundSize: '20px 20px',
    opacity: 0.1,
  },
  energyPulse: {
    position: 'absolute',
    width: '130%',
    height: '130%',
    borderRadius: '50%',
    border: '2px solid #4a22ff',
    top: '-15%',
    left: '-15%',
    animation: 'pulseSphere 3s infinite',
  },
  title: {
    fontSize: '1.8rem',
    marginBottom: '15px',
    textAlign: 'center',
  },
  titleGradient: {
    background: 'linear-gradient(45deg, #4a22ff, #00f7ff)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: 'titleFlow 4s ease infinite',
  },
  dots: {
    color: '#00f7ff',
  },
  subtitle: {
    fontSize: '0.9rem',
    color: '#6c757d',
    marginBottom: '30px',
    textAlign: 'center',
    position: 'relative',
  },
  glitchText: {
    position: 'relative',
    '&:before, &:after': {
      content: 'attr(data-text)',
      position: 'absolute',
      left: 0,
      top: 0,
      color: '#fff',
      overflow: 'hidden',
    },
    '&:before': {
      left: '2px',
      textShadow: '-2px 0 #ff00c1',
      animation: 'glitch-1 2s infinite linear',
    },
    '&:after': {
      left: '-2px',
      textShadow: '2px 0 #00fff9',
      animation: 'glitch-2 2s infinite linear',
    },
  },
  progressContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    height: 12,
    borderRadius: 6,
    overflow: 'hidden',
    marginBottom: 30,
    position: 'relative',
  },
  progressBar: {
    height: '100%',
    background: 'linear-gradient(90deg, #4a22ff, #00f7ff)',
    transition: 'width 0.5s ease-out',
    position: 'relative',
  },
  progressGlow: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 20,
    background: 'linear-gradient(90deg, transparent, rgba(74, 34, 255, 0.5))',
  },
  progressPulse: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1))',
    animation: 'progressPulse 2s infinite',
  },
  infoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 20,
    marginBottom: 30,
  },
  infoCard: {
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: 8,
    padding: 15,
    backdropFilter: 'blur(5px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
  infoHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontSize: '0.8rem',
    color: '#6c757d',
    marginBottom: 10,
  },
  statusLed: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: '#00ff88',
    boxShadow: '0 0 8px #00ff88',
  },
  infoValue: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    background: 'linear-gradient(45deg, #4a22ff, #00f7ff)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  graphLine: {
    height: 2,
    background: 'rgba(255, 255, 255, 0.1)',
    marginTop: 10,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: -3,
      width: 8,
      height: 8,
      background: '#4a22ff',
      borderRadius: '50%',
    },
  },
  codeMatrix: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    opacity: 0.3,
    flexWrap: 'wrap',
  },
  codeCharacter: {
    color: '#00f7ff',
    animation: 'flicker 0.5s infinite',
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotateY(0deg)' },
    '100%': { transform: 'rotateY(360deg)' },
  },
  '@keyframes pulseSphere': {
    '0%': { opacity: 1, transform: 'scale(1)' },
    '100%': { opacity: 0, transform: 'scale(1.5)' },
  },
  '@keyframes titleFlow': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
  '@keyframes progressPulse': {
    '0%': { opacity: 0, left: '-100%' },
    '100%': { opacity: 0.3, left: '100%' },
  },
  '@keyframes float': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-20px)' },
    '100%': { transform: 'translateY(0)' },
  },
  '@keyframes flicker': {
    '0%': { opacity: 0.1 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.1 },
  },
};

export default LoadingPage;