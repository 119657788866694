import React from 'react'
import { Container } from 'react-bootstrap'

const Footer = () => {
  return (
    <Container className='footer text-center py-2'  
     style={{ 
      background: "linear-gradient(90deg, #0f0c29 0%, #1a1a4d 50%, #2c0753 100%)",
      borderBottom: "1px solid rgba(82, 235, 255, 0.2)",
      boxShadow: "0 4px 20px rgba(82, 235, 255, 0.1)"
    }}  fluid>
        <p className='m-0'> 
        @ 2025 Copyrights : Claims Cortex 
        </p>

    </Container>
  )
}

export default Footer