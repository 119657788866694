import React, { useRef } from 'react';
import Loader from './Loader';
import { Config } from '../../config';

const ActionProvider = ({ createChatBotMessage, setState, chatHistory, setChatHistory, isPlaying, children }) => {
    const baseURL = Config.REACT_APP_CHAT_API_BASE_URL;
    const chatHistoryRef = useRef(chatHistory);
    const messageContainerRef = useRef(null);
    const isPlayingRef = useRef(isPlaying);

    const handleMessage = async (message) => {
        const currentQuestion = localStorage.getItem('currentQuestion');
        var currentAnswer = localStorage.getItem('selectedAnswer');

        // Check if currentQuestion is a non-empty string
        const modifiedMessage = currentQuestion && typeof currentQuestion === 'string' && currentQuestion.trim() !== ''
            ? `{{${currentQuestion},${currentAnswer}}} ${message}`
            : message;
        
        console.log('modifiedMessage', modifiedMessage);
        
        setState((prevState) => {
            const chatHistory = [...chatHistoryRef.current];
            if (chatHistory.length === 0 || chatHistory[chatHistory.length - 1].assistant) {
                chatHistory.push({ user: modifiedMessage, assistant: '' });
            } else {
                chatHistory[chatHistory.length - 1].user = modifiedMessage;
            }
            chatHistoryRef.current = chatHistory;
            return prevState;
        });
        const loading = createChatBotMessage(<Loader />);
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, loading],
        }));
            
        const updateLastMessage = (modifiedMessage) => {
          setState((prev) => {
            return { 
              ...prev, 
              messages: [
                  ...prev.messages.slice(0, -1), 
                  { 
                      ...prev.messages.at(-1), 
                      message: <div style={{whiteSpace:"pre-line"}}>{modifiedMessage}</div>
                  }
              ]
          };
          });
        };


        try {
            let url =  `${baseURL}/chat`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: modifiedMessage,
                    chat_history: chatHistoryRef.current,
                }),
            });
            console.log('response', response);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let done = false;
            let botMessageContent = '';

            while (!done) {
                const { value, done: readerDone } = await reader.read();
                done = readerDone;
                const chunk = decoder.decode(value, { stream: true });
                console.log('chunk', chunk);
                if (chunk) {
                    // Remove the 'data: ' prefix and combine the message
                    const response1 = JSON.parse(chunk);
                    botMessageContent +=  response1['response']['answer'] ;
// eslint-disable-next-line
                    const botMessage = createChatBotMessage(<div><pre>{botMessageContent}</pre></div>, { type: 'bot', delay:-950});
                    console.log('botMessage', botMessage);
// eslint-disable-next-line
                    setState((prev) => { // Remove the loading message
                        const chatHistory = [...chatHistoryRef.current];
                        chatHistory[chatHistory.length - 1].assistant = botMessageContent;
                        chatHistoryRef.current = chatHistory;
                        updateLastMessage(botMessageContent)

                        return {
                            ...prev,
                            // messages: [...newPrevMsg, botMessage],
                            chatHistory: chatHistory,
                        };
                    });
                }
            }
            console.log('storage');
            localStorage.setItem('audio_url', "http://res.cloudinary.com/dzfz5d8a2/video/upload/v1725478191/akosieqzgk7s6balouft.mp3");

            setChatHistory(chatHistoryRef.current);
        } catch (error) {
            console.log('storage');
            localStorage.setItem('audio_url', "http://res.cloudinary.com/dzfz5d8a2/video/upload/v1725478191/akosieqzgk7s6balouft.mp3");

            console.error('Error fetching response:', error);
            const botMessage = createChatBotMessage('Sorry, I am having trouble understanding.', { messageType: 'bot' });
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages.slice(0, -1), botMessage],
            }));
        }
    };
    
    return (
        <div ref={messageContainerRef}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleMessage: handleMessage,
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
