'use client';

import React, { useState, useEffect } from 'react';
import { useNavigate as useBillingNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Card,
  Form,
  ListGroup,
  Table,
  OverlayTrigger,
  Tooltip,
  Badge
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css'; // Custom global styling
import { toast } from 'react-toastify';
import axios from 'axios';

// Services
import {
  getDocuments,
  uploadDocumentPDF,
  uploadDocumentESX,
  getXactimateEstimate,
  getCompletedJobs,
  getAllCompletedJobs,
  getEsxEstimate,
  uploadPDFToJob,
  uploadEsxToJob
} from "../services/uploads/upload.service";
import {
  getAccount,
  updateAccount,
  deleteJob,
  restartJob,
  completeJob,
  sendSupportEmail
} from "../services/account/account.service";

// Styles
import './ai-styles.css';
import '@fontsource/space-mono';

// Icons
import { FaUser, FaFolder, FaQuestionCircle, FaMoneyBillWave } from 'react-icons/fa';
import { BsBell, BsPersonCircle } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';

// Components
import XactimateChatbotWidget from './chatbot/XactimateChatbotWidget';
import logo from "../assets/logo-2.png";
import avatarIcon from "../assets/chat-bot.svg"; // Used in BillingSection

// ---------------------------------------------------------------------
// BillingSection Component
// ---------------------------------------------------------------------
const BillingSection = ({ email }) => {
  const accountId = localStorage.getItem('accountId');
  const navigate = useBillingNavigate();

  useEffect(() => {
    // Load Stripe Pricing Table script
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup: remove the script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="background-bg" style={{ margin: 0, color: 'white', minHeight: '100vh' }}>
      <Container
        className="center-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          borderBottom: '2px solid grey'
        }}
      >
        <div>
          <img
            src={avatarIcon}
            alt="Logo"
            style={{ height: '5vh' }}
          />
          <h1 className="mt-5 mb-4 text-black">CHOOSE YOUR PLAN</h1>
          <h3 className="mt-5 mb-4 text-black">Unlock Claims Cortex AI</h3>
        </div>

        <Nav variant="tabs" className=" home-nav justify-content-center">
          <h5
            className="text-center mb-4"
            style={{
              margin: '30px 0',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
              lineHeight: '1.5',
              color: 'lightgrey'
            }}
          >
            Elevate your business with a dynamic profile and watch your sales reach new heights.
          </h5>
        </Nav>
      </Container>
      <div className="background-bg" style={{ margin: '50px 0' }}>
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_ID}
          customer-email={email}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          client-reference-id={accountId}
        >
        </stripe-pricing-table>
      </div>
    </div>
  );
};

// ---------------------------------------------------------------------
// Main Component: EditAccount
// ---------------------------------------------------------------------
const EditAccount = () => {
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    oldPassword: '',
    newPassword: '',
    verifyNewPassword: '',
    tokenCount: 0,
    xactimateTokenCount: 0,
  });

  const [files, setFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState('editProfile');
  const [termsText, setTermsText] = useState('');
  const [selectedFiles, setSelectedFiles] = useState({
    pdf: null,
    esx: null
  });

  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken');
  const headers = {
    'Authorization': `${authToken}`,
    'Content-Type': 'application/json',
    'accountId': localStorage.getItem('accountId')
  };

  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [uploadErrors, setUploadErrors] = useState({});
  const [fileNameErrors, setFileNameErrors] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const accountId = localStorage.getItem('accountId');
  const loginURL =
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:3000/login"
      : "https://claimscortex.com/login";

  // State for Support tab
  const [supportText, setSupportText] = useState("");

  // ----------------------------------------------------------------
  // Check if user is admin
  // ----------------------------------------------------------------
  useEffect(() => {
    setIsAdmin(accountId === '1');
  }, [accountId]);

  // ----------------------------------------------------------------
  // Initial Data Fetch
  // ----------------------------------------------------------------
  useEffect(() => {
    handleGetUser();
    handleGetFiles();
  }, []);

  useEffect(() => {
    handleGetAllFiles();
  }, []);

  useEffect(() => {
    if (selectedOption === 'ToS') {
      fetch('/terms.txt')
        .then(response => {
          if (!response.ok) throw new Error('Failed to load terms text');
          return response.text();
        })
        .then(text => setTermsText(text))
        .catch(err => console.error(err));
    }
  }, [selectedOption]);

  // ----------------------------------------------------------------
  // Data-Fetching Helpers
  // ----------------------------------------------------------------
  const handleGetUser = async (isRefresh) => {
    if (profileData.firstName && profileData.firstName.trim() !== '' && !isRefresh) {
      return; // If there's already data, skip
    }
    const isAuthenticated = authToken && authToken.trim() !== '';
    if (isAuthenticated) {
      const accountId = localStorage.getItem('accountId');
      const requestData = { accountId: accountId };

      try {
        const response = await getAccount(JSON.stringify(requestData), headers);
        if (response && response.data) {
          setProfileData(prev => ({
            ...prev,
            firstName: response.data.account.FirstName || prev.firstName,
            lastName: response.data.account.LastName || prev.lastName,
            email: response.data.account.Email || prev.email,
            tokenCount: response.data.account.Tokens || prev.tokenCount,
            xactimateTokenCount: response.data.account.XactimateTokens || prev.xactimateTokenCount,
          }));
        } else {
          console.error("Invalid response data:", response);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    } else {
      navigate(loginURL);
    }
  };

  const handleGetFiles = async (isRefresh) => {
    if (files && files.length > 0 && !isRefresh) {
      return; // If there's already data, skip
    }
    try {
      const accountId = localStorage.getItem('accountId');
      const formData = new FormData();
      formData.append('accountId', accountId);

      const response = await getCompletedJobs(formData, {
        "Content-Type": "application/json"
      });

      if (response.data.message === "success" && response.data.completed_jobs) {
        const transformedFiles = response.data.completed_jobs.map((job, index) => ({
          id: index + 1,
          jobId: job.id,
          name: job.estimates_pdf_name,
          uploadedAt: job.upload_date.split('T')[0],
          status:
            job.job_status === 'completed'
              ? 'Completed'
              : job.job_status === 'started'
              ? 'In Progress'
              : job.job_status === 'failed'
              ? 'Queued'
              : job.job_status === 'written'
              ? 'Written'
              : job.job_status === 'writing'
              ? 'Writing'
              : job.job_status === 'under review'
              ? 'Under Review'
              : 'Queued'
        }));
        setFiles(transformedFiles);
      } else {
        console.error('Failed to fetch the jobs:', response.statusText);
        setFiles([]);
      }
    } catch (error) {
      console.error('Error fetching the jobs:', error);
      setFiles([]);
    }
  };

  const handleGetAllFiles = async (isRefresh) => {
    if (allFiles && allFiles.length > 0 && !isRefresh) {
      return; // If there's already data, skip
    }
    try {
      const accountId = localStorage.getItem('accountId');
      const formData = new FormData();
      formData.append('accountId', accountId);

      const response = await getAllCompletedJobs(formData, {
        "Content-Type": "application/json"
      });

      if (response.data.message === "success" && response.data.completed_jobs) {
        const transformedFiles = response.data.completed_jobs.map((job, index) => ({
          id: index + 1,
          jobId: job.id,
          name: job.estimates_pdf_name,
          uploadedAt: job.upload_date.split('T')[0],
          status:
            job.job_status === 'completed'
              ? 'Completed'
              : job.job_status === 'started'
              ? 'In Progress'
              : job.job_status === 'failed'
              ? 'Failed'
              : job.job_status === 'written'
              ? 'Written'
              : job.job_status === 'writing'
              ? 'Writing'
              : job.job_status === 'under review'
              ? 'Under Review'
              : 'Inactive'
        }));
        setAllFiles(transformedFiles);
      } else {
        console.error('Failed to fetch the jobs:', response.statusText);
        setAllFiles([]);
      }
    } catch (error) {
      console.error('Error fetching the jobs:', error);
      setAllFiles([]);
    }
  };

  // ----------------------------------------------------------------
  // Form Submit (Profile Update)
  // ----------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profileData.newPassword !== profileData.verifyNewPassword) {
      alert('Passwords do not match');
      return;
    }

    const isAuthenticated = authToken && authToken.trim() !== '';
    if (!isAuthenticated) {
      navigate(loginURL);
      return;
    }

    try {
      const response = await updateAccount(JSON.stringify(profileData), headers);
      if (response.status === 200) {
        alert('Account updated successfully');
      } else {
        alert('Failed to update account');
      }
    } catch (error) {
      console.error('Error during account update:', error);
    }
  };

  // ----------------------------------------------------------------
  // File / Job Actions
  // ----------------------------------------------------------------
  const handleViewFile = async (fileId, fileName) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': accountId,
      };
      const requestData = { accountId, jobId: fileId };

      const response = await getXactimateEstimate(requestData, viewHeaders, 'blob');
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } else {
        console.error('Failed to fetch the PDF');
      }
    } catch (error) {
      console.error('Error fetching the PDF:', error);
    }
  };

  const handleDeleteJob = async (fileId) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': accountId,
      };
      const requestData = { accountId, jobId: fileId };

      const response = await deleteJob(requestData, viewHeaders, 'json');
      if (response) {
        alert('Job deleted successfully!');
        setFiles(prevFiles => prevFiles.filter(file => file.jobId !== fileId));
      } else {
        alert('No job found or delete failed.');
      }
    } catch (error) {
      console.error('Error in handleDeleteJob:', error);
      alert('Error deleting the job. Check console for details.');
    }
  };

  const handleRestartJob = async (fileId) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': accountId,
      };
      const requestData = { accountId, jobId: fileId };

      const response = await restartJob(requestData, viewHeaders, 'json');
      if (response) {
        alert('Job restarted (set to inactive) successfully!');
        setFiles(prevFiles =>
          prevFiles.map(file =>
            file.jobId === fileId
              ? { ...file, status: 'Inactive' }
              : file
          )
        );
      } else {
        alert('Failed to restart job.');
      }
    } catch (error) {
      console.error('Error in handleRestartJob:', error);
      alert('Error restarting the job. Check console for details.');
    }
  };

  const handleDownloadDocuments = async (jobId) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': accountId,
      };
      const requestData = { accountId, jobId };

      const response = await getDocuments(requestData, viewHeaders, 'blob');

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `job_${jobId}_documents.zip`;
        a.click();

        window.URL.revokeObjectURL(url);
      } else {
        console.error('Failed to download documents');
        alert('Failed to download documents. Please try again later.');
      }
    } catch (error) {
      console.error('Error downloading documents:', error);
      alert('Error downloading documents: ' + (error.message || 'Unknown error'));
    }
  };

  const handleViewEsxFile = async (fileName, jobId) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': accountId,
      };
      const requestData = { accountId, jobId };

      const response = await getEsxEstimate(requestData, viewHeaders, 'blob');
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const fileNameWithoutPdf = fileName.replace('.pdf', '.esx');

        const a = document.createElement('a');
        a.href = url;
        a.download = fileNameWithoutPdf;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Failed to fetch the ESX file');
      }
    } catch (error) {
      console.error('Error fetching the ESX file:', error);
    }
  };

  const handleSupportRequest = (file) => {
    // Create a template with the file data
    const template = `
Support request for file: ${file.name}
Job ID: ${file.jobId}
Upload Date: ${file.uploadedAt}
Current Status: ${file.status}

More details by user here:
    `;
    // Set the support text to use in the support tab
    setSupportText(template);
    // Navigate to the support tab
    setSelectedOption('support');
  };

  // ---------------------------------
  // "God Mode" Logic
  // ---------------------------------
  const getBaseName = (filename) => {
    if (!filename) return '';
    return filename.replace(/\.[^/.]+$/, "");
  };

  const checkFilenamesMatch = (jobId) => {
    const jobFiles = selectedFiles[jobId] || {};
    if (!jobFiles.pdf || !jobFiles.esx) return null;

    const pdfBaseName = getBaseName(jobFiles.pdf.name);
    const esxBaseName = getBaseName(jobFiles.esx.name);
    return pdfBaseName === esxBaseName;
  };

  const handlePdfFileSelect = (e, jobId) => {
    const file = e.target.files[0];
    if (!file) return;

    setSelectedFiles(prev => {
      const updatedFiles = {
        ...prev,
        [jobId]: {
          ...(prev[jobId] || {}),
          pdf: file
        }
      };

      // Check filename match if both files are selected
      if (updatedFiles[jobId].pdf && updatedFiles[jobId].esx) {
        const pdfBaseName = getBaseName(updatedFiles[jobId].pdf.name);
        const esxBaseName = getBaseName(updatedFiles[jobId].esx.name);

        if (pdfBaseName !== esxBaseName) {
          setFileNameErrors(prev => ({
            ...prev,
            [jobId]: "File names must match (excluding extensions)"
          }));
        } else {
          setFileNameErrors(prev => {
            const updated = { ...prev };
            delete updated[jobId];
            return updated;
          });
        }
      }
      return updatedFiles;
    });
  };

  const handleEsxFileSelect = (e, jobId) => {
    const file = e.target.files[0];
    if (!file) return;

    setSelectedFiles(prev => {
      const updatedFiles = {
        ...prev,
        [jobId]: {
          ...(prev[jobId] || {}),
          esx: file
        }
      };

      // Check filename match if both files are selected
      if (updatedFiles[jobId].pdf && updatedFiles[jobId].esx) {
        const pdfBaseName = getBaseName(updatedFiles[jobId].pdf.name);
        const esxBaseName = getBaseName(updatedFiles[jobId].esx.name);

        if (pdfBaseName !== esxBaseName) {
          setFileNameErrors(prev => ({
            ...prev,
            [jobId]: "File names must match (excluding extensions)"
          }));
        } else {
          setFileNameErrors(prev => {
            const updated = { ...prev };
            delete updated[jobId];
            return updated;
          });
        }
      }
      return updatedFiles;
    });
  };

  const handleUploadDocuments = async (jobId) => {
    const jobFiles = selectedFiles[jobId] || {};
    if (!jobFiles.pdf || !jobFiles.esx) {
      toast.error("Please select both PDF and ESX files");
      return;
    }

    const pdfBaseName = getBaseName(jobFiles.pdf.name);
    const esxBaseName = getBaseName(jobFiles.esx.name);
    if (pdfBaseName !== esxBaseName) {
      toast.error("File names must match (excluding extensions)");
      return;
    }

    try {
      setUploadingFiles(prev => ({
        ...prev,
        [`${jobId}_pdf`]: true,
        [`${jobId}_esx`]: true
      }));

      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'accountId': accountId,
      };

      // Upload PDF
      const pdfFormData = new FormData();
      pdfFormData.append("id", jobId);
      pdfFormData.append("pdf", jobFiles.pdf);

      const pdfResponse = await uploadDocumentPDF(pdfFormData, viewHeaders);
      if (!pdfResponse || !pdfResponse.data || pdfResponse.data.message !== "success") {
        toast.error("Failed to upload PDF file");
        return;
      }

      // Upload ESX
      const esxFormData = new FormData();
      esxFormData.append("id", jobId);
      esxFormData.append("esx", jobFiles.esx);

      const esxResponse = await uploadDocumentESX(esxFormData, viewHeaders);
      if (!esxResponse || !esxResponse.data || esxResponse.data.message !== "success") {
        toast.error("Failed to upload ESX file");
        return;
      }

      setUploadedFiles(prev => ({
        ...prev,
        [`${jobId}_pdf`]: jobFiles.pdf.name,
        [`${jobId}_esx`]: jobFiles.esx.name
      }));

      // Clear selected files
      setSelectedFiles(prev => {
        const updated = { ...prev };
        delete updated[jobId];
        return updated;
      });

      toast.success("Documents uploaded successfully");
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error(`Upload failed: ${error.response?.data?.error || error.message}`);
    } finally {
      setUploadingFiles(prev => ({
        ...prev,
        [`${jobId}_pdf`]: false,
        [`${jobId}_esx`]: false
      }));
    }
  };

  const resetFileSelection = (jobId) => {
    setSelectedFiles(prev => {
      const updated = { ...prev };
      delete updated[jobId];
      return updated;
    });

    setFileNameErrors(prev => {
      const updated = { ...prev };
      delete updated[jobId];
      return updated;
    });
  };

  const handleCompleteJob = async (fileId) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'accountId': accountId,
      };

      const formData = new FormData();
      formData.append('id', fileId);
      formData.append('account_id', accountId);

      const response = await completeJob(formData, viewHeaders);
      if (response) {
        alert('Job set to complete successfully!');
        setFiles(prevFiles =>
          prevFiles.map(file =>
            file.jobId === fileId
              ? { ...file, status: 'Completed' }
              : file
          )
        );
      } else {
        alert('Failed to complete job.');
      }
    } catch (error) {
      console.error('Error in handleCompleteJob:', error);
      alert('Error completing the job. Check console for details.');
    }
  };

  // ----------------------------------------------------------------
  // Chatbot
  // ----------------------------------------------------------------
  const handleChatbotResponse = (final_output) => {
    console.log('Chatbot final_output:', final_output);
  };

  // ----------------------------------------------------------------
  // "Section" Title
  // ----------------------------------------------------------------
  const getSectionTitle = () => {
    switch (selectedOption) {
      case 'editProfile':
        return 'Edit Profile';
      case 'files':
        // RENAMED in UI to "Xactimate Agent Jobs"
        return 'Xactimate Agent Jobs';
      // case 'pdf2EsxAgentJobs':
      //   return 'Pdf 2 Esx Agent Jobs';
      case 'jobs':
        return 'Jobs';
      case 'billing':
        return 'Billing';
      case 'ToS':
        return 'Terms of Service';
      case 'chatbot':
        return 'Chat Bot';
      case 'godMode':
        return 'God Mode';
      case 'support':
        return 'Support';
      default:
        return '';
    }
  };

  // ----------------------------------------------------------------
  // Simple "Reload" Handler
  // ----------------------------------------------------------------
  const handleReload = async () => {
    await handleGetUser(true);
    await handleGetFiles(true);
    await handleGetAllFiles(true);
    toast.success('Data reloaded!');
  };

  // ----------------------------------------------------------------
  // Handle "Send Email" in Support Tab
  // ----------------------------------------------------------------
  const handleSendEmail = async () => {
    if (!supportText.trim()) {
      toast.error('Please describe your issue first.');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('supportText', supportText);
      const response = await sendSupportEmail(formData, {
        "Content-Type": "application/json"
      });
      if (response.data.message === "success") {
        console.log('Email sent successfully:', response.data);
      }
    }
    catch (error) {
      console.error('Error sending support email:', error);
    }
    toast.success('Email Sent! We will respond within 24 hours.');
    setSupportText('');
  };

  // ----------------------------------------------------------------
  // Render Tables for Each Tab
  // ----------------------------------------------------------------

  // Xactimate Agent Jobs
  const renderXactimateAgentJobs = () => {
    return (
      <>
        {files.length > 0 ? (
          <div className="table-responsive">
            <Table
              bordered
              hover
              className="align-middle shadow-sm"
              style={{ backgroundColor: '#fff' }}
            >
              <thead style={{ backgroundColor: '#f7f7f7' }}>
                <tr>
                  <th>#</th>
                  <th>File Name</th>
                  <th>Uploaded At</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {files
                  .filter(file =>
                    file.status === 'In Progress' ||
                    file.status === 'Inactive' ||
                    file.status === 'Completed' ||
                    file.status === 'Queued' ||
                    file.status === 'Written' ||
                    file.status === 'Writing'
                  )
                  .map((file, index) => (
                    <tr key={file.id}>
                      <td>{index + 1}</td>
                      <td>{file.name}</td>
                      <td>{file.uploadedAt}</td>
                      <td>
                        <Badge bg={
                          file.status === 'Completed'
                            ? 'success'
                            : file.status === 'Writing'
                            ? 'warning'
                            : file.status === 'Queued'
                            ? 'info'
                            : 'secondary'
                        }>
                          {file.status}
                        </Badge>
                      </td>
                      <td className="d-flex justify-content-center gap-2">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          disabled={
                            file.status === 'Inactive' ||
                            file.status === 'Under Review' ||
                            file.status === 'Queued' ||
                            file.status === 'In Progress'
                          }
                          onClick={() => handleViewFile(file.jobId, file.name)}
                        >
                          View
                        </Button>
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={() => handleViewEsxFile(file.name, file.jobId)}
                          disabled={file.status !== 'Completed'}
                        >
                          Download Esx
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleSupportRequest(file)}
                        >
                          Support
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <p>No Xactimate Agent Jobs found.</p>
        )}
      </>
    );
  };

  // Pdf 2 Esx Agent Jobs (New Tab) - everything disabled, "Coming soon" tooltip
  const renderPdf2EsxAgentJobs = () => {
    return (
      <>
        {files.length > 0 ? (
          <div className="table-responsive">
            <Table
              bordered
              hover
              className="align-middle shadow-sm"
              style={{ backgroundColor: '#fff' }}
            >
              <thead style={{ backgroundColor: '#f7f7f7' }}>
                <tr>
                  <th>#</th>
                  <th>File Name</th>
                  <th>Uploaded At</th>
                  <th>Status</th>
                  <th>Actions (Coming Soon)</th>
                </tr>
              </thead>
            </Table>
          </div>
        ) : (
          <p>No Pdf 2 Esx Agent Jobs found.</p>
        )}
      </>
    );
  };

  return (
    <Container fluid className="p-0" style={{ color: '#000' }}>
      {/* Top NavBar */}
      <Navbar className="px-3 py-2" style={{ borderBottom: '1px solid #e5e5e5', backgroundColor: 'rgb(25 26 39)', color:'#fff' }}>
        <div className="d-flex align-items-center w-100 justify-content-between" style={{ color:'#fff' }}>
          <div className="d-flex align-items-center gap-3" style={{ color:'#fff' }}>
            <strong className="ms-2" style={{ color:'#fff' }}>
              {profileData.firstName} {profileData.lastName}
            </strong>
          </div>
          <div className="d-flex align-items-center gap-3" style={{ color:'#fff' }}>
            <BsBell size={20} />
            <BsPersonCircle size={24} />
          </div>
        </div>
      </Navbar>

      <Row className="g-0" style={{ minHeight: '100vh', color:'#000' }}>
        {/* Left Sidebar Nav */}
        <Col xs={2} style={{ backgroundColor: 'rgb(25 26 39)' }}>
          <Nav className="flex-column py-4" variant="pills" fill>
            {/* Edit Profile */}
            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('editProfile')}
              active={selectedOption === 'editProfile'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaUser /> Edit Profile
            </Nav.Link>

            {/* Xactimate Agent Jobs */}
            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('files')}
              active={selectedOption === 'files'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaFolder /> Xactimate Agent Jobs
            </Nav.Link>

            {/* Pdf 2 Esx Agent Jobs */}
            
           
            {/* Terms of Service */}
            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('ToS')}
              active={selectedOption === 'ToS'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaQuestionCircle /> Terms of Service
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('billing')}
              active={selectedOption === 'billing'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaQuestionCircle /> Billing
            </Nav.Link>

            {/* Support */}
            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('support')}
              active={selectedOption === 'support'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaQuestionCircle /> Support
            </Nav.Link>

            {/* God Mode (Admin) */}
            {isAdmin && (
              <Nav.Link
                href="#"
                onClick={() => setSelectedOption('godMode')}
                active={selectedOption === 'godMode'}
                className="d-flex align-items-center gap-2"
                style={{ color: '#fff' }}
              >
                <FaFolder /> God Mode
              </Nav.Link>
            )}

            {/* Reload button */}
            <Nav.Link
              href="#"
              onClick={handleReload}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff', marginTop: '1rem' }}
            >
              ↻ Reload
            </Nav.Link>
          </Nav>
        </Col>

        {/* Main Content Area */}
        <Col xs={10} className="p-4" style={{ backgroundColor: '#f8f9fa', color:'#000' }}>
          <Row style={{ color:'#000' }}>
            {/* Content Based on Selected Option */}
            <Col xs={12} className="mx-auto" style={{ color:'#000' }}>
              <div className="bg-white p-4 rounded shadow-sm" style={{ color:'#000' }}> 
                <h5 className="mb-4" style={{ color:'#000' }}>{getSectionTitle()}</h5>

                {/* Edit Profile */}
                {selectedOption === 'editProfile' && (
                  <>
                    <Form onSubmit={handleSubmit} style={{ color:'#000' }}>
                      <Row className="mb-3" style={{ color:'#000' }}>
                        <Col>
                          <Form.Group controlId="formFirstName">
                            <Form.Label style={{ color:'#000' }}>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="firstName"
                              value={profileData.firstName}
                              onChange={handleChange}
                              style={{ color:'#000' }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formLastName">
                            <Form.Label style={{ color:'#000' }}>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="lastName"
                              value={profileData.lastName}
                              onChange={handleChange}
                              style={{ color:'#000' }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group controlId="formEmail" className="mb-3" style={{ color:'#000' }}>
                        <Form.Label style={{ color:'#000' }}>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={profileData.email}
                          disabled
                          style={{ color:'#000' }}
                        />
                      </Form.Group>

                      <Row className="mb-3" style={{ color:'#000' }}>
                        <Col>
                          <Form.Group controlId="formOldPassword">
                            <Form.Label style={{ color:'#000' }}>Old Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="oldPassword"
                              value={profileData.oldPassword}
                              onChange={handleChange}
                              style={{ color:'#000' }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formNewPassword">
                            <Form.Label style={{ color:'#000' }}>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="newPassword"
                              value={profileData.newPassword}
                              onChange={handleChange}
                              style={{ color:'#000' }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group controlId="formVerifyNewPassword" className="mb-3" style={{ color:'#000' }}>
                        <Form.Label style={{ color:'#000' }}>Verify New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="verifyNewPassword"
                          value={profileData.verifyNewPassword}
                          onChange={handleChange}
                          style={{ color:'#000' }}
                        />
                      </Form.Group>

                      <div className="d-flex align-items-center mb-3" style={{ color:'#000' }}>
                        <Form.Label className="mb-0 me-2" style={{ color:'#000' }}>
                          AI Tokens:
                        </Form.Label>
                        <span className="badge bg-secondary text-white">
                          {profileData.tokenCount}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-3" style={{ color:'#000' }}>
                        <Form.Label className="mb-0 me-2" style={{ color:'#000' }}>
                          Xactimate Agent Tokens:
                        </Form.Label>
                        <span className="badge bg-secondary text-white">
                          {profileData.xactimateTokenCount}
                        </span>
                      </div>
                      <Button variant="primary" type="submit" className="mt-3 gradient-bg">
                        Update Profile
                      </Button>
                    </Form>
                    {error && <div className="mt-4 text-danger">{error}</div>}
                  </>
                )}

                {/* Xactimate Agent Jobs */}
                {selectedOption === 'files' && renderXactimateAgentJobs()}

                {/* Pdf 2 Esx Agent Jobs */}
                {selectedOption === 'pdf2EsxAgentJobs' && renderPdf2EsxAgentJobs()}

                {/* Generic "Jobs" (if you have any future code) */}
                {selectedOption === 'jobs' && (
                  <div>
                    <p>Jobs section coming soon...</p>
                  </div>
                )}

                {/* Billing */}
                {selectedOption === 'billing' && (
                  <BillingSection email={profileData.email} />
                )}

                {/* ToS */}
                {selectedOption === 'ToS' && (
                  <div
                    className="terms-container"
                    style={{ maxHeight: '75rem', overflowY: 'auto' }}
                  >
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      {termsText}
                    </p>
                  </div>
                )}

                {/* Chatbot */}
                {selectedOption === 'chatbot' && (
                  <div>
                    <XactimateChatbotWidget onResponse={handleChatbotResponse} />
                  </div>
                )}

                {/* Support Tab */}
                {selectedOption === 'support' && (
                  <div>
                    <div
                      className="mb-4"
                      style={{
                        margin: '0 auto',
                        width: '80%',
                        padding: '2em',
                        backgroundColor: '#fefefe',
                        fontStyle: 'italic',
                        fontSize: '1.25rem',
                        textAlign: 'center',
                        lineHeight: '1.6',
                        whiteSpace: 'pre-line'
                      }}
                    >
                      {`
In whispered lines and gentle voice, 
we ask you now to share your plight:
A few short notes, a kindly verse,
will guide our help by morning's light.

We vow, within a single day,
your words we'll heed, your case we'll see,
and swift reply shall find its way
to bring you calm and clarity.
                      `}
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label>Describe Your Issue (few sentences)</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        value={supportText}
                        onChange={(e) => setSupportText(e.target.value)}
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleSendEmail}>
                      Email Us Now
                    </Button>
                  </div>
                )}

                {/* God Mode (show ALL files) */}
                {selectedOption === 'godMode' && isAdmin && (
                  <div className="divine-god-mode">
                    <div className="text-center mb-4">
                      <h4 className="divine-title">Divine Control Panel</h4>
                      <div className="divine-separator">
                        <span>⚡</span>
                      </div>
                      <p className="divine-subtitle">Manage jobs with the power of the heavens</p>
                    </div>

                    {allFiles.length > 0 ? (
                      <div className="table-responsive divine-table-container">
                        <Table striped bordered hover className="divine-table">
                          <thead>
                            <tr className="divine-header-row">
                              <th>#</th>
                              <th>File Name</th>
                              <th>Uploaded At</th>
                              <th>Status</th>
                              <th>PDF Upload</th>
                              <th>ESX Upload</th>
                              <th>Upload Documents</th>
                              <th>Download Documents</th>
                              <th>Complete Job</th>
                              <th>Restart</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allFiles
                            .filter(file => file.status === 'In Progress' || file.status === 'Inactive'  || file.status === 'Writing'|| file.status === 'Failed' || file.status === 'Under Review' || file.status === 'Written')
                            .map((file, index) => {
                              const filesMatch = checkFilenamesMatch(file.jobId);
                              const hasBothFiles = selectedFiles[file.jobId]?.pdf && selectedFiles[file.jobId]?.esx;

                              return (
                                <tr key={file.id} className="divine-row">
                                  <td>{index + 1}</td>
                                  <td>{file.name}</td>
                                  <td>{file.uploadedAt}</td>
                                  <td>
                                    <span className="divine-status">{file.status}</span>
                                  </td>

                                  {/* PDF */}
                                  <td>
                                    <Form.Group controlId={`pdfUpload-${file.jobId}`} className="mb-1">
                                      <Form.Control
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => handlePdfFileSelect(e, file.jobId)}
                                        size="sm"
                                        className="divine-file-input"
                                      />
                                    </Form.Group>
                                    {selectedFiles[file.jobId]?.pdf && (
                                      <div className="selected-file mt-1">
                                        <small>
                                          {selectedFiles[file.jobId].pdf.name}
                                        </small>
                                      </div>
                                    )}
                                  </td>

                                  {/* ESX */}
                                  <td>
                                    <Form.Group controlId={`esxUpload-${file.jobId}`} className="mb-1">
                                      <Form.Control
                                        type="file"
                                        accept=".esx"
                                        onChange={(e) => handleEsxFileSelect(e, file.jobId)}
                                        size="sm"
                                        className="divine-file-input"
                                      />
                                    </Form.Group>
                                    {selectedFiles[file.jobId]?.esx && (
                                      <div className="selected-file mt-1">
                                        <small>
                                          {selectedFiles[file.jobId].esx.name}
                                        </small>
                                      </div>
                                    )}
                                  </td>

                                  {/* Upload Documents */}
                                  <td>
                                    {fileNameErrors[file.jobId] && (
                                      <div className="text-danger small mb-2">
                                        <i className="fas fa-exclamation-triangle me-1"></i>
                                        {fileNameErrors[file.jobId]}
                                      </div>
                                    )}

                                    {hasBothFiles && filesMatch && (
                                      <div className="text-success small mb-2">
                                        <i className="fas fa-check-circle me-1"></i>
                                        Files match
                                      </div>
                                    )}

                                    <Button
                                      variant="primary"
                                      size="sm"
                                      className="divine-upload-btn"
                                      onClick={() => handleUploadDocuments(file.jobId)}
                                      disabled={!hasBothFiles || filesMatch === false}
                                    >
                                      <i className="fas fa-upload me-1"></i> Upload
                                    </Button>

                                    {(selectedFiles[file.jobId]?.pdf || selectedFiles[file.jobId]?.esx) && (
                                      <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        className="divine-reset-btn mt-1"
                                        onClick={() => resetFileSelection(file.jobId)}
                                      >
                                        <i className="fas fa-times me-1"></i> Reset
                                      </Button>
                                    )}
                                  </td>

                                  {/* Download Documents */}
                                  <td>
                                    <Button
                                      variant="outline-info"
                                      size="sm"
                                      className="divine-download-btn"
                                      onClick={() => handleDownloadDocuments(file.jobId)}
                                    >
                                      <i className="fas fa-cloud-download-alt me-1"></i> Download
                                    </Button>
                                  </td>

                                  {/* Complete Job */}
                                  <td className="text-center">
                                    <Button
                                      variant="outline-success"
                                      size="sm"
                                      className="divine-complete-btn"
                                      onClick={() => handleCompleteJob(file.jobId)}
                                    >
                                      <i className="fas fa-check-circle me-1"></i> Complete
                                    </Button>
                                  </td>

                                  {/* Restart Job */}
                                  <td className="text-center">
                                    <Button
                                      variant="outline-secondary"
                                      size="sm"
                                      onClick={() => handleRestartJob(file.jobId)}
                                    >
                                      Restart
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <div className="divine-empty-state">
                        <div className="divine-empty-icon">📋✨</div>
                        <p>No jobs found for Divine Intervention</p>
                      </div>
                    )}
                  </div>
                )}

                {/* Catch-all */}
                {selectedOption !== 'editProfile' &&
                  selectedOption !== 'files' &&
                  selectedOption !== 'pdf2EsxAgentJobs' &&
                  selectedOption !== 'jobs' &&
                  selectedOption !== 'billing' &&
                  selectedOption !== 'ToS' &&
                  selectedOption !== 'chatbot' &&
                  selectedOption !== 'godMode' &&
                  selectedOption !== 'support' && (
                    <div>
                      <p>Please select an option from the left menu.</p>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAccount;
