import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Navigation from "./routes/Navigation";
import Footer from "./components/Footer";
import ChatbotWidget from './components/chatbot/ChatbotWidget';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import './global.css';
import { isAuthenticated2 } from "./services/account/account.service";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Config } from './config';

// Add this CSS to your global.css or create a new stylesheet
const styles = `
  .pdf2esx-link {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    padding: 5px 15px !important;
    margin: 0 10px;
    font-weight: 600;
    transition: all 0.3s ease;
    background: linear-gradient(45deg, rgba(106, 90, 205, 0.2), rgba(147, 112, 219, 0.2));
    border: 1px solid rgba(106, 90, 205, 0.3);
  }

  .pdf2esx-link:hover {
    background: linear-gradient(45deg, rgba(106, 90, 205, 0.4), rgba(147, 112, 219, 0.4));
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(106, 90, 205, 0.3);
  }

  .pdf2esx-link::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #6a5acd, #9370db, #6a5acd);
    z-index: -1;
    border-radius: 10px;
    animation: pdf2esx-border-glow 3s ease-in-out infinite;
  }

  .pdf2esx-icon {
    margin-right: 8px;
    font-size: 16px;
    background: linear-gradient(45deg, #6a5acd, #9370db);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }

  .pdf2esx-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background: linear-gradient(45deg, #ff4081, #ff6b81);
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 12px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  @keyframes pdf2esx-border-glow {
    0%, 100% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.3;
    }
  }
`;

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const loginURL =  Config.REACT_LANDING_PAGE_URL;

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        console.log("authToken", authToken);
        const urlParams = new URLSearchParams(window.location.search);
        const authTokenFromURL = urlParams.get('token');

        // Prioritize URL token if present
        const tokenToValidate = authTokenFromURL || authToken;

        if (tokenToValidate) {
          const headers = {
            "Content-Type": "application/json"
          };

          const response = await isAuthenticated2({ authToken: tokenToValidate }, headers);
          
          // Check authentication and store tokens
          if (response.data.isAuthenticated) {
            setIsAuthenticated(true);
            localStorage.setItem('accountId', response.data.accountId);
            
            // Store token counts in localStorage
            localStorage.setItem('tokenCount', response.data.tokenCount);
            localStorage.setItem('tokenCountX', response.data.tokenCountX);
            
            // Update localStorage with validated token if URL token was used
            if (authTokenFromURL) {
              localStorage.clear();
              console.log("cleared");
              localStorage.setItem('authToken', authTokenFromURL);
              localStorage.setItem('accountId', response.data.accountId);
             navigate("/");
            }
          } else {
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Authentication check failed", error);
        setIsAuthenticated(false);
      } finally {
        setAuthLoading(false);
      }
    };

    checkAuthentication();
    
    // Add the styles to the document
    const styleElement = document.createElement('style');
    styleElement.innerHTML = styles;
    document.head.appendChild(styleElement);
    
    return () => {
      // Clean up the styles when component unmounts
      document.head.removeChild(styleElement);
    };
  }, []);

  const showStepper = location.pathname !== "/login";
  const loginText = "Logout" ;
  const editText = isAuthenticated ? "Edit Account" : "Create Account";
  const accountPath = isAuthenticated ? "/editAccount" : "/createAccount";

  const handleEditOrCreate = () => {
    navigate(isAuthenticated ? "/editaccount" : "/createaccount");
  };

  const handleLogout = () => {
    if (isAuthenticated) {
      // Logout logic
      localStorage.removeItem('authToken');
      localStorage.removeItem('accountId');
      setIsAuthenticated(false);
      window.location.href = loginURL;
    } else {
      window.location.href = loginURL;
    }
  };
  
  const handlePdf2EsxClick = () => {
    navigate("/pdf2esx");
  };

  if (authLoading) {
    return <div>Loading...</div>; // Optional: add a loading state
  }

  return (
    <>
      <Navbar 
        style={{ 
          background: "linear-gradient(90deg, #0f0c29 0%, #1a1a4d 50%, #2c0753 100%)",
          borderBottom: "1px solid rgba(82, 235, 255, 0.2)",
          boxShadow: "0 4px 20px rgba(82, 235, 255, 0.1)"
        }} 
        expand="lg" 
        className="position-relative ai-navbar"
      >
        <Container fluid>
          <Navbar.Brand 
            as={Link} 
            to="/" 
            className="navbar-brand-ai d-flex align-items-center"
          >
            <div className="ai-logo-container me-2">
              <div className="ai-logo"></div>
              <div className="ai-logo-pulse"></div>
            </div>
            <span className="gradient-text">Claims Cortex</span>
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-white" />
          
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="ml-auto d-flex align-items-center gap-3">
              {isAuthenticated && (
                <>
                  <Nav.Link 
                    as={Link} 
                    to="/" 
                    className="nav-link-ai"
                  >
                    <span className="nav-link-content">App</span>
                  </Nav.Link>

                  {/* New PDF2ESX Link with cool styling */}
                  {/* <Nav.Link 
                    onClick={handlePdf2EsxClick}
                    className="pdf2esx-link"
                  >
                    <span className="pdf2esx-icon">
                      <i className="fas fa-file-pdf"></i>
                    </span>
                    PDF2ESX
                    <span className="pdf2esx-badge">New</span>
                  </Nav.Link> */}

                  <Nav.Link 
                    onClick={handleEditOrCreate} 
                    className="nav-link-ai"
                  >
                    <span className="nav-link-content">Account</span>
                  </Nav.Link>
                </>
              )}
              {isAuthenticated && (
                <Button 
                  variant="custom" 
                  onClick={handleLogout}
                  className="ai-button"
                >
                  <span className="button-content">
                    {loginText}
                    <div className="button-hover-gradient"></div>
                  </span>
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navigation />
      {showStepper && isAuthenticated && (
        <ChatbotWidget />
      )}
      <Footer />
    </>
  );
};

export default App;