import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import { createAccount } from '../services/account/account.service';
import { useNavigate } from 'react-router-dom';
import AIImage from '../assets/AI_Img.png';
import { toast } from "react-toastify";


function CreateAccount() {
  const [formData, setFormData] = useState({
    firstName: '',
    password: '',
    address: '',
    lastName: '',
    email: '', 
    token: 0
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isFormDataValid = () => {
    var isValid = !Object.values(formData).some(value => value === '');
    return isValid;
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Creating account"); // Show loading toast

    try {
      const headers = {
        'Content-Type': 'application/json'
      };

      const response = await createAccount(JSON.stringify(formData), headers);
      if (response.status === 200) {
        toast.update(toastId, { render: "Account created successfully", type: "success", isLoading: false, autoClose: 500 });
     

        localStorage.setItem("authToken",  response.data["auth-token"]);
        localStorage.setItem("accountId",  response.data["accountId"]);
        navigate('/app');  // This will programmatically navigate to the app page
      }
    } catch (error) {
      toast.update(toastId, { render: "Error creating account", type: "error", isLoading: false, autoClose: 500 });

      console.error('Error during account creation:', error);
    }
  };

  return (
    <Container style={{ marginBottom: '100px', marginTop: '100px' }}>
      <Row>
        <Col md={6}>
          <Card className="p-4" style={{ height: '100%' }}>
            <h2 className="text-center mb-4">Create Your Profile</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="formFirstName">
                <Form.Label column sm={4}>First Name</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Enter your first name"
                  />
                </Col>
              </Form.Group>
              
              <Form.Group as={Row} className="mb-3" controlId="formLastName">
                <Form.Label column sm={4}>Last Name</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Enter your last name"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formPassword">
                <Form.Label column sm={4}>Password</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formAddress">
                <Form.Label column sm={4}>Address</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Enter your address"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formEmail">
                <Form.Label column sm={4}>Email</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                </Col>
              </Form.Group>
              <h5 className="text-center mb-4" style={{ margin: '30px 0', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: 'bold', lineHeight: '1.5', color: '#333' }}>
                Elevate your business with a dynamic profile and watch your sales reach new heights.
              </h5>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{ background: "linear-gradient(90deg, rgba(189, 38, 249, 1) 32%, rgba(82, 52, 226, 1) 100%)" }} 
                disabled={!isFormDataValid()}
              >
                Save
              </Button>
            </Form>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="p-4" style={{ height: '100%' }}>
              <img 
                src={AIImage} 
                alt="Promotional Image" 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
              />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateAccount;
