import { GetApiData, PostFormData } from "../../utils/http-client";
import axios from "axios";

// todo: replace with actual base URL
const api = axios.create({
  baseURL: "localhost:8000", // Replace with actual base URL
  // Add any other configuration as needed
});

  
export const getEstimate = async (headers) => {
    try {
      const response = await api.get(`/getEstimate`, { headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching estimate PDF:", error);
      throw error;
    }
  };

export const searchLineItems =  function (data,headers) {
  return PostFormData(`/searchLineItems`, 'POST', data, true,headers);
}

export const getCompletedJobs = function (data, headers) {
  return PostFormData(`/getCompletedJobs`, 'POST', data, true,headers);
}

export const getXactimateEstimate = function (data, headers, responseType) {
  return PostFormData(`/getXactimateEstimate`, 'POST', data, true, headers, responseType);
};

export const getXactimateEstimates =  function (data,headers) {
  return PostFormData(`/getXactimateEstimates`, 'POST', data, true,headers);
}


export const uploadPDF = function (data,headers) {
    return PostFormData(`/generateFromPDF`, 'POST', data, true,headers);
}

export const getAccessories = function (data,headers) {
  return PostFormData(`/getAccessories`, 'POST', data, true,headers);
}

export const getLineItems = function (data,headers) {
  return PostFormData(`/getLineItems`, 'POST', data, true,headers);
}

export const saveLineItems = async (data, headers) => {
  return PostFormData(`/saveLineItems`, 'POST', data, true, headers);
};

export const uploadAdjusterMail = function (data,headers) {
    return PostFormData(`/generateFromEmail`, 'POST', data, true,headers);
}
export const generateMailfromPDF = function (data,headers) {
    return PostFormData(`/emailPDF`, 'POST', data, true,headers);
}
export const generateMailfromText = function (data,headers) {
    return PostFormData(`/emailText`, 'POST', data, true,headers);
}
export const generateEstimate = function (data,headers) {
    return PostFormData(`/generateEstimate`, 'POST', data, true,headers);
}
export const generateAudioFile = function (data,headers) {
    return PostFormData(`/getAudioFile`, 'POST', data, true,headers);
}