import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css'; // Import the custom CSS for consistent styling
import { useNavigate } from 'react-router-dom';
import { getLineItems } from "../services/uploads/upload.service";
import { toast } from "react-toastify";

const EstimatePreview = ({ goToNextStep, estimatePdf, propertyPdf, goToStepThree }) => {
    const [page, setPage] = useState(1); // State to manage which page of the form is displayed
    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState({
        estimateType: 'xactimate',
        includeOverheadProfit: 'yes',
        roofingMaterial: '3 Tab Shingle',
        shingleLayers: 1,
        pipeJacks: 1,
        boxVents: 1,
        skylights: 1,
        chimneyFlashing: 'yes',
        reDecking: 'yes',
        guttersReplaced: 'yes',
        gutterGuards: 'None',
        sidingMaterial: "Vinyl",
        houseWrap: "no",
        fanfoldInsulation: "no",
        gableVents: 0,
        exteriorLights: 0,
        outletsSwitches: 0,
        dryerVents: 0,
        exteriorFaucets: 0,
        windowWraps: 0,
        shutterSets: 0,
        electricMeter: "no",
        tradeSelection: "Roofing"
    });

    const navigate = useNavigate();
    const headers = {
        "Content-Type": "multipart/form-data"
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleBack = () => {
        setPage(page - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("estimate_pdf", estimatePdf);
            formData.append("property_pdf", propertyPdf);

            // Convert camelCase to snake_case
            const toSnakeCase = (str) => str.replace(/([A-Z])/g, "_$1").toLowerCase();
            const toastId = toast.loading("Gathering Line Items..."); // Show loading toast

            // Append form data
            Object.entries(profileData).forEach(([key, value]) => {
                formData.append(toSnakeCase(key), value);
            });

            const response = await getLineItems(formData, headers);
            setLoading(false);
            toast.update(toastId, { render: "Line items were selected successfully. Please review", type: "success", isLoading: false, autoClose: 5000 });

            goToStepThree(2, estimatePdf, propertyPdf, response.data);

            // goToStepThree(2, estimatePdf, propertyPdf, null);
        } catch (error) {
            toast.error("Error uploading files. Please try again.");

            setLoading(false);
        }
    };

    return (
        <Container className="mt-5 container-profile" style={{ marginBottom: '120px', marginTop: '120px' }}>
            
            <Row className="justify-content-center" style={{ padding: "0" }}>
                <Col md={6}>
                    <Card className="card-profile">
                        <Card.Body className=" main-sub-card" >
                            <Card.Title className="text-center card-title" style={{ color: 'white' }}>Roofing Estimate Configuration</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                {page === 1 && (
                                    <>
                                        {/* Page 1 - First half of the inputs */}
                                        <Form.Group controlId="formEstimateType">
                                            <Form.Label>Estimate Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="estimateType"
                                                value={profileData.estimateType}
                                                onChange={handleChange}
                                            >
                                                <option value="xactimate">Xactimate</option>
                                                <option value="Symbility">Symbility(Coming Soon)</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formTradeSelection">
                                            <Form.Label>Which trade do you want us to write a new estimate for?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="tradeSelection"
                                                value={profileData.tradeSelection}
                                                onChange={handleChange}
                                            >
                                                <option value="Roofing">Roofing</option>
                                                <option value="Siding">Siding</option>
                                                <option value="Both">Both</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formIncludeOverheadProfit">
                                            <Form.Label>Should We Include Overhead and Profit?</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="includeOverheadProfit"
                                                    type="radio"
                                                    value="yes"
                                                    checked={profileData.includeOverheadProfit === 'yes'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="includeOverheadProfit"
                                                    type="radio"
                                                    value="no"
                                                    checked={profileData.includeOverheadProfit === 'no'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formRoofingMaterial">
                                            <Form.Label>What is the roofing material?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="roofingMaterial"
                                                value={profileData.roofingMaterial}
                                                onChange={handleChange}
                                            >
                                                <option value="3 Tab Shingle">3 Tab Shingle</option>
                                                <option value="Architectural Shingles">Architectural Shingles</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formShingleLayers">
                                            <Form.Label>How many layers of shingles are there?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="shingleLayers"
                                                value={profileData.shingleLayers}
                                                onChange={handleChange}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formPipeJacks">
                                            <Form.Label>How many Pipe jacks?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="pipeJacks"
                                                value={profileData.pipeJacks}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formBoxVents">
                                            <Form.Label>How many Box Vents?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="boxVents"
                                                value={profileData.boxVents}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formSkylights">
                                            <Form.Label>How many Skylights?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="skylights"
                                                value={profileData.skylights}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formChimneyFlashing">
                                            <Form.Label>Is there chimney flashing?</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="chimneyFlashing"
                                                    type="radio"
                                                    value="yes"
                                                    checked={profileData.chimneyFlashing === 'yes'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="chimneyFlashing"
                                                    type="radio"
                                                    value="no"
                                                    checked={profileData.chimneyFlashing === 'no'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </>
                                )}
                                {page === 2 && (
                                    <>
                                        {/* Page 2 - Second half of the inputs */}
                                        <Form.Group controlId="formReDecking">
                                            <Form.Label>Does the roof need to be re-decked?</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="reDecking"
                                                    type="radio"
                                                    value="yes"
                                                    checked={profileData.reDecking === 'yes'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="reDecking"
                                                    type="radio"
                                                    value="no"
                                                    checked={profileData.reDecking === 'no'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formGuttersReplaced">
                                            <Form.Label>Are the Gutters Being Replaced?</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="guttersReplaced"
                                                    type="radio"
                                                    value="yes"
                                                    checked={profileData.guttersReplaced === 'yes'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="guttersReplaced"
                                                    type="radio"
                                                    value="no"
                                                    checked={profileData.guttersReplaced === 'no'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formGutterGuards">
                                            <Form.Label>What type are the gutter guards?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="gutterGuards"
                                                value={profileData.gutterGuards}
                                                onChange={handleChange}
                                            >
                                                <option value="None">None</option>
                                                <option value="Standard">Standard</option>
                                                <option value="Premium">Premium</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formSidingMaterial">
                                            <Form.Label>Siding Material</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="sidingMaterial"
                                                value={profileData.sidingMaterial}
                                                onChange={handleChange}
                                            >
                                                <option value="Vinyl">Vinyl</option>
                                                <option value="Aluminum">Aluminum</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formHouseWrap">
                                            <Form.Label>House Wrap</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="houseWrap"
                                                    type="radio"
                                                    value="yes"
                                                    checked={profileData.houseWrap === 'yes'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="houseWrap"
                                                    type="radio"
                                                    value="no"
                                                    checked={profileData.houseWrap === 'no'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formFanfoldInsulation">
                                            <Form.Label>Fanfold Insulation</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="fanfoldInsulation"
                                                    type="radio"
                                                    value="yes"
                                                    checked={profileData.fanfoldInsulation === 'yes'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="fanfoldInsulation"
                                                    type="radio"
                                                    value="no"
                                                    checked={profileData.fanfoldInsulation === 'no'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="formGableVents">
                                            <Form.Label>How many Gable Vents?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="gableVents"
                                                value={profileData.gableVents}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formExteriorLights">
                                            <Form.Label>How many Exterior Lights?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="exteriorLights"
                                                value={profileData.exteriorLights}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formOutletsSwitches">
                                            <Form.Label>How many Outlets or Switches?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="outletsSwitches"
                                                value={profileData.outletsSwitches}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formDryerVents">
                                            <Form.Label>How many Dryer Vents?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="dryerVents"
                                                value={profileData.dryerVents}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formExteriorFaucets">
                                            <Form.Label>How many Exterior Faucets?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="exteriorFaucets"
                                                value={profileData.exteriorFaucets}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formWindowWraps">
                                            <Form.Label>How many Aluminum Window Wraps?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="windowWraps"
                                                value={profileData.windowWraps}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formShutterSets">
                                            <Form.Label>How many Sets of Shutters?</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="shutterSets"
                                                value={profileData.shutterSets}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formElectricMeter">
                                            <Form.Label>Is there an Electric Meter?</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="electricMeter"
                                                    type="radio"
                                                    value="yes"
                                                    checked={profileData.electricMeter === 'yes'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="electricMeter"
                                                    type="radio"
                                                    value="no"
                                                    checked={profileData.electricMeter === 'no'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </>
                                )}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={10} className="mx-auto my-4 d-flex justify-content-center align-items-center">
    {page === 1 && (
        <Button
            className="step-button border-0 px-4 py-2"
            onClick={handleNext}
        >
            <p className="m-0">NEXT STEP</p>
        </Button>
    )}
    {page === 2 && (
        <>
            <Button
                className="step-button border-0 px-4 py-2 mr-2"
                onClick={handleBack}
            >
                <p className="m-0">BACK</p>
            </Button>
            <Button
                className="step-button border-0 px-4 py-2"
                onClick={handleSubmit}
            >
                <p className="m-0">SUBMIT</p>
            </Button>
        </>
    )}
</Col>
            </Row>
        </Container>
    );
};

export default EstimatePreview;
