import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../components/Login';
import Billing from '../components/Billing';
import CreateAccount from '../components/CreateAccount';
import PrivateRoute from './PrivateRoute';
import EditAccount from '../components/EditAccount';
import XactimatePreviewPage from '../components/XactimatePreviewPage'; 
import EstimateStepper from '../components/XactimateStepper';
import StartPage from '../components/StartPage';
import Pdf2Esx from '../components/Pdf2Esx';
import PublicRoute from './PublicRoute';
import SemiPrivateRoute from './SemiPrivateRoute';
import MaintenancePage from '../components/Maintenance';
import Stepper from '../components/Stepper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../global.css'; // Import the custom CSS
const Navigation = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/login" element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } /> */}
        {/* <Route path="/pdf2esx" element={
          <PrivateRoute>
            <Pdf2Esx/>
          </PrivateRoute>
        } /> */}
        <Route path="/editAccount" element={
          <PrivateRoute>
            <EditAccount/>
          </PrivateRoute>
        } />

        <Route path="/estimateStepper" element={
          <PrivateRoute>
            <EstimateStepper/>
          </PrivateRoute>
        } />
          <Route path="/billing" element={
          <PrivateRoute>
            <Billing/>
          </PrivateRoute> 
        } />
      
        <Route path="/" element={
          <SemiPrivateRoute>
            <StartPage />
          </SemiPrivateRoute>
        } />
        <Route path="/createAccount" element={
          <PublicRoute>
            <CreateAccount />
          </PublicRoute>
        } />
        <Route path="/negotiator" element={
          <SemiPrivateRoute>
            <Stepper />
          </SemiPrivateRoute>
        } />
        {/* <Route path="/" element={
          <PublicRoute>
            <WelcomePage />
          </PublicRoute>
        } /> */}
        <Route path="/maintenance" element={
          <PublicRoute>
            <XactimatePreviewPage/>
          </PublicRoute>
        } />
       
      </Routes>
      <ToastContainer />
    </>
  );
};

export default Navigation;
