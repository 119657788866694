import React from "react";
import { useLocation } from "react-router-dom";
import Navigation from "./routes/Navigation";
import Footer from "./components/Footer";
import ChatbotWidget from './components/chatbot/ChatbotWidget';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import './global.css'; // Import the custom CSS
import { useNavigate } from "react-router-dom";
import ChatbotWidgetTraining from './components/chatbot/ChatbotWidgetTraining';

import 'bootstrap/dist/css/bootstrap.min.css';
// import { ChatBotWidget } from "chatbot-widget-ui";
const App = () => {
  const location = useLocation();
  const showStepper = location.pathname !== "/login";
  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken');
  const isAuthenticated = authToken && authToken.trim() !== '';
  const loginText = isAuthenticated ? "Logout" : "Login";
  const editText = isAuthenticated ? "Edit Account" : "Create Account";
  const accountPath = isAuthenticated ? "/editAccount" : "/createAccount";
  let isQuiz =  localStorage.getItem('IsQuiz') === true.toString();
  const showTrainingTerminal =  localStorage.getItem('showTrainingTerminal') === true.toString();

  const handleEditOrCreate = () => {
    if (isAuthenticated) {
      navigate("/editaccount");
    } else {
      navigate("/createaccount");
    }
  };

  const handleLoginOrLogout = () => {
    // Handle logout logic here
    // Clearing the token on logout
    if (loginText === "Logout") {
      localStorage.removeItem('authToken');
      localStorage.removeItem('accountId');
      navigate("/");
    } else if (loginText === "Login") {
      navigate("/login");
    }
  };

  return (
    <>
      <Navbar style={{ background: "linear-gradient(90deg, rgba(189, 38, 249, 1) 32%, rgba(82, 52, 226, 1) 100%)" }} 
      expand="lg" className="position-relative">
        <Container fluid>
          <Navbar.Brand href="/" style={{ color: "White" }} className="navbar-center">Claims Cortex</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="ml-auto d-flex align-items-center">
              {isAuthenticated && (
                <>
                  <Nav.Link href="/app" className="text-white">
                    Negotiator
                  </Nav.Link>
                  <Nav.Link href="/estimateStepper" className="text-white">
                    XActiwriter
                  </Nav.Link>
                  <Nav.Link href="/billing" className="text-white">
                    Billing
                  </Nav.Link>
                  <Nav.Link onClick={handleEditOrCreate} href="/editAccount" className="text-white">
                    Account
                  </Nav.Link>
                </>
              )}
              {/* <Nav.Link onClick={handleEditOrCreate} className="text-white">{editText}</Nav.Link> */}
              <Button variant="outline-light" onClick={handleLoginOrLogout}>{loginText}</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navigation />
      {showStepper && isAuthenticated && (
        <ChatbotWidget />

      )}
      
      <Footer />
    </>
  );
};

export default App;