// XactimateActionProvider.jsx

import React, { useRef } from 'react';
import Loader from './Loader';
import { Config } from '../../config';

const XactimateActionProvider = ({
  createChatBotMessage,
  setState,
  chatHistory,
  setChatHistory,
  onResponse, // Ensure onResponse is accepted as a prop
  estimatePdf,
  propertyPdf,
  children,
}) => {
  const baseURL = Config.REACT_APP_CHAT_API_BASE_URL;
  const chatHistoryRef = useRef(chatHistory);

  const handleMessage = async (message) => {
    const currentQuestion = localStorage.getItem('currentQuestion');
    const currentAnswer = localStorage.getItem('selectedAnswer');

    // Check if currentQuestion is a non-empty string
    const modifiedMessage =
      currentQuestion && currentQuestion.trim() !== ''
        ? `{{${currentQuestion},${currentAnswer}}} ${message}`
        : message;

    console.log('modifiedMessage', modifiedMessage);

    // Update chat history with the user's message
    setState((prevState) => {
      const updatedChatHistory = [...chatHistoryRef.current];
      if (
        updatedChatHistory.length === 0 ||
        updatedChatHistory[updatedChatHistory.length - 1].assistant
      ) {
        updatedChatHistory.push({ user: modifiedMessage, assistant: '' });
      } else {
        updatedChatHistory[updatedChatHistory.length - 1].user = modifiedMessage;
      }
      chatHistoryRef.current = updatedChatHistory;
      return prevState;
    });

    // Add a loading message
    const loadingMessage = createChatBotMessage(<Loader />);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, loadingMessage],
    }));

    const updateLastMessage = (content) => {
      setState((prev) => ({
        ...prev,
        messages: [
          ...prev.messages.slice(0, -1),
          {
            ...prev.messages.at(-1),
            message: <div style={{ whiteSpace: 'pre-line' }}>{content}</div>,
          },
        ],
      }));
    };

    try {
      const url = `${baseURL}/xchat`;

      // Create a FormData object and append necessary fields and files
      const formData = new FormData();
      formData.append('query', modifiedMessage);
      formData.append('chat_history', JSON.stringify(chatHistoryRef.current));
      formData.append('estimate', estimatePdf);
      formData.append('measurements', JSON.stringify(propertyPdf));
        console.log('measurements', propertyPdf);
      const response = await fetch(url, {
        method: 'POST',
        // Do not set 'Content-Type' header; the browser will set it automatically
        body: formData,
      });

      console.log('response', response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let doneReading = false;
      let actionOutput = '';
      let finalOutput = '';

      while (!doneReading) {
        const { value, done } = await reader.read();
        doneReading = done;
        const chunk = decoder.decode(value, { stream: true });
        console.log('chunk', chunk);

        if (chunk) {
          try {
            const responseJson = JSON.parse(chunk);

            // Handle action_output
            if (responseJson.action_output) {
              actionOutput += responseJson.action_output;

              const botMessage = createChatBotMessage(
                <div>
                  <pre>{responseJson.action_output}</pre>
                </div>,
                { type: 'bot', delay: -950 }
              );

              console.log('botMessage', botMessage);

              setState((prev) => {
                const updatedChatHistory = [...chatHistoryRef.current];
                updatedChatHistory[updatedChatHistory.length - 1].assistant =
                  actionOutput;
                chatHistoryRef.current = updatedChatHistory;
                updateLastMessage(actionOutput);

                return {
                  ...prev,
                  chatHistory: updatedChatHistory,
                };
              });
            }

            // Handle final_output
            if (responseJson.final_output) {
              finalOutput += responseJson.final_output;
              console.log('finalOutput', finalOutput);
            }
          } catch (error) {
            console.error('Error parsing chunk:', error);
          }
        }
      }

      // Invoke the callback function with final_output
      if (onResponse && finalOutput) {
        onResponse(finalOutput);
      }

      setChatHistory(chatHistoryRef.current);
    } catch (error) {
      console.error('Error fetching response:', error);

      // Update chat with an error message
      const errorMessage = createChatBotMessage(
        'Sorry, I am having trouble understanding.',
        { messageType: 'bot' }
      );

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages.slice(0, -1), errorMessage],
      }));
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          actions: {
            handleMessage: handleMessage,
          },
        })
      )}
    </div>
  );
};

export default XactimateActionProvider;
