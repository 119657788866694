import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css'; // Import the custom CSS for consistent styling
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { getLineItems } from '../services/uploads/upload.service';

const EstimatePreview = ({ goToNextStep, estimatePdf, propertyPdf, goToStepThree, customEstimateData }) => {
    const [page, setPage] = useState(1); // State to manage which page of the form is displayed
    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState({
        estimateType: 'xactimate',
        includeOverheadProfit: 'True',
        roofingMaterial: '3 Tab Shingle',
        shingleLayers: 1,
        pipeJacks: 0,
        boxVents: 0,
        skylights: 0,
        solarPanels: 0,
        satellites: 0,
        chimneyFlashing: 'yes',
        reDecking: 'yes',
        guttersReplaced: 'no',
        gutterGuards: 'None',
        sidingMaterial: "Vinyl",
        houseWrap: "no",
        fanfoldInsulation: "no",
        gableVents: 0,
        exteriorLights: 0,
        outletsSwitches: 0,
        dryerVents: 0,
        exteriorFaucets: 0,
        windowWraps: 0,
        shutterSets: 0,
        electricMeter: "no",
        tradeSelection: "Roofing"
    });
    
    const navigate = useNavigate();
    const headers = {
        "Content-Type": "multipart/form-data"
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };
    const [propertyPdfUrl, setPropertyPdfUrl] = useState(null);

    useEffect(() => {
      if (propertyPdf) {
        const url = URL.createObjectURL(propertyPdf);
        setPropertyPdfUrl(url);
  
        // Optional cleanup to free memory
        return () => URL.revokeObjectURL(url);
      }
    }, [propertyPdf]);
    // Improved navigation logic that respects trade selection
    const handleNext = () => {
        if (page === 1) {
            // If on page 1 and user selected "Siding", skip to page 3
            if (profileData.tradeSelection === "Siding") {
                setPage(3);
            } else {
                // Otherwise go to page 2 (Roofing details)
                setPage(2);
            }
        } else {
            // Normal increment for other pages
            setPage(page + 1);
        }
    };

    const handleBack = () => {
        if (page === 3 && profileData.tradeSelection === "Siding") {
            // If on siding page and trade is Siding only, go back to page 1
            setPage(1);
        } else {
            // Normal decrement for other cases
            setPage(page - 1);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const formData = new FormData();
            
            console.log("estimatePdf", estimatePdf);
            formData.append("estimate_pdf", estimatePdf);
            formData.append("property_pdf", propertyPdf);
            
            // Convert camelCase to snake_case
            const toSnakeCase = (str) => str.replace(/([A-Z])/g, "_$1").toLowerCase();
            const toastId = toast.loading("Gathering Line Items..."); // Show loading toast

            // Append form data
            Object.entries(profileData).forEach(([key, value]) => {
                formData.append(toSnakeCase(key), value);
            });

            const response = await getLineItems(formData, headers);
            setLoading(false);
            toast.update(toastId, { render: "Line items were selected successfully. Please review", type: "success", isLoading: false, autoClose: 5000 });
            console.log(response.data);

            goToStepThree(2, estimatePdf, propertyPdf, response.data);
        } catch (error) {
            toast.error("Error uploading files. Please try again.");
            setLoading(false);
        }
    };

    // Determine if current page is the final page based on trade selection
    const isFinalPage = () => {
        if (profileData.tradeSelection === "Roofing") {
            return page === 2;
        } else if (profileData.tradeSelection === "Siding") {
            return page === 3;
        } else if (profileData.tradeSelection === "Both") {
            return page === 3;
        }
        return false;
    };

    // Helper to determine which page titles to show
    const getPageTitle = () => {
        if (page === 1) return "Estimate Configuration";
        if (page === 2) return "Roofing Details";
        if (page === 3) return "Siding Details";
        return "";
    };

    // For development: if backend is not available, create a mock function
    const handleMockSubmit = () => {
        const mockResponse = { data: { line_items: [] } };
        goToStepThree(2, estimatePdf, propertyPdf, mockResponse.data);
    };

    return (
        <Container
        className="mt-5 container-profile"
        style={{ marginBottom: '120px', marginTop: '120px', minHeight: '70vh' }}
      >
        <Row>
          {/* Left column: Multi-step form */}
          <Col md={6}>
            <Row className="justify-content-center" style={{ padding: "0" }}>
              <Col md={12}>
                <Card className="card-profile">
                  <Card.Body className="main-sub-card">
                    <Card.Title
                      className="text-center card-title"
                      style={{ color: 'white' }}
                    >
                      {getPageTitle()}
                    </Card.Title>
  
                    <Form onSubmit={handleSubmit}>
                      {page === 1 && (
                        <>
                          <Form.Group
                            controlId="formEstimateType"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Which Type Of Estimate Do You Want Written?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="estimateType"
                              value={profileData.estimateType}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                            >
                              <option value="xactimate">Xactimate</option>
                              <option value="Symbility" disabled>
                                Symbility (Coming Soon)
                              </option>
                            </Form.Control>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formTradeSelection"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Which trade do you want us to write a new estimate for?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="tradeSelection"
                              value={profileData.tradeSelection}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                            >
                              <option value="Roofing">Roofing</option>
                              <option value="Siding">Siding</option>
                              <option value="Both">Both</option>
                              <option value="Interior" disabled>
                                Interior (Coming Soon)
                              </option>
                            </Form.Control>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formIncludeOverheadProfit"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Should We Include Overhead and Profit?
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                label="Yes"
                                name="includeOverheadProfit"
                                type="radio"
                                value="True"
                                id="overhead-yes"
                                checked={profileData.includeOverheadProfit === 'True'}
                                onChange={handleChange}
                                className="me-4"
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="includeOverheadProfit"
                                type="radio"
                                value="False"
                                id="overhead-no"
                                checked={profileData.includeOverheadProfit === 'False'}
                                onChange={handleChange}
                              />
                            </div>
                          </Form.Group>
                        </>
                      )}
  
                      {page === 2 && (
                        <>
                          <Form.Group
                            controlId="formRoofingMaterial"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Which Roofing Material Would You Like On The Estimate?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="roofingMaterial"
                              value={profileData.roofingMaterial}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                            >
                              <option value="3 Tab Shingle">3 Tab Shingle</option>
                              <option value="Architectural Shingles">
                                Architectural Shingles
                              </option>
                            </Form.Control>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formShingleLayers"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many layers of shingles are currently on the roof?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="shingleLayers"
                              value={profileData.shingleLayers}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </Form.Control>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formPipeJacks"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Pipe jacks are currently on the roof?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="pipeJacks"
                              value={profileData.pipeJacks}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formBoxVents"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Box Vents are currently on the roof?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="boxVents"
                              value={profileData.boxVents}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formSkylights"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Skylights are currently on the roof?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="skylights"
                              value={profileData.skylights}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formSolarPanels"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Solar Panels are currently on the roof?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="solarPanels"
                              value={profileData.solarPanels}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formSatellites"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Satellites are currently on the roof?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="satellites"
                              value={profileData.satellites}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formChimneyFlashing"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Is there chimney flashing currently on the roof?
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                label="Yes"
                                name="chimneyFlashing"
                                type="radio"
                                value="yes"
                                id="chimney-yes"
                                checked={profileData.chimneyFlashing === 'yes'}
                                onChange={handleChange}
                                className="me-4"
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="chimneyFlashing"
                                type="radio"
                                value="no"
                                id="chimney-no"
                                checked={profileData.chimneyFlashing === 'no'}
                                onChange={handleChange}
                              />
                            </div>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formReDecking"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Does the roof need to be re-decked?
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                label="Yes"
                                name="reDecking"
                                type="radio"
                                value="yes"
                                id="redecking-yes"
                                checked={profileData.reDecking === 'yes'}
                                onChange={handleChange}
                                className="me-4"
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="reDecking"
                                type="radio"
                                value="no"
                                id="redecking-no"
                                checked={profileData.reDecking === 'no'}
                                onChange={handleChange}
                              />
                            </div>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formGuttersReplaced"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Are the Gutters Being Replaced?
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                label="Yes"
                                name="guttersReplaced"
                                type="radio"
                                value="yes"
                                id="gutters-yes"
                                checked={profileData.guttersReplaced === 'yes'}
                                onChange={handleChange}
                                className="me-4"
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="guttersReplaced"
                                type="radio"
                                value="no"
                                id="gutters-no"
                                checked={profileData.guttersReplaced === 'no'}
                                onChange={handleChange}
                              />
                            </div>
                          </Form.Group>
  
                          {profileData.guttersReplaced === 'yes' && (
                            <Form.Group
                              controlId="formGutterGuards"
                              className="mb-4 p-3 bg-purple-light rounded border"
                            >
                              <Form.Label className="fw-bold mb-2">
                                What type are the gutter guards?
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="gutterGuards"
                                value={profileData.gutterGuards}
                                onChange={handleChange}
                                className="mt-2 border-primary"
                              >
                                <option value="None">None</option>
                                <option value="Standard">Standard</option>
                                <option value="Premium">Premium</option>
                              </Form.Control>
                            </Form.Group>
                          )}
                        </>
                      )}
  
                      {page === 3 && (
                        <>
                          <Form.Group
                            controlId="formSidingMaterial"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Which Siding Material Would You Like On The Estimate?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="sidingMaterial"
                              value={profileData.sidingMaterial}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                            >
                              <option value="Vinyl">Vinyl</option>
                              <option value="Aluminum">Aluminum</option>
                            </Form.Control>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formHouseWrap"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Would You Like House Wrap On The Estimate?
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                label="Yes"
                                name="houseWrap"
                                type="radio"
                                value="yes"
                                id="housewrap-yes"
                                checked={profileData.houseWrap === 'yes'}
                                onChange={handleChange}
                                className="me-4"
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="houseWrap"
                                type="radio"
                                value="no"
                                id="housewrap-no"
                                checked={profileData.houseWrap === 'no'}
                                onChange={handleChange}
                              />
                            </div>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formFanfoldInsulation"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Would You Like Fanfold Insulation On The Estimate?
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                label="Yes"
                                name="fanfoldInsulation"
                                type="radio"
                                value="yes"
                                id="fanfold-yes"
                                checked={profileData.fanfoldInsulation === 'yes'}
                                onChange={handleChange}
                                className="me-4"
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="fanfoldInsulation"
                                type="radio"
                                value="no"
                                id="fanfold-no"
                                checked={profileData.fanfoldInsulation === 'no'}
                                onChange={handleChange}
                              />
                            </div>
                          </Form.Group>
  
                          <Form.Group
                            controlId="formGableVents"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Gable Vents Are Currently On The Property?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="gableVents"
                              value={profileData.gableVents}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formExteriorLights"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Exterior Lights Are Currently On The Property?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="exteriorLights"
                              value={profileData.exteriorLights}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formOutletsSwitches"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Outlets or Switches Are Currently On The Property?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="outletsSwitches"
                              value={profileData.outletsSwitches}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formDryerVents"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Dryer Vents Are Currently On The Property?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="dryerVents"
                              value={profileData.dryerVents}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formExteriorFaucets"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Exterior Faucets Are Currently On The Property?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="exteriorFaucets"
                              value={profileData.exteriorFaucets}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formWindowWraps"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Aluminum Window Wraps Are Currently On The Property?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="windowWraps"
                              value={profileData.windowWraps}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formShutterSets"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              How many Sets of Shutters Are Currently On The Property?
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="shutterSets"
                              value={profileData.shutterSets}
                              onChange={handleChange}
                              className="mt-2 border-primary"
                              min="0"
                            />
                          </Form.Group>
  
                          <Form.Group
                            controlId="formElectricMeter"
                            className="mb-4 p-3 bg-purple-light rounded border"
                          >
                            <Form.Label className="fw-bold mb-2">
                              Is there an Electric Meter On The Property?
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                label="Yes"
                                name="electricMeter"
                                type="radio"
                                value="yes"
                                id="electricmeter-yes"
                                checked={profileData.electricMeter === 'yes'}
                                onChange={handleChange}
                                className="me-4"
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="electricMeter"
                                type="radio"
                                value="no"
                                id="electricmeter-no"
                                checked={profileData.electricMeter === 'no'}
                                onChange={handleChange}
                              />
                            </div>
                          </Form.Group>
                        </>
                      )}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
  
              {/* Step Navigation Buttons */}
              <Col md={12} className="mx-auto my-4 d-flex justify-content-center align-items-center">
                {page > 1 && (
                  <Button
                    className="step-button border-0 px-4 py-2 me-2"
                    onClick={handleBack}
                  >
                    <p className="m-0">BACK</p>
                  </Button>
                )}
  
                {isFinalPage() ? (
                  <Button
                    className="step-button border-0 px-4 py-2"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    <p className="m-0">{loading ? "PROCESSING..." : "SUBMIT"}</p>
                  </Button>
                ) : (
                  <Button
                    className="step-button border-0 px-4 py-2"
                    onClick={handleNext}
                  >
                    <p className="m-0">NEXT STEP</p>
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
  
          {/* Right column: PDF preview of propertyPdf */}
          <Col md={6}>
            {propertyPdfUrl ? (
              <iframe
                src={`${propertyPdfUrl}#view=FitH`}
                width="100%"
                height="800px"
                style={{ border: 'none', borderRadius: 4 }}
                title="Property Measurements Preview"
              />
            ) : (
              <p style={{ color: 'white' }}>
                No measurements PDF available for preview yet.
              </p>
            )}
          </Col>
        </Row>
      </Container>
    );
};

export default EstimatePreview;
