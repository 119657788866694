import React, { useState, useCallback } from "react";
import { Button, Col, Image, Row, Container } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import uploader from "../assets/upload-icon.png";
import deleteBtn from "../assets/delete.png";

const FileUploader = ({ title, fileType, file, setFile }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      setFile(newFile);
    },
    [setFile]
  );

  const removeFile = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      [fileType]: [],
    },
    multiple: false,
  });

  return (
    <Container className="d-flex justify-content-center">
      <Col xs={10} lg={10} className="my-3">
        <h5 className="text-center">{title}</h5>
        <div className="bg-white p-5 rounded">
          <div 
            className="hover-glow"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '10px',
              pointerEvents: 'none',
              transition: 'all 0.3s ease'
            }}
          ></div>
          
          <Row className="justify-content-center">
            <Col lg={10} className="text-dark">
              <section>
                <div
                  {...getRootProps()}
                  className="py-5 text-center main-dropzone"
                  style={{ 
                    border: "2px dashed #ccc", 
                    borderRadius: "10px",
                    transition: 'all 0.3s ease',
                    position: 'relative'
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <input {...getInputProps()} />
                  <>
                    <Image src={uploader} />
                    <p className="fw-bold">Browse PDF Files to upload</p>
                  </>
                </div>
              </section>
            </Col>
          </Row>
          <style>
            {`
              .main-dropzone:hover {
                background-color:rgba(221, 220, 221, 0.44);
                transform: translateY(-2px);
              }
              .hover-glow {
                box-shadow: 0 0 15px rgba(13, 110, 253, 0);
              }
              .main-dropzone:hover ~ .hover-glow {
                // box-shadow: 0 0 15px rgba(13, 110, 253, 0.2);
              }
              .doc-card:hover {
                transform: translateX(5px);
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
              }
            `}
          </style>
          {file && (
            <Row className="justify-content-center mt-4">
              <Col
                lg={10}
                className="px-3 my-2 text-dark doc-card py-2 rounded d-flex align-items-center justify-content-between"
              >
                <p className="m-0">{file.path}</p>
                <Button
                  className="bg-transparent border-0 p-1 delete-btn"
                  onClick={removeFile}
                >
                  <Image src={deleteBtn} />
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Container>
  );
};

export default FileUploader;

