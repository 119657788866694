import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAccount } from "../services/account/account.service";
import LoadingPage from '../components/LoadingPage'; // Ensure this is the correct path to your LoadingPage component

const PrivateRoute = ({ children }) => {

  const accountId = localStorage.getItem('accountId');
  const authToken = localStorage.getItem('authToken');
  const [loading, setLoading] = useState(true);

  const headers = {
    'Authorization': authToken || '',
    'Content-Type': 'application/json',
    'accountId': accountId || ''
  };
  const loginURL =
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:3000"
      : "https://claimscortex.com";

  useEffect(() => {
    const afterLoading = async () => {
      console.log("PrivateRoute: Fetching account info...");
      try {
        if (!authToken) {
          // If there's no auth token, no need to load anything; redirect to login.
          setLoading(false);
          return;
        }

        const response = await getAccount({ "accountId": accountId }, headers);
        console.log("PrivateRoute: Account response:", response);
        if (response.status !== 200) {
          throw new Error('Failed to fetch account data.');
        }

        // Extract the actual token counts from the response
        const { account } = response.data;
        const tokenCount = account?.Tokens || "0";
        const tokenCountX = account?.XactimateTokens || "0";
        
        console.log('response tokens', tokenCount, tokenCountX);
        localStorage.setItem('tokenCount', tokenCount);
        localStorage.setItem('tokenCountX', tokenCountX);
      } catch (error) {
        console.error("Error fetching account:", error);
        // Clear auth token on error to trigger redirect
        localStorage.removeItem('authToken');
      } finally {
        setLoading(false);
        console.log("PrivateRoute has finished loading");
      }
    };


    afterLoading();
  }, [accountId, authToken, headers]);

  // If there's no authToken, user is not authenticated. Redirect to login.
  // if (!authToken) {
  //   return <Navigate to="/login" />;
  // }

  // If still loading the account data, show the loading page.
  // if (loading) {
  //   return <LoadingPage />;
  // }

  // Once loading is complete and authToken is present, render the protected route content.
  return children;
};

export default PrivateRoute;
