import React, { useState, useRef } from "react";
import { Button, Image, Spinner } from 'react-bootstrap';
import { createChatBotMessage } from 'react-chatbot-kit';
import avatarIcon from "../../assets/avatar-icon.svg";
import expandIcon from "../../assets/expand.svg";
import closeIcon from "../../assets/close.svg";
import volume from "../../assets/volume.png";
import stop from "../../assets/stop.png";
import { Config } from '../../config';
import { generateAudioFile } from "../../services/uploads/upload.service";

const botName = 'Oliver the Cortex Bot';

const CustomHeader = ({ toggleExpand, setIsOpen, togglePlay, chatHistory }) => {
  const audioRef = useRef(null); // Move the useRef here
  const [isLoading, setIsLoading] = useState(false);
  const chatHistoryRef = useRef(chatHistory);
  const baseURL = Config.REACT_APP_CHAT_API_BASE_URL;
  const [isPlaying, setIsPlaying] = useState(false);

  const playAudio = async () => {
// Assuming chatHistoryRef.current is an array
   
    let audio_text;

    if (!chatHistoryRef.current || chatHistoryRef.current.length === 0) {
      return; // or set audio_text to a default value like ''
    } else {
      audio_text = chatHistoryRef.current[chatHistoryRef.current.length - 1].assistant;
    }    

    if(isPlaying){

      stopAudio();
      return;
    }
    
    // let url =  `${baseURL}/getAudioFile`;
    const headers = {
      "Content-Type": "application/json",
    };
    let response = await generateAudioFile(audio_text,headers);

    if (!response.status === 200) {
        throw new Error('Network response was not ok');
    };
    let voiceSrc = response.data;
    if (voiceSrc && !isPlaying) {
      setIsLoading(true);
      const audio = new Audio(voiceSrc);
      audioRef.current = audio;
      audio.play();
      audio.onplaying = () => {
          setIsLoading(false);
          setIsPlaying(true);
      };
      audio.onended = () => setIsPlaying(false);
  } else if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
  }
  };

  const stopAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  return (
    <div style={{ padding: '5px', borderRadius: '3px', display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid rgb(255, 255, 255,0.3)", alignItems: 'center' }}>

      <Button  style={{ marginRight: 'auto', marginLeft: '13px' }} className="bg-transparent border-0 p-1 delete-btn" onClick={playAudio} >
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <Image src={isPlaying ? stop : volume} width={25} />
          )}
        </Button>
      <Button onClick={() => setIsOpen(false)} className='expand-button bg-transparent border-0' style={{ marginLeft: 'auto' }}>
       
        <Image src={closeIcon} />
      </Button>
    </div>
  );
};

const BotAvatar = (props) => {
  return (
    <div className='me-3'>
      <Image width={40} src={avatarIcon} />
    </div>
  );
};

const config = (toggleExpand, setIsOpen, togglePlay, chatHistory) => ({
  initialMessages: [createChatBotMessage(`Hi! I'm ${botName}`)],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  state: {
    messages: [],
    chatHistory: [],
  },
  customComponents: {
    header: () => <CustomHeader toggleExpand={toggleExpand} chatHistory={chatHistory} setIsOpen={setIsOpen} togglePlay={togglePlay} />,
    botAvatar: (props) => <BotAvatar {...props} />,
  },
});

export default config;
