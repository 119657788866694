// XactimatePdfPreviewer.jsx
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../assets/logo-2.png";

// Styles
const styles = StyleSheet.create({
  page: { 
    padding: 30, 
    fontSize: 10 
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    width: '100%',
  },
  logo: { 
    width: 80, 
    height: 40, 
    marginRight: 10 
  },
  companyInfo: { 
    fontSize: 9,
    flex: 1,
  },
  companyInfoLine: { 
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    width: '100%',
    marginTop: 2,
    marginBottom: 5,
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionContainer: {
    marginBottom: 20,
  },
  sectionHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  sectionHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  sectionHeaderUnderline: {
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    flex: 1,
    marginLeft: 10,
  },
  table: { 
    display: 'table', 
    width: 'auto', 
  },
  tableRowHeader: { 
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    marginBottom: 5,
  },
  tableRow: { 
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '8.5%',
    padding: 5,
    textAlign: 'center',
  },
  tableCol: {
    width: '8.5%',
    padding: 5,
    textAlign: 'center',
  },
  descriptionCol: {
    width: '35%',
    padding: 5,
    textAlign: 'left',
  },
  noCol: {
    width: '5%',
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableCellHeader: { 
    fontSize: 9, 
    fontWeight: 'bold' 
  },
  tableCell: { 
    fontSize: 9 
  },
  noteRow: {
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 2,
  },
  noteCell: {
    flex: 1,
    padding: 5,
    fontSize: 9,
    fontStyle: 'italic',
  },
  boldNote: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'black',
  },
});

const XactimatePreviewer = ({ data }) => {
  const sections = Object.keys(data.itemsBySection);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.companyInfo}>
            <Text>AK Public Adjusters</Text>
            <View style={styles.companyInfoLine}></View>
            <Text>11436 Cronridge Drive, Suite E</Text>
            <Text>Owings Mills MD 21117</Text>
          </View>
        </View>

        {/* Title */}
        <Text style={styles.title}>XActimate Preview</Text>

        {/* Sections and Their Tables */}
        {sections.map((sectionName, idx) => (
          <View style={styles.sectionContainer} key={idx}>
            {/* Section Header with Underline Extending to the End of the Page */}
            <View style={styles.sectionHeaderContainer}>
              <Text style={styles.sectionHeader}>{sectionName}</Text>
              <View style={styles.sectionHeaderUnderline}></View>
            </View>

            {/* Table for the Section */}
            <View style={styles.table}>
              {/* Table Header Row */}
              <View style={styles.tableRowHeader}>
                {/* Numbering Column Header */}
                <View style={styles.noCol}>
                  <Text style={styles.tableCellHeader}>No.</Text>
                </View>
                {/* Description Column Header */}
                <View style={styles.descriptionCol}>
                  <Text style={styles.tableCellHeader}>DESCRIPTION</Text>
                </View>
                {/* Other Column Headers */}
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>QUANTITY</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>UNIT PRICE</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>TAX</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>O&P</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>RCV</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>DEPREC.</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>ACV</Text>
                </View>
              </View>

              {/* Items in Section */}
              {data.itemsBySection[sectionName].map((item, index) => (
                <React.Fragment key={index}>
                  {/* Line Item Row */}
                  <View style={styles.tableRow}>
                    {/* Numbering Column */}
                    <View style={styles.noCol}>
                      <Text style={styles.tableCell}>{index + 1}</Text>
                    </View>
                    {/* Description Column */}
                    <View style={styles.descriptionCol}>
                      <Text style={styles.tableCell}>{item.description || 'N/A'}</Text>
                    </View>
                    {/* Other Columns */}
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.quantity || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        ${item.price ? item.price.toFixed(2) : '0.00'}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.tax || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.oAndP || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.rcv || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.deprec || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.acv || 0}</Text>
                    </View>
                  </View>

                  {/* Notes Rows (if any) */}
                  {item.notes && item.notes.map((note, noteIndex) => (
                    <View style={styles.noteRow} key={`note-${index}-${noteIndex}`}>
                      {/* Empty numbering column for alignment */}
                      <View style={styles.noCol}></View>
                      {/* Note content spanning the rest of the table width */}
                      <View style={{ flexDirection: 'row', flex: 1 }}>
                        <View style={styles.noteCell}>
                          <Text style={note.bold ? styles.boldNote : {}}>{note.text}</Text>
                        </View>
                      </View>
                    </View>
                  ))}
                </React.Fragment>
              ))}

              {/* Removed the extra notes mapping that caused notes to group together */}
            </View>
          </View>
        ))}

        {/* Footer (if needed) */}
        {/* <Text style={styles.footerText}>Your footer content here</Text> */}
      </Page>
    </Document>
  );
};

export default XactimatePreviewer;
