import React, { useState } from "react";
import { Col, Container, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo-text-2.png";
import { useNavigate } from "react-router-dom";
import { login } from '../services/account/account.service';
import { Navigate } from 'react-router-dom';

const Login = () => {
    const authToken = localStorage.getItem('authToken');

    
    
    const [formData, setFormData] = useState({
        password: '',
        email: ''
      });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        toast.info("Logging into your account...");
    
        if (!formData.email || !formData.password) {
            toast.error("Please enter both email and password.");
            return;
        }
        try {
            const headers = {
              'Content-Type': 'application/json'
            };
            console.log('Form data submitted:', JSON.stringify(formData));
            const response =  await login(JSON.stringify(formData), headers);
            if (response.data["auth-token"] && response.data["auth-token"].trim() !== "") {
                localStorage.setItem("authToken",  response.data["auth-token"]);
                localStorage.setItem("accountId",  response.data["accountId"]);

                navigate("/app");
            } else {
                toast.error("Invalid credentials.");
                console.log('Error during login:', response);
            }
          } catch (error) {
            console.log('Error during login:', error);
            toast.error("Invalid credentials.");
          }
        

    };
    if (authToken)
        return <Navigate to="/app" />;
    return (
        <Container className="d-flex align-items-center min-vh-100">
            <Col xs={11} lg={6} className="mx-auto">
                <Image
                    src={logo}
                    className="m-auto d-flex justify-content-center pb-4"
                />
                <div className="text-center my-4">
                    <h2 style={{ fontWeight: "700" }}>Log In</h2>
                    <p>Enter your credentials</p>
                </div>
                <Form onSubmit={handleLogin}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            className="bg-transparent text-white py-3"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            className="bg-transparent text-white py-3"
                            value={formData.password}
                            onChange={handleChange}
                            name="password"
                        />
                    </Form.Group>

                    <Button
                        className="w-100 login-btn border-0 py-3 fw-bold my-4 fs-5"
                        variant="primary"
                        type="submit"
                    >
                        Log In
                    </Button>
                </Form>
            </Col>
        </Container>
    );
};

export default Login;
