import React from 'react';

const MaintenancePage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>We'll Be Back Soon!</h1>
      <p style={styles.text}>
        Our site is currently down for maintenance. We are working hard to bring it back up as soon as possible.
        Thank you for your patience!
      </p>
      <p style={styles.text}>Please check back later.</p>
      <div style={styles.loader}></div>
    </div>
  );
};

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    textAlign: 'center',
  },
  title: {
    fontSize: '2.5rem',
    color: '#333',
    marginBottom: '20px',
  },
  text: {
    fontSize: '1.25rem',
    color: '#666',
    marginBottom: '10px',
  },
  loader: {
    marginTop: '20px',
    width: '50px',
    height: '50px',
    border: '6px solid #f3f3f3',
    borderTop: '6px solid #333',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};

export default MaintenancePage;
