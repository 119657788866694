import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Card,
  Row,
  Modal,
  Form
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  FiUploadCloud,
  FiCheckCircle,
  FiFilePlus,
  FiChevronRight,
  FiInfo
} from "react-icons/fi";
import { GiStairs } from "react-icons/gi";
import FileUploader from "./FileUploader";
import { getAccessories, submitCustomEstimate, searchLineItems } from "../services/uploads/upload.service";
import { jsPDF } from "jspdf";
import AsyncSelect from "react-select/async";

const Estimate = ({ currentStep, goToNextStep, goToStepTwo }) => {
  
  // PDF Upload States
  const [estimatePdf, setEstimatePdf] = useState(null);
  const [propertyPdf, setPropertyPdf] = useState(null);
  const [loading, setLoading] = useState(false);

  // Estimate Creation State (for the popup/modal)
  const [showEstimatePopup, setShowEstimatePopup] = useState(false);
  const [tempEstimateData, setTempEstimateData] = useState({
    estimator: "",
    address: {
      street: "",
      city: "",
      state: "MD",
      zip_code: ""
    },
    claim_details: {
      claim_number: "",
      policy_number: "",
      date_of_loss: ""
    },
    line_items: [],
    is_submitted: false,
    estimate_type: "Xactimate"
  });
  const [newLineItem, setNewLineItem] = useState({
    description: "",
    quantity: "",
    unit_of_measure: "SQ"
  });

  // Controls which modal step is shown
  const [estimateModalStep, setEstimateModalStep] = useState(1);

  // State for PDF preview URL in step 3
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);

  // Process Steps (Left Column)
  const [activeIndex, setActiveIndex] = useState(null);
  const steps = [
    "Upload Insurance Estimate",
    "Upload Measurements",
    "Select Details",
    "Preview Xactimate or Symbility Estimate",
    "Submit to your Xactimate and Symbility Expert"
  ];
  
  const stepDescriptions = [
    {
      title: "Upload Estimate",
      content:
        "Upload a contract estimate or create one from our popup. Please include all values. PDFs should be clear—not screenshots or faxed copies, but originals."
    },
    {
      title: "Upload Measurements",
      content:
        "Upload the measurements of the property."
    },
    {
      title: "Choose Accessories",
      content:
        "Please add accessories for the line items that the estimate may have missed."
    },
    {
      title: "Preview Xactimate Estimate",
      content:
        "We will provide a preview of the Xactimate estimate and allow modification of line items."
    },
    {
      title: "Submit to Xactimate Solutions Expert",
      content:
        "Generate an Xactimate estimate after confirming the job. Jobs take anywhere from 10 minutes to a few hours depending on our queue."
    }
  ];
  
  // Info Modal for Process Steps
  const [showInfo, setShowInfo] = useState(false);

  // Token Count
  const tokenCount = 0;

  // Generate a random filename for generated PDFs
  const generateRandomFilename = () => {
    const randomString = Math.random().toString(36).substring(2, 10);
    return `custom_${randomString}.pdf`;
  };

  // AsyncSelect custom styles
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      color: "black",
      borderColor: "#ced4da"
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      color: "black"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#e9ecef" : "white",
      color: "black"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d"
    })
  };

  // Async select load options function
  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];
    try {
      const formData = new FormData();
      formData.append("searchTerm", inputValue);
      formData.append("page", 1);
      const response = await searchLineItems(formData);
      return response.data.lineItems.map(item => ({
        label: item.Description,
        value: item.Description,
        unit: item.Unit,
        componentCode: item.componentCode,
        unitPrice: item.UnitPrice
      }));
    } catch (error) {
      console.error("Error fetching line items:", error);
      return [];
    }
  };

  // Convert estimate data to PDF file if no file was uploaded
  const convertEstimateDataToPdf = (estimateData) => {
    const doc = new jsPDF();
    const jsonString = JSON.stringify(estimateData, null, 2);
    const lines = doc.splitTextToSize(jsonString, 180);
    doc.text(lines, 10, 10);
    const pdfBlob = doc.output("blob");
    const pdfFile = new File([pdfBlob], generateRandomFilename(), { type: "application/pdf" });
    return pdfFile;
  };

  // Generate PDF Preview of the Estimate Summary
  const generatePdfPreview = () => {
    const doc = new jsPDF();
    // Header
    doc.setFontSize(22);
    doc.setTextColor("#0d6efd");
    doc.text("Estimate Summary", 10, 20);

    // Subheader
    doc.setFontSize(14);
    doc.setTextColor(0);
    doc.text("Estimator Information", 10, 35);
    doc.setFontSize(12);
    doc.text(`Name: ${tempEstimateData.estimator}`, 10, 45);

    // Address
    doc.setFontSize(14);
    doc.text("Site Address", 10, 60);
    doc.setFontSize(12);
    const addressLine = `${tempEstimateData.address.street}, ${tempEstimateData.address.city}, ${tempEstimateData.address.state} ${tempEstimateData.address.zip_code}`;
    doc.text(addressLine, 10, 70);

    // Claim Details
    doc.setFontSize(14);
    doc.text("Claim Details", 10, 85);
    doc.setFontSize(12);
    doc.text(`Claim Number: ${tempEstimateData.claim_details.claim_number}`, 10, 95);
    doc.text(`Policy Number: ${tempEstimateData.claim_details.policy_number}`, 10, 105);
    doc.text(`Date of Loss: ${tempEstimateData.claim_details.date_of_loss}`, 10, 115);

    // Estimate Type
    doc.setFontSize(14);
    doc.text("Estimate Type", 10, 130);
    doc.setFontSize(12);
    doc.text(tempEstimateData.estimate_type, 10, 140);

    // Line Items
    doc.setFontSize(14);
    doc.text("Line Items", 10, 155);
    doc.setFontSize(12);
    let yPosition = 165;
    if (tempEstimateData.line_items.length === 0) {
      doc.text("No line items added.", 10, yPosition);
    } else {
      tempEstimateData.line_items.forEach((item, index) => {
        const line = `${index + 1}. ${item.description} - Qty: ${item.quantity} ${item.unit_of_measure}`;
        doc.text(line, 10, yPosition);
        yPosition += 8;
      });
    }
    // Return the blob URL for preview
    return doc.output("bloburl");
  };

  // When the user reaches step 3, generate the PDF preview URL
  useEffect(() => {
    if (estimateModalStep === 3) {
      const url = generatePdfPreview();
      setPdfPreviewUrl(url);
    }
  }, [estimateModalStep, tempEstimateData]);

  // Line Item Styles
  const lineItemStyles = {
    listBox: {
      border: "1px solid #ddd",
      borderRadius: "4px",
      padding: "10px",
      marginTop: "10px",
      maxHeight: "200px",
      overflowY: "auto"
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 0",
      borderBottom: "1px solid #eee"
    },
    listHeader: {
      fontWeight: "bold",
      marginBottom: "10px"
    }
  };

  // Add a new line item to estimate data
  const addLineItem = () => {
    if (newLineItem.description && newLineItem.quantity) {
      setTempEstimateData((prev) => ({
        ...prev,
        line_items: [
          ...prev.line_items,
          {
            ...newLineItem,
            item_number: prev.line_items.length + 1,
            quantity: parseFloat(newLineItem.quantity)
          }
        ]
      }));
      setNewLineItem({ description: "", quantity: "", unit_of_measure: "SQ" });
    }
  };

  // Remove a line item by index
  const removeLineItem = (index) => {
    setTempEstimateData((prev) => ({
      ...prev,
      line_items: prev.line_items.filter((_, i) => i !== index)
    }));
  };

  // Remove the estimate PDF and reset the estimate data
  const removeEstimatePdf = () => {
    setEstimatePdf(null);
    setTempEstimateData({
      estimator: "",
      address: { street: "", city: "", state: "MD", zip_code: "" },
      claim_details: { claim_number: "", policy_number: "", date_of_loss: "" },
      line_items: [],
      is_submitted: false,
      estimate_type: "Xactimate"
    });
    toast.info("Estimate upload reset");
  };

  // Remove property measurements PDF
  const removePropertyPdf = () => {
    setPropertyPdf(null);
    toast.info("Property measurements reset");
  };

  // Overall "Next Step" for file uploads and estimate submission
  const handleNext = async () => {
    try {
      const tokenCountX = localStorage.getItem('tokenCountX');
      console.log("Token Count X:", tokenCountX);
      if (!tokenCountX || parseInt(tokenCountX, 0) <= 0) {
        toast.error("You don't have enough tokens to submit an estimate. Please purchase more tokens.");
        return;
      }
      // Build a copy of estimate data including line items
      const estimateData = { ...tempEstimateData };
      // If no PDF was uploaded, convert the estimate data into a PDF file
      const finalEstimatePdf = estimatePdf || convertEstimateDataToPdf(estimateData);

      console.log("Temp Estimate Data:", tempEstimateData);
      console.log("Estimate PDF:", finalEstimatePdf);

      if (!estimatePdf && !(tempEstimateData.line_items.length || tempEstimateData.is_submitted)) {
        toast.error("Please upload an Estimate PDF or create a New Estimate.");
        return;
      }
      if (!propertyPdf) {
        toast.error("Please upload the Property PDF.");
        return;
      }
      setLoading(true);

      // In this branch, we simulate successful submission of a newly created estimate.
      if (!estimatePdf && (tempEstimateData.line_items.length || tempEstimateData.is_submitted)) {
        toast.success("Estimate submitted successfully!");
        // Reset the estimate data if needed
        setTempEstimateData({
          estimator: "",
          address: { street: "", city: "", state: "MD", zip_code: "" },
          claim_details: { claim_number: "", policy_number: "", date_of_loss: "" },
          line_items: [],
          is_submitted: true,
          estimate_type: "Xactimate"
        });
        setEstimateModalStep(1);
        setShowEstimatePopup(false);
        // Pass the complete estimate data (including line items) to the next step / API
        goToStepTwo(1, finalEstimatePdf, propertyPdf, estimateData);
      } else {
        const formData = new FormData();
        if (estimatePdf) formData.append("estimate_pdf", estimatePdf);
        formData.append("property_pdf", propertyPdf);
        const headers = { "Content-Type": "multipart/form-data" };
        const response = await getAccessories(formData, headers);
        const { isReadable } = response.data;
        if (!isReadable && estimatePdf) {
          toast.error("The Estimate PDF appears to be non-readable (possibly images only).");
          setLoading(false);
          return;
        }
        // Pass the complete estimate data (including line items) to the next step / API
        goToStepTwo(1, estimatePdf, propertyPdf, estimateData);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Error reading items. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Validate required fields for the estimate form (Step 1)
  const validateEstimateFields = () => {
    const { estimator, address, claim_details } = tempEstimateData;
    if (
      !estimator.trim() ||
      !address.street.trim() ||
      !address.city.trim() ||
      !address.state.trim() ||
      !address.zip_code.trim() ||
      !claim_details.claim_number.trim() ||
      !claim_details.policy_number.trim() ||
      !claim_details.date_of_loss.trim()
    ) {
      return false;
    }
    return true;
  };

  // Validate that at least one line item has been added (Step 2)
  const validateLineItems = () => {
    if (tempEstimateData.line_items.length === 0) {
      return false;
    }
    return true;
  };

  // Handler for moving from modal step 1 to step 2
  const handleNextEstimate = (e) => {
    e.preventDefault();
    if (!validateEstimateFields()) {
      toast.error("Please fill out all required fields.");
      return;
    }
    setEstimateModalStep(2);
  };

  // Handler for moving from modal step 2 to step 3
  const handleNextLineItems = (e) => {
    e.preventDefault();
    if (!validateLineItems()) {
      toast.error("Please add at least one line item.");
      return;
    }
    setEstimateModalStep(3);
  };

  // Final submission handler for the estimate popup (if needed)
  const handleSubmitEstimate = () => {
    // Check if tokenCountX exists in localStorage and is greater than 0
    const tokenCountX = localStorage.getItem('tokenCountX');
    console.log("Token Count X:", tokenCountX);
    if (!tokenCountX || parseInt(tokenCountX, 0) <= 0) {
      toast.error("You don't have enough tokens to submit an estimate. Please purchase more tokens.");
      return;
    }
    
    console.log("Final Estimate Data:", tempEstimateData);
    toast.success("Estimate submitted successfully!");
    setTempEstimateData((prevData) => ({
      ...prevData,
      is_submitted: true,
    }));
    setEstimateModalStep(1);
    setShowEstimatePopup(false);
  };

  return (
    <Container fluid className="px-4 py-5 gradient-bg">
      {/* Main Card Structure */}
      <Card className="elevation-xxl border-0 overflow-hidden" style={{ borderRadius: "1.5rem" }}>
        {/* Navigation Tabs */}
        <Row
          className="g-0 align-items-center"
          style={{
            background: "linear-gradient(45deg, rgb(29, 31, 59) 70%, rgb(36, 9, 171) 100%)"
          }}
        >
          <Nav variant="pills" className="modern-tabs px-4 pt-4 w-100 d-flex align-items-center">
            <div className="flex-grow-1 d-flex justify-content-center">
            <h1 className="text-white text-center"> Xactimate and Symbility Estimate Writer <span style={{fontSize: '14px'}}>AI Powered  </span> </h1>            </div>
          </Nav>
        </Row>

        {/* Main Content Area */}
        <Row className="g-4 p-4">


          {/* Right Column: File Upload Section */}
          <Col lg={15} className="d-flex justify-content-center">
          <Card className="h-100 border-0 shadow-sm upload-interface" style={{ maxWidth: "1500px", width: "100%" }}>
              <Card.Body className="d-flex flex-column">
                <div className="upload-card-group d-flex flex-column gap-3">
                  {/* Estimate Upload or Create New Estimate */}
                  <Card className={`upload-card ${estimatePdf || tempEstimateData.is_submitted ? "uploaded" : ""}`}>
                    <Card.Body>
                      {(!estimatePdf && !tempEstimateData.is_submitted) ? (
                        <>
                          <FileUploader
                            title={
                              <div className="d-flex flex-column align-items-center">
                                <FiUploadCloud className="upload-icon mb-3" />
                                <span>Upload Your Insurance Estimate (PDF)</span>
                              </div>
                            }
                            fileType="application/pdf"
                            category="estimate"
                            setFile={setEstimatePdf}
                            file={estimatePdf}
                          />
                          <Button
                            variant="primary"
                            className="create-estimate-btn mt-3"
                            onClick={() => {
                              setEstimateModalStep(1);
                              setShowEstimatePopup(true);
                            }}
                          >
                            <FiFilePlus className="me-2" />
                            Create New Estimate
                          </Button>
                        </>
                      ) : (
                        <div className="uploaded-file">
                          <FiCheckCircle className="success-icon" />
                          <div className="file-meta">
                            <span className="file-name">
                              {estimatePdf ? estimatePdf.name : "EstimateCreated.pdf"}
                            </span>
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={removeEstimatePdf}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>

                  {/* Property Measurements Upload */}
                  <Card className={`upload-card ${propertyPdf ? "uploaded" : ""}`}>
                    <Card.Body>
                      {!propertyPdf ? (
                        <FileUploader
                          title={
                            <div className="d-flex flex-column align-items-center">
                              <FiUploadCloud className="upload-icon mb-3" />
                              <span>Upload Your Property Measurements (PDF)</span>
                            </div>
                          }
                          fileType="application/pdf"
                          category="property"
                          setFile={setPropertyPdf}
                          file={propertyPdf}
                        />
                      ) : (
                        <div className="uploaded-file">
                          <FiCheckCircle className="success-icon" />
                          <div className="file-meta">
                            <span className="file-name">{propertyPdf.name}</span>
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={removePropertyPdf}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>

                {/* Next Step Button */}
                <div className="mt-auto pt-4 border-top">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-muted small">
                      {(propertyPdf && (estimatePdf || tempEstimateData.is_submitted))
                        ? "All files ready for processing"
                        : "Complete Estimate + Property Upload to continue"}
                    </div>
                    <Button
                      className="next-step-btn"
                      onClick={handleNext}
                      disabled={
                        (!estimatePdf && !tempEstimateData.is_submitted) ||
                        !propertyPdf ||
                        loading
                      }
                    >
                      {loading ? "Validating..." : (
                        <>
                          Next Step
                          {!loading && <FiChevronRight className="ms-2" />}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>

      {/* Info Modal for Process Steps */}
      <Modal show={showInfo} onHide={() => setShowInfo(false)} centered>
        <Modal.Header closeButton className="gradient-bg">
          <Modal.Title>Estimate Creation Steps</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gradient-bg">
          <p className="text-white">
            These are the main steps to create a complete insurance estimate.
            Expand each step on the left to learn more.
          </p>
        </Modal.Body>
        <Modal.Footer className="gradient-bg">
          <Button variant="primary" onClick={() => setShowInfo(false)}>
            Got It
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Enhanced Estimate Creation Modal (Popup) */}
      <Modal
        show={showEstimatePopup}
        onHide={() => {
          setShowEstimatePopup(false);
          setEstimateModalStep(1);
        }}
        size="lg"
        dialogClassName={estimateModalStep === 3 ? "custom-modal-dialog" : "modern-modal"}
      >
        <Modal.Header closeButton className="gradient-bg">
          <Modal.Title style={{ width: "100%" }}>
            {estimateModalStep === 1 && "Create New Estimate"}
            {estimateModalStep === 2 && "Add Line Items"}
            {estimateModalStep === 3 && "Preview PDF"}
          </Modal.Title>
          <div className="d-flex justify-content-center w-100 mt-2">
            <div className="step-indicator">
              <span className={`step ${estimateModalStep >= 1 ? "active" : ""}`}>1</span>
              <span className={`step ${estimateModalStep >= 2 ? "active" : ""}`}>2</span>
              <span className={`step ${estimateModalStep >= 3 ? "active" : ""}`}>3</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="gradient-bg">
          {estimateModalStep === 1 ? (
            // Step 1: Basic Information
            <Form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }} noValidate>
              <h5 className="mb-3">Estimator Information</h5>
              <Form.Group className="mb-3">
                <Form.Label style={{ color: "white" }}>Estimator Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={tempEstimateData.estimator}
                  onChange={(e) =>
                    setTempEstimateData({ ...tempEstimateData, estimator: e.target.value })
                  }
                />
              </Form.Group>

              <h5 className="mb-3 mt-4">Site Address</h5>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3">
                  <Form.Label style={{ color: "white" }}>Street</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={tempEstimateData.address.street}
                    onChange={(e) =>
                      setTempEstimateData({
                        ...tempEstimateData,
                        address: { ...tempEstimateData.address, street: e.target.value }
                      })
                    }
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md={6}>
                  <Form.Label style={{ color: "white" }}>City</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={tempEstimateData.address.city}
                    onChange={(e) =>
                      setTempEstimateData({
                        ...tempEstimateData,
                        address: { ...tempEstimateData.address, city: e.target.value }
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label style={{ color: "white" }}>State</Form.Label>
                  <Form.Select
                    required
                    value={tempEstimateData.address.state}
                    onChange={(e) =>
                      setTempEstimateData({
                        ...tempEstimateData,
                        address: { ...tempEstimateData.address, state: e.target.value }
                      })
                    }
                  >
                    <option>MD</option>
                    <option>VA</option>
                    <option>PA</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <Form.Label style={{ color: "white" }}>Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={tempEstimateData.address.zip_code}
                    onChange={(e) =>
                      setTempEstimateData({
                        ...tempEstimateData,
                        address: { ...tempEstimateData.address, zip_code: e.target.value }
                      })
                    }
                  />
                </Form.Group>
              </Row>

              <h5 className="mb-3 mt-4">Claim Details</h5>
              <Row className="mb-3">
                <Form.Group as={Col} md={6}>
                  <Form.Label style={{ color: "white" }}>Claim Number</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={tempEstimateData.claim_details.claim_number}
                    onChange={(e) =>
                      setTempEstimateData({
                        ...tempEstimateData,
                        claim_details: { ...tempEstimateData.claim_details, claim_number: e.target.value }
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label style={{ color: "white" }}>Policy Number</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={tempEstimateData.claim_details.policy_number}
                    onChange={(e) =>
                      setTempEstimateData({
                        ...tempEstimateData,
                        claim_details: { ...tempEstimateData.claim_details, policy_number: e.target.value }
                      })
                    }
                  />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label style={{ color: "white" }}>Date of Loss</Form.Label>
                <Form.Control
                  type="date"
                  required
                  value={tempEstimateData.claim_details.date_of_loss}
                  onChange={(e) =>
                    setTempEstimateData({
                      ...tempEstimateData,
                      claim_details: { ...tempEstimateData.claim_details, date_of_loss: e.target.value }
                    })
                  }
                />
              </Form.Group>

              <h5 className="mb-3 mt-4">Estimate Type</h5>
              <Form.Group className="mb-3">
                <Form.Label style={{ color: "white" }}>Select Estimate Type</Form.Label>
                <Form.Select
                  value={tempEstimateData.estimate_type}
                  onChange={(e) =>
                    setTempEstimateData({ ...tempEstimateData, estimate_type: e.target.value })
                  }
                >
                  <option value="Xactimate">Xactimate</option>
                </Form.Select>
              </Form.Group>
            </Form>
          ) : estimateModalStep === 2 ? (
            // Step 2: Line Items Entry
            <div className="line-items-step">
              <h5 className="mb-4">Add Line Items</h5>
              <Card className="p-3">
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group as={Col} md={12}>
                      <AsyncSelect
                        styles={customSelectStyles}
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setNewLineItem({
                              ...newLineItem,
                              description: selectedOption.label,
                              unit_of_measure: selectedOption.unit || "SQ"
                            });
                          } else {
                            setNewLineItem({
                              ...newLineItem,
                              description: "",
                              unit_of_measure: "SQ"
                            });
                          }
                        }}
                        placeholder="Search line items..."
                        isClearable
                        value={
                          newLineItem.description
                            ? { label: newLineItem.description, value: newLineItem.description }
                            : null
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Control
                      type="number"
                      placeholder="Quantity"
                      value={newLineItem.quantity}
                      onChange={(e) =>
                        setNewLineItem({ ...newLineItem, quantity: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Form.Select
                      value={newLineItem.unit_of_measure}
                      disabled={true}
                      onChange={(e) =>
                        setNewLineItem({ ...newLineItem, unit_of_measure: e.target.value })
                      }
                    >
                      <option>SQ</option>
                      <option>LF</option>
                      <option>SF</option>
                      <option>EA</option>
                    </Form.Select>
                  </Col>
                  <Col md={2}>
                    <Button
                      variant="primary"
                      className="add-item-btn"
                      disabled={!newLineItem.description || !newLineItem.quantity}
                      onClick={(e) => {
                        e.preventDefault();
                        addLineItem();
                      }}
                    >
                      Add Item
                    </Button>
                  </Col>
                </Row>
                {tempEstimateData.line_items.length > 0 && (
                  <Card className="mt-3">
                    <Card.Body style={lineItemStyles.listBox}>
                      <div style={lineItemStyles.listHeader}>
                        <Row>
                          <Col md={1}>#</Col>
                          <Col md={6}>Description</Col>
                          <Col md={2}>Quantity</Col>
                          <Col md={2}>Unit</Col>
                          <Col md={1}></Col>
                        </Row>
                      </div>
                      {tempEstimateData.line_items.map((item, index) => (
                        <div key={index} style={lineItemStyles.listItem}>
                          <Row className="w-100">
                            <Col md={1}>{index + 1}.</Col>
                            <Col md={5}>{item.description}</Col>
                            <Col md={2}>{item.quantity}</Col>
                            <Col md={2}>{item.unit_of_measure}</Col>
                            <Col md={1}>
                              <Button
                                variant="link"
                                className="text-danger"
                                onClick={() => removeLineItem(index)}
                              >
                                <i className="bi bi-trash"></i>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Card.Body>
                  </Card>
                )}
              </Card>
            </div>
          ) : (
          // Step 3: PDF Preview
          <div className="pdf-preview">
            {pdfPreviewUrl ? (
              <div style={{ 
                backgroundColor: '#f5f5f5', 
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                maxWidth: '800px',
                margin: '0 auto'
              }}>
                <iframe
                  src={`${pdfPreviewUrl}#view=FitH&toolbar=1`}
                  style={{
                    width: '100%',
                    height: '80vh',
                    border: 'none',
                    borderRadius: '4px',
                    display: 'block'
                  }}
                  title="Estimate PDF Preview"
                />
              </div>
            ) : (
              <div style={{ 
                backgroundColor: '#f5f5f5',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                maxWidth: '800px',
                margin: '0 auto'
              }}>
                <p style={{ color: '#666', margin: 0 }}>Generating PDF preview...</p>
              </div>
            )}
          </div>
          )}
        </Modal.Body>
        <Modal.Footer className="gradient-bg">
          {estimateModalStep === 1 ? (
            <>
              <Button variant="secondary" onClick={() => setShowEstimatePopup(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleNextEstimate}>
                Next
              </Button>
            </>
          ) : estimateModalStep === 2 ? (
            <>
              <Button variant="secondary" onClick={() => setEstimateModalStep(1)}>
                Back
              </Button>
              <Button variant="primary" onClick={handleNextLineItems}>
                Continue
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={() => setEstimateModalStep(2)}>
                Back
              </Button>
              <Button variant="primary" onClick={handleSubmitEstimate}>
                Submit Estimate
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      {/* Global Styles */}
      <style jsx global>{`
        .custom-modal-dialog {
          width: 60vw !important;
          max-width: 60vw !important;
        }
        .custom-modal-dialog .modal-content {
          height: 90vh;
        }
        .step-indicator {
          display: flex;
          gap: 1.5rem;
          margin-bottom: 1rem;
        }
        .step-indicator .step {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #e9ecef;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          color: #6c757d;
          transition: all 0.3s ease;
        }
        .step-indicator .step.active {
          background: var(--primary-gradient);
          color: white;
          box-shadow: 0 4px 10px rgba(13, 110, 253, 0.3);
        }
        .line-items-step .add-item-btn {
          width: 100%;
          white-space: nowrap;
        }
        .pdf-preview {
          background: #ffffff;
          border-radius: 0.5rem;
          padding: 1rem;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        }
        .modern-modal .modal-body {
          padding: 2rem;
        }
        .modern-modal .form-label {
          font-weight: 600;
          color: #495057;
        }
        .modern-modal .form-control, .modern-modal .form-select {
          border-radius: 0.5rem;
          padding: 0.75rem 1rem;
        }
        .modern-modal .card {
          border-radius: 0.75rem;
          border: 1px solid rgba(0,0,0,0.08);
        }
      `}</style>
    </Container>
  );
};

export default Estimate;
