import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Card,
  Row,
  Modal
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  FiUploadCloud,
  FiCheckCircle,
  FiChevronRight,
  FiFile,
  FiDownload,
  FiArrowRight
} from "react-icons/fi";
import { GoRocket } from "react-icons/go";
import FileUploader from "./FileUploader"; // Reusing FileUploader component

const Pdf2Esx = () => {
  // State for uploaded PDF
  const [pdfFile, setPdfFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showJumpAnimation, setShowJumpAnimation] = useState(false);
  const [showArrowAnimation, setShowArrowAnimation] = useState(false);
  
  // Refs for drag and drop animation
  const pdfCardRef = useRef(null);
  const dropZoneRef = useRef(null);
  const arrowRef = useRef(null);
  
  // Remove the uploaded PDF
  const removePdf = () => {
    setPdfFile(null);
    setIsSubmitted(false);
    toast.info("PDF upload reset");
  };
  
  // Handle submission
  const handleSubmit = async () => {
    try {
      if (!pdfFile) {
        toast.error("Please upload a PDF file first.");
        return;
      }
      
      setLoading(true);
      
      // Simulate API call - replace with actual implementation
      setTimeout(() => {
        setLoading(false);
        setIsSubmitted(true);
        setShowSuccessModal(true);
        toast.success("PDF successfully converted to ESX format!");
      }, 2000);
      
    } catch (error) {
      console.error("Error processing PDF:", error);
      toast.error("Error processing PDF. Please try again.");
      setLoading(false);
    }
  };
  
  // Handle file upload completion
  const handleFileUploaded = (file) => {
    setPdfFile(file);
    // Show jumping animation
    setTimeout(() => {
      setShowJumpAnimation(true);
      setTimeout(() => {
        setShowJumpAnimation(false);
        // Show arrow animation after jump
        setShowArrowAnimation(true);
        setTimeout(() => {
          setShowArrowAnimation(false);
        }, 2000);
      }, 1000);
    }, 100);
  };
  
  // Handle drag start
  const handleDragStart = (e) => {
    if (!pdfFile) return;
    
    // Create a ghost image for dragging
    const dragImage = new Image();
    dragImage.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";
    e.dataTransfer.setDragImage(dragImage, 0, 0);
    
    // Set data being dragged
    e.dataTransfer.setData("text/plain", "pdf-file");
    setIsDragging(true);
    
    // Trigger animation in drop zone
    setShowAnimation(true);
  };
  
  // Handle drag end
  const handleDragEnd = () => {
    setIsDragging(false);
    // Stop animation after a short delay
    setTimeout(() => {
      setShowAnimation(false);
    }, 300);
  };
  
  // Handle drop zone events
  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("drop-zone-active");
  };
  
  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove("drop-zone-active");
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove("drop-zone-active");
    setShowAnimation(false);
    
    // Check if the dropped item is our PDF
    const data = e.dataTransfer.getData("text/plain");
    if (data === "pdf-file" && pdfFile) {
      handleSubmit();
    }
  };
  
  // Hover effect for PDF when draggable
  useEffect(() => {
    if (pdfFile && !isDragging) {
      const interval = setInterval(() => {
        if (pdfCardRef.current) {
          pdfCardRef.current.classList.add('hover-effect');
          setTimeout(() => {
            if (pdfCardRef.current) {
              pdfCardRef.current.classList.remove('hover-effect');
            }
          }, 1000);
        }
      }, 3000);
      
      return () => clearInterval(interval);
    }
  }, [pdfFile, isDragging]);
  
  return (
    <Container fluid className="px-4 py-5 gradient-bg">
      {/* Main Card Structure */}
      <Card className="elevation-xxl border-0 overflow-hidden" style={{ borderRadius: "1.5rem" }}>
        {/* Navigation Header */}
        <Row
          className="g-0 align-items-center"
          style={{
            background: "linear-gradient(45deg, rgb(29, 31, 59) 70%, rgb(36, 9, 171) 100%)"
          }}
        >
          <div className="px-4 pt-4 pb-4 w-100 d-flex align-items-center">
            <h1 className="text-white text-center w-100">
              PDF to ESX Converter <span style={{ fontSize: '14px' }}>AI Powered</span>
              <span className="beta-badge">BETA</span>
            </h1>
          </div>
        </Row>

        {/* Main Content Area */}
        <Row className="g-4 p-4">
          <Col lg={12} className="d-flex justify-content-center">
            <Card className="border-0 shadow-sm upload-interface w-100">
              <Card.Body>
                <h4 className="mb-4 text-center">Convert PDF Drawings to ESX Format</h4>
                <p className="text-center text-muted mb-4">
                  Upload your PDF drawing, then drag it to the ESX converter below.
                </p>

                <Row className="mb-5">
                  {/* PDF Upload Section */}
                  <Col md={6} className="mb-4 mb-md-0 d-flex">
                    <Card 
                      className={`upload-card modern-card ${pdfFile ? "uploaded" : ""} ${isDragging ? "dragging" : ""}`} 
                      ref={pdfCardRef}
                      draggable={!!pdfFile}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                    >
                      <Card.Body className="d-flex flex-column justify-content-center align-items-center position-relative">
                        {!pdfFile ? (
                          <div className="pdf-upload-wrapper">
                            <div className="upload-glow-effect"></div>
                            <FileUploader
                              title={
                                <div className="d-flex flex-column align-items-center">
                                  <div className="upload-icon-wrapper">
                                    <FiUploadCloud className="upload-icon" />
                                  </div>
                                  <span className="upload-text">Upload Your PDF Drawing</span>
                                </div>
                              }
                              fileType="application/pdf"
                              category="pdf"
                              setFile={handleFileUploaded}
                              file={pdfFile}
                            />
                          </div>
                        ) : (
                          <div className={`uploaded-file ${showJumpAnimation ? 'jump-animation' : ''}`}>
                            <div className="file-icon-container">
                              <FiCheckCircle className="success-icon" />
                            </div>
                            <div className="file-meta">
                              <span className="file-name">{pdfFile.name}</span>
                              <Button
                                variant="link"
                                className="text-danger"
                                onClick={removePdf}
                              >
                                Remove
                              </Button>
                            </div>
                            {!isSubmitted && (
                              <div className="drag-instruction mt-3 text-center">
                                <div className="drag-arrow-animation">
                                  <FiArrowRight className="arrow-icon" />
                                </div>
                                <p className="drag-text">DRAG PDF TO CONVERT</p>
                              </div>
                            )}
                          </div>
                        )}
                        
                        {/* Floating arrow animation */}
                        {showArrowAnimation && (
                          <div className="floating-arrow" ref={arrowRef}>
                            <svg width="80" height="40" viewBox="0 0 80 40">
                              <path 
                                d="M5,20 L65,20 M50,5 L65,20 L50,35" 
                                fill="none" 
                                stroke="#5e72e4" 
                                strokeWidth="5" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* ESX Drop Zone */}
                  <Col md={6} className="d-flex">
                    <div 
                      className={`esx-drop-zone modern-card ${isSubmitted ? "submitted" : ""} ${showAnimation ? "receiving" : ""}`}
                      ref={dropZoneRef}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      {!isSubmitted ? (
                        <>
                          <div className="drop-glow-effect"></div>
                          <div className="pdf-outline">
                            <FiFile className="file-icon" />
                            <div className="file-extension">.ESX</div>
                          </div>
                          <p className="drop-text">DROP PDF HERE</p>
                          <div className="drop-particles">
                            {[...Array(10)].map((_, i) => (
                              <div key={i} className="particle"></div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="conversion-success">
                          <div className="success-icon-container">
                            <FiCheckCircle className="success-icon-large" />
                          </div>
                          <p className="success-text">Conversion Complete!</p>
                          <Button variant="primary" className="mt-3 download-btn">
                            <FiDownload className="me-2" />
                            Download ESX File
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {/* Submit Button (Alternative to drag and drop) */}
                <div className="d-flex justify-content-center mt-5">
                  <div 
                    className={`conversion-banner ${(!pdfFile || isSubmitted || loading) ? "disabled" : ""}`}
                    onClick={pdfFile && !isSubmitted && !loading ? handleSubmit : null}
                  >
                    <div className="banner-content">
                      {loading ? (
                        <>
                          <div className="spinner"></div>
                          <span>Converting...</span>
                        </>
                      ) : (
                        <>
                          <GoRocket className="banner-icon" />
                          <span>Convert PDF to ESX Now</span>
                          <FiChevronRight className="ms-2" />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
        <Modal.Header closeButton className="gradient-bg">
          <Modal.Title>Conversion Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center py-4">
          <div className="success-animation">
            <FiCheckCircle className="text-success mb-3" style={{ fontSize: "3rem" }} />
          </div>
          <h4>Your PDF has been converted to ESX format!</h4>
          <p className="text-muted">The file is now ready for download.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
          <Button variant="primary" className="download-btn">
            <FiDownload className="me-2" />
            Download ESX File
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Global Styles */}
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
        
        .gradient-bg {
          background: linear-gradient(135deg, #f5f7fa 0%, #e4e7f0 100%);
        }
        
        /* Modern Card Styling */
        /* Beta Badge */
        .beta-badge {
          position: relative;
          top: -10px;
          margin-left: 10px;
          background: #ff9500;
          color: white;
          font-size: 12px;
          font-weight: 600;
          padding: 3px 8px;
          border-radius: 10px;
          letter-spacing: 1px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          animation: pulse 2s infinite;
        }
        
        .modern-card {
          border-radius: 1.5rem;
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
          transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          position: relative;
          width: 100%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border: none;
          background: white;
        }
        
        /* Floating arrow animation */
        .floating-arrow {
          position: absolute;
          top: 50%;
          right: -40px;
          transform: translateY(-50%);
          z-index: 100;
          animation: floatArrow 2s ease-in-out;
          opacity: 0;
        }
        
        @keyframes floatArrow {
          0% {
            opacity: 0;
            right: 0;
          }
          20% {
            opacity: 1;
          }
          80% {
            opacity: 1;
            right: -80px;
          }
          100% {
            opacity: 0;
            right: -100px;
          }
        }
        
        /* Upload Card Specific */
        .upload-card {
          transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          cursor: pointer;
        }
        
        .upload-card.hover-effect {
          transform: translateY(-5px) scale(1.02);
        }
        
        .upload-card.uploaded {
          border-style: solid;
          border-color: #4a8cff;
          background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
        }
        
        .upload-card.uploaded::before {
          background: linear-gradient(135deg, #4a8cff, #6ea8fe);
        }
        
        /* Jump animation for uploaded file */
        .jump-animation {
          animation: jump 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.5);
        }
        
        @keyframes jump {
          0% {
            transform: translateY(0) scale(1);
          }
          50% {
            transform: translateY(-15px) scale(1.1);
          }
          75% {
            transform: translateY(5px) scale(0.95);
          }
          100% {
            transform: translateY(0) scale(1);
          }
        }
        
        .upload-card.dragging {
          opacity: 0.6;
          transform: scale(0.95) rotate(-2deg);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        }
        
        .upload-icon-wrapper {
          background: linear-gradient(135deg, #5e72e4 0%, #825ee4 100%);
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1.5rem;
          box-shadow: 0 10px 20px rgba(94, 114, 228, 0.3);
          transition: all 0.3s ease;
        }
        
        .upload-icon-wrapper:hover {
          transform: scale(1.1);
        }
        
        .upload-icon {
          font-size: 2.75rem;
          color: white;
        }
        
        .upload-text {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 1.1rem;
          color: #525f7f;
          margin-top: 0.75rem;
        }
        
        .upload-glow-effect {
          position: absolute;
          width: 150px;
          height: 150px;
          background: radial-gradient(circle, rgba(94, 114, 228, 0.2) 0%, rgba(255, 255, 255, 0) 70%);
          animation: glow 3s infinite alternate;
          z-index: 0;
        }
        
        /* File Meta styles */
        .file-icon-container {
          background: linear-gradient(135deg, #2dce89 0%, #1f8b5d 100%);
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;
          box-shadow: 0 10px 20px rgba(45, 206, 137, 0.3);
        }
        
        .success-icon {
          font-size: 2rem;
          color: white;
        }
        
        .success-icon-large {
          font-size: 2.5rem;
          color: white;
        }
        
        .file-name {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 1rem;
          color: #525f7f;
        }
        
        .uploaded-file {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          z-index: 1;
        }
        
        .file-meta {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 0.5rem;
        }
        
        /* Drag instruction styles */
        .drag-instruction {
          margin-top: 1.5rem !important;
          position: relative;
        }
        
        .drag-text {
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          font-size: 1.1rem;
          letter-spacing: 1px;
          color: #5e72e4;
          margin: 0;
          text-shadow: 0 2px 4px rgba(94, 114, 228, 0.2);
        }
        
        .drag-arrow-animation {
          position: relative;
          margin-bottom: 0.5rem;
        }
        
        .arrow-icon {
          font-size: 2rem;
          color: #5e72e4;
          animation: slideRight 1.5s infinite;
        }
        
        /* ESX Drop Zone styles */
        .esx-drop-zone {
          position: relative;
          border: 2px dashed rgba(94, 114, 228, 0.3);
          transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        
        .esx-drop-zone::before {
          background: linear-gradient(135deg, #f5f7fa, #e4e7f0);
        }
        
        .esx-drop-zone.receiving {
          border-color: #5e72e4;
          transform: scale(1.03);
          box-shadow: 0 15px 35px rgba(94, 114, 228, 0.2);
        }
        
        .esx-drop-zone.receiving::before {
          background: linear-gradient(135deg, #5e72e4, #825ee4);
        }
        
        .esx-drop-zone:hover,
        .drop-zone-active {
          border-color: #5e72e4;
          background-color: rgba(94, 114, 228, 0.05);
        }
        
        .esx-drop-zone.submitted {
          border-style: solid;
          border-color: #2dce89;
          background: linear-gradient(135deg, #f0fff4 0%, #ffffff 100%);
        }
        
        .esx-drop-zone.submitted::before {
          background: linear-gradient(135deg, #2dce89, #1f8b5d);
        }
        
        .drop-glow-effect {
          position: absolute;
          width: 200px;
          height: 200px;
          background: radial-gradient(circle, rgba(94, 114, 228, 0.1) 0%, rgba(255, 255, 255, 0) 70%);
          animation: glow 3s infinite alternate;
          z-index: 0;
        }
        
        .pdf-outline {
          position: relative;
          width: 120px;
          height: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1.5rem;
          z-index: 1;
        }
        
        .file-icon {
          font-size: 6rem;
          color: #5e72e4;
          filter: drop-shadow(0 5px 10px rgba(94, 114, 228, 0.2));
        }
        
        .file-extension {
          position: absolute;
          bottom: 20px;
          font-weight: bold;
          font-size: 1.25rem;
          color: #525f7f;
          font-family: 'Poppins', sans-serif;
          background: white;
          padding: 2px 8px;
          border-radius: 4px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        
        .drop-text {
          color: #525f7f;
          font-weight: 700;
          font-size: 1.2rem;
          font-family: 'Poppins', sans-serif;
          margin-top: 0.5rem;
          letter-spacing: 1px;
          z-index: 1;
        }
        
        /* Drop particles animation */
        .drop-particles {
          position: absolute;
          inset: 0;
          pointer-events: none;
        }
        
        .particle {
          position: absolute;
          width: 6px;
          height: 6px;
          background: #5e72e4;
          border-radius: 50%;
          opacity: 0;
        }
        
        .esx-drop-zone.receiving .particle {
          animation: particles 1.5s linear infinite;
        }
        
        .esx-drop-zone.receiving .particle:nth-child(1) { top: 20%; left: 30%; animation-delay: 0s; }
        .esx-drop-zone.receiving .particle:nth-child(2) { top: 40%; left: 80%; animation-delay: 0.2s; }
        .esx-drop-zone.receiving .particle:nth-child(3) { top: 60%; left: 20%; animation-delay: 0.4s; }
        .esx-drop-zone.receiving .particle:nth-child(4) { top: 80%; left: 65%; animation-delay: 0.1s; }
        .esx-drop-zone.receiving .particle:nth-child(5) { top: 10%; left: 70%; animation-delay: 0.3s; }
        .esx-drop-zone.receiving .particle:nth-child(6) { top: 30%; left: 10%; animation-delay: 0.5s; }
        .esx-drop-zone.receiving .particle:nth-child(7) { top: 70%; left: 40%; animation-delay: 0.2s; }
        .esx-drop-zone.receiving .particle:nth-child(8) { top: 50%; left: 50%; animation-delay: 0s; }
        .esx-drop-zone.receiving .particle:nth-child(9) { top: 90%; left: 90%; animation-delay: 0.3s; }
        .esx-drop-zone.receiving .particle:nth-child(10) { top: 15%; left: 45%; animation-delay: 0.1s; }
        
        /* Success view */
        .conversion-success {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
        
        .success-icon-container {
          background: linear-gradient(135deg, #2dce89 0%, #1f8b5d 100%);
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1.5rem;
          box-shadow: 0 10px 20px rgba(45, 206, 137, 0.3);
          animation: scaleIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        
        .success-text {
          color: #2dce89;
          font-weight: 700;
          font-size: 1.5rem;
          font-family: 'Poppins', sans-serif;
          margin: 0.5rem 0 1rem;
        }
        
        .download-btn {
          background: linear-gradient(135deg, #5e72e4 0%, #825ee4 100%) !important;
          border: none !important;
          padding: 0.6rem 1.5rem !important;
          border-radius: 0.75rem !important;
          font-family: 'Poppins', sans-serif !important;
          font-weight: 600 !important;
          box-shadow: 0 5px 15px rgba(94, 114, 228, 0.3) !important;
          transition: all 0.3s ease !important;
        }
        
        .download-btn:hover {
          transform: translateY(-2px) !important;
          box-shadow: 0 8px 20px rgba(94, 114, 228, 0.4) !important;
        }
        
        .download-btn:active {
          transform: translateY(1px) !important;
        }
        
        /* Banner conversion button */
        .conversion-banner {
          background: linear-gradient(135deg, #5e72e4 0%, #825ee4 100%);
          border-radius: 1rem;
          padding: 0;
          width: 80%;
          max-width: 500px;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          box-shadow: 0 10px 25px rgba(94, 114, 228, 0.3);
          transition: all 0.3s ease;
        }
        
        .conversion-banner:not(.disabled):hover {
          transform: translateY(-3px);
          box-shadow: 0 15px 30px rgba(94, 114, 228, 0.4);
        }
        
        .conversion-banner:not(.disabled):active {
          transform: translateY(1px);
        }
        
        .conversion-banner.disabled {
          background: linear-gradient(135deg, #98a4e0 0%, #b3aad9 100%);
          opacity: 0.7;
          cursor: not-allowed;
        }
        
        .banner-content {
          padding: 1.25rem 2rem;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 1.25rem;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
        }
        
        .banner-icon {
          font-size: 1.5rem;
          margin-right: 1rem;
        }
        
        .conversion-banner::after {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: linear-gradient(
            to bottom right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: rotate(45deg);
          animation: shimmer 3s infinite;
        }
        
        /* Spinner for loading */
        .spinner {
          width: 25px;
          height: 25px;
          border: 3px solid rgba(255, 255, 255, 0.3);
          border-top-color: white;
          border-radius: 50%;
          margin-right: 1rem;
          animation: spin 0.8s linear infinite;
        }
        
        /* Success animation in modal */
        .success-animation {
          position: relative;
        }
        
        .success-animation::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0;
          height: 0;
          background: rgba(45, 206, 137, 0.1);
          border-radius: 50%;
          animation: successPulse 1.5s ease-out;
        }
        
        /* Animations */
        @keyframes slideRight {
          0%, 100% { transform: translateX(0); opacity: 0.5; }
          50% { transform: translateX(10px); opacity: 1; }
        }
        
        @keyframes glow {
          0% { opacity: 0.3; transform: scale(0.95); }
          100% { opacity: 0.7; transform: scale(1.05); }
        }
        
        @keyframes pulse-bg {
          0% { background: rgba(94, 114, 228, 0.05); }
          50% { background: rgba(94, 114, 228, 0.2); }
          100% { background: rgba(94, 114, 228, 0.05); }
        }
        
        @keyframes particles {
          0% { opacity: 0; transform: translate(0, 0); }
          50% { opacity: 1; }
          100% { opacity: 0; transform: translate(calc(Math.random() * 60px - 30px), calc(Math.random() * 60px - 30px)); }
        }
        
        @keyframes scaleIn {
          0% { transform: scale(0); }
          100% { transform: scale(1); }
        }
        
        @keyframes shimmer {
          0% { transform: translateX(-150%) rotate(45deg); }
          100% { transform: translateX(150%) rotate(45deg); }
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        
        @keyframes successPulse {
          0% { width: 0; height: 0; opacity: 0.5; }
          100% { width: 120px; height: 120px; opacity: 0; }
        }
        
        /* Animation for drop zone when item is dragged over it */
        .drop-zone-active::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(94, 114, 228, 0.1);
          animation: pulse-bg 1s infinite;
          z-index: 0;
        }
      `}</style>
    </Container>
  );
};

export default Pdf2Esx;