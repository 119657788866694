import React, { useState, useEffect, useRef } from 'react';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './XactimateConfig';
import MessageParser from './MessageParser';
import XactimateActionProvider from './XactimateActionProvider';
import { Image } from 'react-bootstrap';
import chatLogo from "../../assets/chat-bot.svg";

const XactimateChatbotWidget = ({ onResponse, estimatePdf, propertyPdf }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const messageContainerRef = useRef(null);

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  const saveMessages = (messages, HTMLString) => {
    setMessages(messages);
  };

  const loadMessages = () => {

    return messages;
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const validateInput = (message) => {
    console.log('messages', messages);

    return message.trim().length > 0;
  };

  useEffect(() => {
    
    if (isOpen) {
      scrollToBottom();
    }
  }, [messages, isOpen]);
  return (
    <div className="x-chatbot-widget">
    <div className="x-chatbot-container" ref={messageContainerRef}>
      <Chatbot
        config={config()}
        messageParser={MessageParser}
        validator={validateInput}
        actionProvider={(props) => (
          <XactimateActionProvider
            {...props}
            chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            onResponse={onResponse} // Pass the callback here
            estimatePdf={estimatePdf} // Pass the prop here
            propertyPdf={propertyPdf} // Pass the prop here
          />
        )}
        saveMessages={saveMessages}
        messageHistory={loadMessages()}
      />
    </div>
  </div>
  );
};

export default XactimateChatbotWidget;
