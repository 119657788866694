import { PostFormData } from "../../utils/http-client";

export const createAccount = function (data,headers) {
    return PostFormData(`/createAccount`, 'POST', data, true,headers);
}
export const updateTokens = function (data,headers) {
    return PostFormData(`/updateTokens`, 'POST', data, true,headers);
}
export const retrieveTokens = function (data,headers) {
    return PostFormData(`/retrieveTokens`, 'POST', data, true,headers);
}
export const getAccount = function (data,headers) {
    return PostFormData(`/getAccount`, 'POST', data, true,headers);
}
export const updateAccount = function (data,headers) {
    return PostFormData(`/updateAccount`, 'POST', data, true,headers);
}
export const logout = function (data,headers) {
    return PostFormData(`/logout`, 'POST', data, true,headers);
}
export const sendSupportEmail = function (data,headers) {
    return PostFormData(`/sendSupportEmail`, 'POST', data, true,headers);
}

export const login = function (data,headers) {
    return PostFormData(`/login`, 'POST', data, true,headers);
}
export const isAuthenticated2 = function (data,headers) {
    return PostFormData(`/isAuth`, 'POST', data, true,headers);
}
export const completeJob = function (data, headers) {
    return PostFormData(`/completeJob`, 'POST', data, true, headers);
  };
export const restartJob = function (data,headers) {
    return PostFormData(`/restartJob`, 'POST', data, true,headers);
}

export const deleteJob = function (data,headers) {
    return PostFormData(`/deleteJob`, 'POST', data, true,headers);
}