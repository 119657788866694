'use client';

import React, { useState, useEffect, useRef } from "react";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import XactimatePreviewer from './XactimatePdfPreviewer';
import logo from "../assets/logo-2.png";
import { toast } from "react-toastify";
import { generateEstimate, searchLineItems } from "../services/uploads/upload.service"; 
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Image, Form, Nav, Table, Badge } from "react-bootstrap";
import XactimateChatbotWidget from './chatbot/XactimateChatbotWidget';
import defaultNotesJson from '../default_notes.json';
import XactimatePdfHeaderPage from "./XactimatePdfHeaderPage";

// -- Custom React-Select Styles
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
    borderColor: '#ced4da',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#e9ecef' : 'white',
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6c757d',
  }),
};

// Purple color palette
const purpleColors = {
  dark: '#3a3356',      // Dark purple for headers and important text
  medium: '#6a5acd',    // Medium purple for accents and buttons
  light: '#e9e4ff',     // Light purple for backgrounds
  veryLight: '#f0ecff', // Very light purple for subtle backgrounds
};

const XactimatePreviewPage = ({ estimatePdf, propertyPdf, data }) => {
  // ------------------ Local States ------------------
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState('Roofing');
  const [termsText, setTermsText] = useState('');
  const [defaultNotes, setDefaultNotes] = useState(defaultNotesJson); 

  // A local temp state to store in-progress edits to default note names
  const [tempNoteKeys, setTempNoteKeys] = useState({});

  const [selectedRow, setSelectedRow] = useState(null);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [rightPanelContent, setRightPanelContent] = useState(''); // 'notes' or 'oliver'
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverItem, setDragOverItem] = useState(null);
  const [draggedNote, setDraggedNote] = useState(null);
  const [dragOverRow, setDragOverRow] = useState(null);
  const [invoiceData, setInvoiceData] = useState({
    number: 'INV-1001',
    clientName: 'John Doe',
    date: '2023-10-24',
    items: [],
    total: 0,
    itemsBySection: {},
  });

  // Section options for the tabs
  const sectionOptions = [
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Interior', label: 'Interior' },
    { value: 'Siding', label: 'Siding' },
    { value: 'Misc', label: 'Misc' },
  ];

  const navigate = useNavigate();

  // ------------------ API Functions ------------------
  const fetchLineItems = async (inputValue) => {
    try {
      const formData = new FormData();
      formData.append('searchTerm', inputValue);  
      formData.append('page', 1);
      const response = await searchLineItems(formData);
      return response;
    } catch (error) {
      console.error('Error fetching line items:', error);
      toast.error('Error fetching line items. Please try again.');
      return null;
    }
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    const response = await fetchLineItems(inputValue);
    if (response && response.data && response.data.lineItems) {
      const options = response.data.lineItems.map(item => ({
        value: item.Description,
        label: item.Description,
        componentCode: item.componentCode,
        unitPrice: item.UnitPrice || 0,
        unit: item.Unit,
      }));
      return options;
    }
    return [];
  };

  // ------------------ Drag and Drop Functions for Items ------------------
  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverItem(index);
  };

  const handleDragEnd = () => {
    // Only reorder items in the same section
    if (draggedItem !== null && dragOverItem !== null && draggedItem !== dragOverItem) {
      const _items = [...items];
      
      // Find the actual indices in the full items array
      const filteredItems = items.filter(item => item.section === activeTab);
      const draggedGlobalIndex = items.indexOf(filteredItems[draggedItem]);
      const dropGlobalIndex = items.indexOf(filteredItems[dragOverItem]);
      
      // Remove the dragged item
      const draggedElement = _items.splice(draggedGlobalIndex, 1)[0];
      
      // Add it at the new position
      _items.splice(dropGlobalIndex, 0, draggedElement);
      
      // Update state
      setItems(_items);
      
      // Reset drag-related states
      setDraggedItem(null);
      setDragOverItem(null);
      
      // If the selected row was involved in the reordering, update it
      if (selectedRow === draggedGlobalIndex) {
        setSelectedRow(dropGlobalIndex);
      } else if (
        selectedRow >= Math.min(draggedGlobalIndex, dropGlobalIndex) && 
        selectedRow <= Math.max(draggedGlobalIndex, dropGlobalIndex)
      ) {
        if (draggedGlobalIndex < dropGlobalIndex) {
          setSelectedRow(selectedRow - 1);
        } else {
          setSelectedRow(selectedRow + 1);
        }
      }
    }
  };

  // ------------------ Drag and Drop Functions for Notes ------------------
  const handleNoteDragStart = (e, noteKey) => {
    e.stopPropagation();
    // Set data to transfer
    e.dataTransfer.setData('text/plain', noteKey);
    // Store which note is being dragged
    setDraggedNote(noteKey);
    
    // Create a ghost image for drag preview
    const ghostElement = document.createElement('div');
    ghostElement.classList.add('note-drag-preview');
    ghostElement.innerText = `${noteKey} Note`;
    ghostElement.style.padding = '8px';
    ghostElement.style.background = '#f0ecff';
    ghostElement.style.border = '1px solid #6a5acd';
    ghostElement.style.borderRadius = '4px';
    ghostElement.style.position = 'absolute';
    ghostElement.style.top = '-1000px';
    document.body.appendChild(ghostElement);
    
    e.dataTransfer.setDragImage(ghostElement, 0, 0);
    
    // Set the drag effect
    e.dataTransfer.effectAllowed = 'copy';
  };

  const handleRowDragOver = (e, index) => {
    e.preventDefault();
    // We're dragging a note over a row
    if (draggedNote) {
      e.dataTransfer.dropEffect = 'copy';
      setDragOverRow(index);
    }
  };

  const handleRowDragLeave = () => {
    setDragOverRow(null);
  };

  const handleRowDrop = (e, rowIndex) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Get the note key from dataTransfer
    const noteKey = e.dataTransfer.getData('text/plain');
    
    if (noteKey && defaultNotes[noteKey]) {
      // Get the global index of the row
      const filteredItems = items.filter(item => item.section === activeTab);
      const globalRowIndex = items.indexOf(filteredItems[rowIndex]);
      
      // Get a copy of the items array
      const updatedItems = [...items];
      
      // Get the note template
      const noteTemplate = defaultNotes[noteKey];
      
      // Check if the item already has a notes array, if not, create one
      if (!updatedItems[globalRowIndex].notes) {
        updatedItems[globalRowIndex].notes = [];
      }
      
      // Add the new notes from the template (avoid duplicates if desired)
      const existingTexts = new Set(updatedItems[globalRowIndex].notes.map(n => n.text));
      noteTemplate.value.forEach(nt => {
        if (!existingTexts.has(nt.text)) {
          updatedItems[globalRowIndex].notes.push({ ...nt });
        }
      });
      
      // Update the items state
      setItems(updatedItems);
      
      // Show success notification
      toast.success(`Added "${noteKey}" notes to line item`, {
        position: 'bottom-right',
        autoClose: 2000
      });
    }
    
    // Reset drag states
    setDraggedNote(null);
    setDragOverRow(null);
  };

  // ------------------ Item Management ------------------

  /**
   * Helper function to attach default notes to a single line item’s description
   * (based on whether the item description includes any of the default note keys).
   */
  const attachMatchingDefaultNotes = (lineItem) => {
    if (!lineItem.description) return;
    const descLower = lineItem.description.toLowerCase();
    
    Object.keys(defaultNotes).forEach((noteKey) => {
      // If the note key is included in the description
      if (descLower.includes(noteKey.toLowerCase())) {
        if (!lineItem.notes) {
          lineItem.notes = [];
        }
        const existingTexts = new Set(lineItem.notes.map(n => n.text));
        defaultNotes[noteKey].value.forEach((nt) => {
          if (!existingTexts.has(nt.text)) {
            lineItem.notes.push({ ...nt });
          }
        });
      }
    });
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    if (selectedRow === index) {
      setSelectedRow(null);
    } else if (selectedRow > index) {
      setSelectedRow(selectedRow - 1);
    }
  };

  const parseData = (line_items) => {
    const lineItemsText = line_items || (data && data.line_items);
    
    if (!lineItemsText) {
      console.error('line_items is undefined or empty.');
      return;
    }

    const pattern = /\*\*(\d+):\s*(.*?)\s*:\*\*[\s\S]*?- \*\*Quantity:\*\* ([\d\.,]+ \w+)/g;
    const sectionPattern = /##\s*(\w+)/g;

    const matches = [...lineItemsText.matchAll(pattern)];
    const sections = [...lineItemsText.matchAll(sectionPattern)];

    if (!matches.length) {
      console.warn('No line items matched the pattern.');
      return;
    }

    let currentSection = 'Unknown'; 
    const parsedItems = [];
    let sectionIndex = 0;

    matches.forEach(match => {
      while (
        sectionIndex < sections.length &&
        match.index > sections[sectionIndex].index
      ) {
        currentSection = sections[sectionIndex][1];
        sectionIndex++;
      }

      parsedItems.push({
        type: 'lineItem',
        description: match[2]?.trim() || "",
        quantity: parseFloat(match[3].replace(',', '')) || 0,
        price: 0,
        notes: [],
        section: currentSection,
        componentCode: '',
        unitPrice: 0,
        unit: '',
      });
    });

    if (!parsedItems.length) {
      console.warn('No parsed items were generated.');
      return;
    }

    // After we parse them, check for default note matches:
    parsedItems.forEach((item) => attachMatchingDefaultNotes(item));

    setItems(parsedItems);
  };

  useEffect(() => {
    if (data) {
      parseData();
    }
  }, [data]);

  const addLineItem = () => {
    setItems([
      ...items,
      {
        type: 'lineItem',
        description: '',
        quantity: 1,
        price: 0,
        notes: [],
        section: activeTab, // Use the active tab as the default section
        componentCode: '',
        unitPrice: 0,
        unit: '',
      },
    ]);
  };

  // Allow user to edit notes for a line item
  const handleLineItemNoteChange = (itemIndex, noteIndex, field, value) => {
    const updatedItems = [...items];
    if (!updatedItems[itemIndex].notes) {
      updatedItems[itemIndex].notes = [];
    }
    updatedItems[itemIndex].notes[noteIndex][field] = value;
    setItems(updatedItems);
  };

  const handleAddLineItemNote = (itemIndex) => {
    const updatedItems = [...items];
    if (!updatedItems[itemIndex].notes) {
      updatedItems[itemIndex].notes = [];
    }
    updatedItems[itemIndex].notes.push({ text: '', bold: false });
    setItems(updatedItems);
  };

  const handleRemoveLineItemNote = (itemIndex, noteIndex) => {
    const updatedItems = [...items];
    updatedItems[itemIndex].notes.splice(noteIndex, 1);
    setItems(updatedItems);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];

    if (field === 'description') {
      const selectedOption = value;
      if (selectedOption) {
        updatedItems[index].description = selectedOption.label;
        updatedItems[index].componentCode = selectedOption.componentCode;
        updatedItems[index].unitPrice = selectedOption.unitPrice || 0;
        updatedItems[index].unit = selectedOption.unit;
        updatedItems[index].price = selectedOption.unitPrice || 0;
      } else {
        updatedItems[index].description = '';
        updatedItems[index].componentCode = '';
        updatedItems[index].unitPrice = 0;
        updatedItems[index].unit = '';
        updatedItems[index].price = 0;
      }
      // After setting the description, attach any matching default notes
      attachMatchingDefaultNotes(updatedItems[index]);
    } 
    else if (field === 'quantity') {
      // Allow decimals
      updatedItems[index].quantity = parseFloat(value) || 1;
    } 
    else {
      updatedItems[index][field] = value;
    }

    setItems(updatedItems);
  };

  useEffect(() => {
    const total = items.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );

    const itemsBySection = {};
    items.forEach(item => {
      const section = item.section || 'Roofing';
      if (itemsBySection[section]) {
        itemsBySection[section].push(item);
      } else {
        itemsBySection[section] = [item];
      }
    });

    // Ensure the PDF previewer always displays sections in the correct order
    const orderedSectionKeys = ['Roofing', 'Interior', 'Siding', 'Misc'];
    const orderedItemsBySection = {};
    orderedSectionKeys.forEach(sec => {
      if (itemsBySection[sec]) {
        orderedItemsBySection[sec] = itemsBySection[sec];
      }
    });

    setInvoiceData(prevData => ({
      ...prevData,
      items: items,
      itemsBySection: orderedItemsBySection,
      total: total,
    }));
  }, [items]);

  // ------------------ Event Handlers ------------------
  const handleChatbotResponse = (final_output) => {
    console.log('Chatbot final_output:', final_output);
    if (final_output) {
      try {
        parseData(final_output);
      } catch (error) {
        console.error('Error parsing action_output:', error);
      }
    }
  };

  const toggleRightPanel = (content) => {
    if (rightPanelOpen && rightPanelContent === content) {
      setRightPanelOpen(false);
    } else {
      setRightPanelOpen(true);
      setRightPanelContent(content);
    }
  };

  const generateRandomString = (length = 4) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Thank you, we have queued your Xactimate estimate."); 
    
    try {
      const formData = new FormData();
      formData.append("account_id", localStorage.getItem("accountId"));
      const randomString = generateRandomString();

      function renameFile(originalFile, newFilename) {
        return new File([originalFile], newFilename, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
      }

      const estimatePdfName = estimatePdf.name || 'estimate.pdf';
      const propertyPdfName = propertyPdf.name || 'property.pdf';

      function getFileNameAndExtension(filename) {
        const lastDotIndex = filename.lastIndexOf('.');
        if (lastDotIndex === -1) {
          return { name: filename, extension: '' };
        }
        const name = filename.substring(0, lastDotIndex);
        const extension = filename.substring(lastDotIndex);
        return { name, extension };
      }

      const estimateFileParts = getFileNameAndExtension(estimatePdfName);
      const propertyFileParts = getFileNameAndExtension(propertyPdfName);

      const newEstimatePdfName = `${estimateFileParts.name}_${randomString}${estimateFileParts.extension}`;
      const newPropertyPdfName = `${propertyFileParts.name}_${randomString}${propertyFileParts.extension}`;

      const newEstimatePdf = renameFile(estimatePdf, newEstimatePdfName);
      const newPropertyPdf = renameFile(propertyPdf, newPropertyPdfName);

      formData.append("estimate_pdf", newEstimatePdf);
      formData.append("property_pdf", newPropertyPdf);
      formData.append("terms_text", termsText);
      formData.append("include_overhead", data.include_overhead_profit);
      formData.append("line_items", JSON.stringify(items));

      const headers = {"Content-Type": "multipart/form-data"};
      const response = await generateEstimate(formData, headers);
      toast.update(toastId, { 
        render: "You can check your files for the status and we will notify the email on file once complete.", 
        type: "success", 
        isLoading: false, 
        autoClose: 5000 
      });
      navigate("/editAccount");
      console.log("Response:", response);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  // ------------------ Default Notes Management ------------------

  /**
   * 1) We store what the user is typing in `tempNoteKeys` so that we don't rename the object on each keystroke.
   * 2) On blur, we commit the rename if needed.
   */

  // Called on each keystroke for the default note name
  const handleNoteKeyTyping = (oldKey, newTypedValue) => {
    setTempNoteKeys((prev) => ({
      ...prev,
      [oldKey]: newTypedValue
    }));
  };

  // When user blurs (clicks away), do the actual rename
  const handleNoteKeyBlur = (oldKey, typedName) => {
    // If typedName is empty, revert
    if (!typedName.trim()) {
      // Remove from temp so it reverts visually
      setTempNoteKeys((prev) => {
        const copy = { ...prev };
        delete copy[oldKey];
        return copy;
      });
      return;
    }

    // If they didn't really change it, just remove from temp and do nothing
    if (typedName === oldKey) {
      setTempNoteKeys((prev) => {
        const copy = { ...prev };
        delete copy[oldKey];
        return copy;
      });
      return;
    }

    // Otherwise, rename the key in defaultNotes
    // preserving the order
    const updatedNotes = {};
    Object.keys(defaultNotes).forEach((existingKey) => {
      if (existingKey === oldKey) {
        updatedNotes[typedName] = defaultNotes[oldKey];
      } else {
        updatedNotes[existingKey] = defaultNotes[existingKey];
      }
    });
    setDefaultNotes(updatedNotes);

    // Remove from temp
    setTempNoteKeys((prev) => {
      const copy = { ...prev };
      delete copy[oldKey];
      return copy;
    });
  };

  const handleDefaultNoteValueChange = (key, index, field, value) => {
    const updatedNotes = { ...defaultNotes };
    updatedNotes[key].value[index][field] = value;
    setDefaultNotes(updatedNotes);
  };

  const handleAddNewDefaultNote = () => {
    // Create a new notes object with "New Line Item" at the beginning
    const newKey = "New Line Item";
    const updatedNotes = {
      [newKey]: {
        note: newKey,
        value: [
          { text: "New note text", bold: false }
        ]
      },
      ...defaultNotes // Add the existing notes after the new one
    };
    setDefaultNotes(updatedNotes);
  };

  // ------------------ Rendering Functions ------------------
  const renderDefaultNotesEditor = () => {
    return (
      <div className="p-4" style={{ height: '100%', overflowY: 'auto' }}>
        <div className="mb-4 p-2 rounded bg-light">
          <p className="fw-bold text-dark mb-1">Drag & Drop Notes</p>
          <p className="small text-muted mb-0">Drag any note below onto a line item to attach it</p>
        </div>
        
        <Button 
          variant="primary" 
          onClick={handleAddNewDefaultNote} 
          className="mb-4 w-100"
          style={{ backgroundColor: purpleColors.medium, borderColor: purpleColors.medium }}
        >
          Add New Default Note
        </Button>
        
        {Object.keys(defaultNotes).map((key) => {
          // If the user has partially typed a new name, show that
          const typedValue = tempNoteKeys[key] !== undefined ? tempNoteKeys[key] : key;
          return (
            <div 
              key={key} 
              className="mb-4 p-4 rounded shadow-sm" 
              style={{ 
                backgroundColor: draggedNote === key ? purpleColors.light : '#f8f9fa',
                cursor: 'grab',
                borderLeft: draggedNote === key ? `4px solid ${purpleColors.medium}` : '4px solid transparent'
              }}
              draggable
              onDragStart={(e) => handleNoteDragStart(e, key)}
              onDragEnd={() => setDraggedNote(null)}
            >
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Form.Label className="fw-bold mb-0" style={{ color: purpleColors.dark }}>
                  {/** The key name input **/}
                  <Form.Control
                    type="text"
                    className="text-dark border"
                    value={typedValue}
                    onChange={(e) => handleNoteKeyTyping(key, e.target.value)}
                    onBlur={(e) => handleNoteKeyBlur(key, e.target.value)}
                    style={{ fontWeight: 'bold', padding: '0.25rem 0.5rem' }}
                  />
                </Form.Label>

                <div 
                  style={{ 
                    color: purpleColors.medium, 
                    cursor: 'grab',
                    opacity: 0.7
                  }}
                  className="drag-note-handle"
                >
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 11V8L14 12L18 16V13H22V11H18ZM6 7H15V9H6C4.9 9 4 9.9 4 11V21C4 22.1 4.9 23 6 23H16C17.1 23 18 22.1 18 20V18H16V20H6V11H16V12L20 8V7C20 5.9 19.1 5 18 5H6C4.9 5 4 5.9 4 7V8H6V7Z" fill="#6a5acd"/>
                  </svg>
                </div>
              </div>
              
              {defaultNotes[key].value.map((val, vIndex) => (
                <div key={vIndex} className="mb-3 p-3 rounded" style={{ backgroundColor: 'white', border: '1px solid #dee2e6' }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ color: purpleColors.dark }}>Text</Form.Label>
                    <Form.Control
                      type="text"
                      className="text-dark"
                      value={val.text}
                      onChange={(e) => handleDefaultNoteValueChange(key, vIndex, 'text', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Bold"
                    checked={val.bold}
                    onChange={(e) => handleDefaultNoteValueChange(key, vIndex, 'bold', e.target.checked)}
                    className="text-dark"
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  // Filter items based on the active tab/section
  const filteredItems = items.filter(item => item.section === activeTab);

  return (
    <Container fluid style={{ padding: '2rem', backgroundColor: '#f5f7fa', minHeight: '100vh' }}>
      {/* Main Content Area - 75% width */}
      <div className="mx-auto" style={{ width: '75%' }}>
        <div style={{ 
          backgroundColor: 'white', 
          borderRadius: '12px', 
          boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
          overflow: 'hidden'
        }}>
          <Row className="g-0">
            <Col className="p-4">
              <h1 className="mb-3" style={{ color: '#333', fontWeight: '600' }}>{activeTab}</h1>
              
              {/* Horizontal Tab Navigation */}
              <Nav className="mb-4">
                {sectionOptions.map(section => (
                  <Nav.Item key={section.value}>
                    <Nav.Link 
                      active={activeTab === section.value}
                      onClick={() => setActiveTab(section.value)}
                      style={{ 
                        color: activeTab === section.value ? '#fff' : '#495057',
                        backgroundColor: activeTab === section.value ? purpleColors.medium : purpleColors.veryLight,
                        margin: '0 5px 0 0',
                        padding: '0.5rem 1.5rem',
                        borderRadius: '0.25rem',
                        fontWeight: activeTab === section.value ? '500' : '400'
                      }}
                    >
                      {section.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                
                {/* Tool buttons on the right */}
                <div className="ms-auto d-flex">
                  <Button 
                    variant={rightPanelOpen && rightPanelContent === 'oliver' ? "primary" : "outline-secondary"} 
                    onClick={() => toggleRightPanel('oliver')}
                    className="me-2"
                    style={{ 
                      backgroundColor: rightPanelOpen && rightPanelContent === 'oliver' ? purpleColors.medium : 'transparent',
                      borderColor: rightPanelOpen && rightPanelContent === 'oliver' ? purpleColors.medium : '#ced4da',
                      color: rightPanelOpen && rightPanelContent === 'oliver' ? 'white' : '#6c757d'
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-1">Oliver</span>
                      {rightPanelOpen && rightPanelContent === 'oliver' && (
                        <span>&times;</span>
                      )}
                    </span>
                  </Button>
                  <Button 
                    variant={rightPanelOpen && rightPanelContent === 'notes' ? "primary" : "outline-secondary"} 
                    onClick={() => toggleRightPanel('notes')}
                    style={{ 
                      backgroundColor: rightPanelOpen && rightPanelContent === 'notes' ? purpleColors.medium : 'transparent',
                      borderColor: rightPanelOpen && rightPanelContent === 'notes' ? purpleColors.medium : '#ced4da',
                      color: rightPanelOpen && rightPanelContent === 'notes' ? 'white' : '#6c757d'
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <span className="me-1">Default Notes</span>
                      {rightPanelOpen && rightPanelContent === 'notes' && (
                        <span>&times;</span>
                      )}
                    </span>
                  </Button>
                </div>
              </Nav>
              
              {/* Main Content with Table */}
              <Row className="g-0">
                {/* Main Table Panel */}
                <Col md={rightPanelOpen ? 8 : 12} className={rightPanelOpen ? "pe-4" : ""}>
                  <div style={{ minHeight: '60vh' }}>
                    <Table hover className="mb-0" style={{ borderCollapse: 'collapse' }}>
                      <thead>
                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                          <th style={{ width: '5%', padding: '12px 15px', textAlign: 'left', color: '#666' }}>No.</th>
                          <th style={{ width: '10%', padding: '12px 15px', textAlign: 'center', color: '#666' }}>Order</th>
                          <th style={{ width: '55%', padding: '12px 15px', textAlign: 'left', color: '#666' }}>DESCRIPTION</th>
                          <th style={{ width: '30%', padding: '12px 15px', textAlign: 'right', color: '#666' }}>QUANTITY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredItems.length > 0 ? (
                          filteredItems.map((item, index) => (
                            <tr 
                              key={index} 
                              onClick={() => setSelectedRow(items.indexOf(item))}
                              draggable
                              onDragStart={() => handleDragStart(index)}
                              onDragOver={(e) => handleDragOver(e, index)}
                              onDragEnd={handleDragEnd}
                              onDrop={(e) => handleRowDrop(e, index)}
                              onDragEnter={(e) => handleRowDragOver(e, index)}
                              onDragLeave={handleRowDragLeave}  // <--- Fixed here
                              style={{ 
                                cursor: draggedItem === index ? 'grabbing' : 'grab',
                                borderBottom: '1px solid #f0f0f0',
                                backgroundColor: draggedItem === index ? 'rgba(107, 90, 205, 0.1)' : 
                                                dragOverItem === index ? 'rgba(107, 90, 205, 0.05)' : 
                                                dragOverRow === index ? 'rgba(107, 90, 205, 0.1)' :
                                                selectedRow === items.indexOf(item) ? 'rgba(107, 90, 205, 0.05)' : 'transparent',
                                border: dragOverRow === index ? `2px dashed ${purpleColors.medium}` :
                                        selectedRow === items.indexOf(item) ? `1px solid ${purpleColors.medium}` : 'none',
                                borderRadius: selectedRow === items.indexOf(item) ? '4px' : '0',
                                opacity: draggedItem === index ? 0.7 : 1,
                                transform: draggedItem === index ? 'scale(0.98)' : 'scale(1)',
                                transition: 'background-color 0.2s, opacity 0.2s, transform 0.2s'
                              }}
                            >
                              <td style={{ padding: '12px 15px' }}>{index + 1}</td>
                              <td style={{ padding: '12px 15px', textAlign: 'center' }}>
                                <div 
                                  style={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center',
                                    cursor: 'grab' 
                                  }}
                                  className="drag-handle"
                                >
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 9H16M8 15H16" stroke="#666" strokeWidth="2" strokeLinecap="round"/>
                                  </svg>
                                </div>
                              </td>
                              <td style={{ padding: '12px 15px' }}>
                                {item.description}
                                {item.notes && item.notes.length > 0 && (
                                  <div className="mt-1">
                                    <small className="text-muted">
                                      <i className="fas fa-sticky-note me-1"></i>
                                      {item.notes.length} notes
                                    </small>
                                  </div>
                                )}
                                {dragOverRow === index && (
                                  <div 
                                    className="dropzone-indicator"
                                    style={{ 
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      backgroundColor: 'rgba(107, 90, 205, 0.1)',
                                      borderRadius: '4px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      zIndex: 1,
                                      pointerEvents: 'none'
                                    }}
                                  >
                                    <div style={{ 
                                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                      padding: '4px 8px',
                                      borderRadius: '4px',
                                      color: purpleColors.medium,
                                      fontWeight: 'bold',
                                      fontSize: '0.8rem'
                                    }}>
                                      Drop to add notes
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td style={{ padding: '12px 15px', textAlign: 'right' }}>
                                {item.quantity} {item.unit}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center py-4 text-muted">
                              No items in this section. Click "Add Line Item" to add some.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    {/* Item Edit Form (shows when a row is selected) */}
                    {selectedRow !== null && (
                      <div className="mt-4 p-4 rounded shadow-sm" style={{ backgroundColor: '#f8f9fa' }}>
                        <h5 className="mb-3" style={{ color: purpleColors.dark, fontWeight: '600' }}>Edit Line Item</h5>
                        <Row className="mb-3">
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label style={{ color: purpleColors.dark, fontWeight: '500' }}>Description</Form.Label>
                              <AsyncSelect
                                styles={customSelectStyles}
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                onChange={(selectedOption) =>
                                  handleItemChange(selectedRow, 'description', selectedOption)
                                }
                                placeholder="Select a line item..."
                                isClearable
                                value={
                                  items[selectedRow].description
                                    ? { label: items[selectedRow].description, value: items[selectedRow].description }
                                    : null
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label style={{ color: purpleColors.dark, fontWeight: '500' }}>Quantity</Form.Label>
                              <Form.Control
                                type="number"
                                step="any"
                                value={items[selectedRow].quantity}
                                onChange={(e) =>
                                  handleItemChange(selectedRow, 'quantity', e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label style={{ color: purpleColors.dark, fontWeight: '500' }}>Section</Form.Label>
                              <Select
                                styles={customSelectStyles}
                                options={sectionOptions}
                                value={sectionOptions.find(option => option.value === items[selectedRow].section)}
                                onChange={(selectedOption) =>
                                  handleItemChange(selectedRow, 'section', selectedOption ? selectedOption.value : '')
                                }
                                placeholder="Select a section..."
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* -- Removed "Select Default Notes" section per request -- */}

                        {/* Edit existing notes directly */}
                        <div className="mt-3 mb-3 p-3 rounded" style={{ backgroundColor: 'white', border: '1px solid #dee2e6' }}>
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h6 className="mb-0" style={{ color: purpleColors.dark, fontWeight: '600' }}>Notes:</h6>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              onClick={() => handleAddLineItemNote(selectedRow)}
                            >
                              Add Note
                            </Button>
                          </div>
                          {items[selectedRow].notes && items[selectedRow].notes.length > 0 ? (
                            items[selectedRow].notes.map((note, noteIndex) => (
                              <div 
                                key={noteIndex}
                                className="mb-3 p-3 rounded"
                                style={{ backgroundColor: '#f8f9fa', border: '1px solid #eee' }}
                              >
                                <Form.Group className="mb-2">
                                  <Form.Label style={{ color: purpleColors.dark }}>Note Text</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={note.text}
                                    onChange={(e) => handleLineItemNoteChange(selectedRow, noteIndex, 'text', e.target.value)}
                                  />
                                </Form.Group>
                                <Form.Check
                                  type="checkbox"
                                  label="Bold"
                                  checked={note.bold}
                                  onChange={(e) => handleLineItemNoteChange(selectedRow, noteIndex, 'bold', e.target.checked)}
                                  className="text-dark mb-2"
                                />
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => handleRemoveLineItemNote(selectedRow, noteIndex)}
                                >
                                  Remove
                                </Button>
                              </div>
                            ))
                          ) : (
                            <p className="text-muted mb-0">No notes added yet.</p>
                          )}
                        </div>

                        <Row>
                          <Col>
                            <Button 
                              variant="outline-danger" 
                              onClick={() => removeItem(selectedRow)}
                              className="me-2"
                            >
                              Remove Item
                            </Button>
                            <Button 
                              variant="outline-secondary" 
                              onClick={() => setSelectedRow(null)}
                              className="ms-2"
                            >
                              Close
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {/* Add Line Item Button */}
                    <div className="d-flex justify-content-end mt-3">
                      <Button 
                        variant="primary" 
                        onClick={addLineItem}
                        style={{ backgroundColor: purpleColors.medium, borderColor: purpleColors.medium }}
                      >
                        Add Line Item
                      </Button>
                    </div>
                  </div>
                </Col>

                {/* Collapsible Right Panel */}
                {rightPanelOpen && (
                  <Col md={4} style={{ 
                    borderLeft: '1px solid #eee',
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '70vh'
                  }}>
                    <div className="border-bottom" style={{ 
                      padding: '16px', 
                      backgroundColor: '#f8f9fa', 
                      borderLeft: `4px solid ${purpleColors.medium}`
                    }}>
                      <h5 className="m-0 fw-bold" style={{ color: purpleColors.dark }}>
                        {rightPanelContent === 'oliver' ? 'Oliver Assistant' : 'Default Notes'}
                      </h5>
                    </div>
                    
                    {rightPanelContent === 'oliver' ? (
                      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <XactimateChatbotWidget 
                          onResponse={handleChatbotResponse} 
                          estimatePdf={data.line_items} 
                          propertyPdf={data.measurements} 
                          style={{ flex: 1, height: '100%' }}
                        />
                      </div>
                    ) : (
                      renderDefaultNotesEditor()
                    )}
                  </Col>
                )}
              </Row>

              {/* Footer Buttons */}
              <div className="d-flex justify-content-center mt-5 pt-3" style={{ borderTop: '1px solid #eee' }}>
                <Button
                  className="px-4 py-2 mx-2"
                  style={{ 
                    backgroundColor: purpleColors.veryLight, 
                    color: purpleColors.medium, 
                    border: 'none',
                    borderRadius: '6px',
                    fontWeight: '500'
                  }}
                >
                  <PDFDownloadLink
                    document={<XactimatePdfHeaderPage data={invoiceData} clientInfo={data}/>}
                    fileName="preview-xactimate.pdf"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {({ blob, url, loading, error }) => {
                      if (loading) return 'Loading document...';
                      if (error) return 'Error generating document';
                      return 'Preview Xactimate';
                    }}
                  </PDFDownloadLink>
                </Button>

                <Button
                  className="px-4 py-2 mx-2"
                  onClick={handleSubmit}
                  style={{ 
                    backgroundColor: purpleColors.medium, 
                    color: 'white', 
                    border: 'none',
                    borderRadius: '6px',
                    fontWeight: '500'
                  }}
                >
                  Generate Estimate
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default XactimatePreviewPage;
