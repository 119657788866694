import React, { useState } from "react";
import { Container, Nav, Card, Row, Col, Button } from "react-bootstrap";
import { FiMail, FiInfo } from "react-icons/fi";
import { toast } from "react-toastify";
import TinyEditor from "./TextEditor";
import { uploadAdjusterMail } from "../services/uploads/upload.service";
import { updateTokens } from "../services/account/account.service";
import { GiStairs } from "react-icons/gi";

const Home = ({
  content,
  setContent,
  setSummaryText,
  setEmailText,
  setAudio,
  setOrganizedArguments,
  setRepairType,
  setFullArguments,
  setDifferences,
  setEmailJest,
  goToNextStep,
}) => {
  const [loading, setLoading] = useState(false);

  // Process Steps (Left Column)
  const [activeIndex, setActiveIndex] = useState(null);
  const steps = [
    "Adjusters Email",
    "Summary of Arguments",
    "Generate Email",
    "Send Email"
  ];
  
  const stepDescriptions = [
    {
      title: "Adjusters Email",
      content:
        "Add the adjusters emails received from an insurance company. Copy and paste the email received by the adjuster. Our software will analyze the email and figure out how to argue your claim for more money with evidence from regulations."
    },
    {
      title: "Summary of Arguments",
      content:
        "We will provide a summary of arguments that we will include in the email."
    },
    {
      title: "Generate Email",
      content:
        "Next we will generate an email arguing your claim. We provide a detailed argument and sometimes PDFs with evidence backing the arguments."
    },
    {
      title: "Send Email",
      content:
        "Once confirmed and you agree with the arguments or you make any changes, send the email out."
    }
  ];
  
  // (Optional) Info Modal for Process Steps
  const [showInfo, setShowInfo] = useState(false);

  // Token count
  const tokenCount = localStorage.getItem("tokenCount") || 0;

  // Handler for the Next button using toast.promise
  const handleNext = async () => {
    const tokenCurrentCount = parseInt(tokenCount, 10);
    if (tokenCurrentCount <= 0) {
      toast.error(
        "Sorry, you don't have enough tokens to proceed. Token Count: " +
          tokenCurrentCount
      );
      return;
    }

    setLoading(true);

    // Wrap the API calls in a promise that toast.promise can track
    try {
      await toast.promise(
        (async () => {
          // Create FormData with the adjuster's email response
          const formData = new FormData();
          formData.append("adjuster_email", content);

          // Set headers for the request
          const headers = { "Content-Type": "multipart/form-data" };
          const tokensHeader = { "Content-Type": "application/json" };

          // Call the API that processes the adjuster's email
          const response = await uploadAdjusterMail(formData, headers);

          // Update state with data returned from the API
          setSummaryText(response.data.summary);
          setEmailText(response.data.email);
          setAudio(response.data.audio_url);
          setOrganizedArguments(response.data.email_arguments);
          setRepairType(response.data.repair_type);
          setFullArguments(response.data.full_arguments);
          setDifferences(response.data.differences);
          setEmailJest(response.data.email_jest);

          // Deduct tokens from the user's account
          await updateTokens(
            { tokens: 1, account_id: localStorage.getItem("accountId") },
            tokensHeader
          );

          // Proceed to the next step
          goToNextStep();
        })(),
        {
          pending: "Generating summary of your argument...",
          success: "Summary generated successfully",
          error: "Error generating summary. Try again later",
        }
      );
    } catch (error) {
      console.error("Error generating summary:", error);
      // The error toast is automatically shown via toast.promise
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="px-4 py-5 gradient-bg" style={{ minHeight: "90vh" }}>
      <Card
        className="elevation-xxl border-0 overflow-hidden"
        style={{ borderRadius: "1.5rem", minHeight: "80vh" }}
      >
        {/* Navigation Tabs */}
        <Row
          className="g-0 align-items-center"
          style={{
            background:
              "linear-gradient(45deg, rgb(29, 31, 59) 70%, rgb(36, 9, 171) 100%)",
          }}
        >
          <Nav
            variant="pills"
            className="modern-tabs px-4 pt-4 w-100 d-flex align-items-center "
          >
            <Nav.Item className="gradient-bg">
              <Nav.Link active className="d-flex align-items-center ">
                <FiMail className="me-2 gradient-bg" />
                Email Response
              </Nav.Link>
            </Nav.Item>
            <div className="flex-grow-1 d-flex justify-content-center">
              <h1 className="text-white text-center">NEGOTIATOR</h1>
            </div>
          </Nav>
        </Row>

        {/* Main Content Area */}
        <Row className="g-4 p-4 " style={{ marginTop: "5vh", minHeight: "70vh" }}>
          {/* Left “Help” Section with Steps */}
          <Col lg={4} className="pe-lg-4 ">
            <Card className="h-100 border-0 shadow-sm process-tracker">
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="text-uppercase tracking-wide m-0">
                    <GiStairs className="me-2" />
                    Estimate Creation Steps
                  </h5>
                  <Button
                    variant="link"
                    className="ms-auto text-muted"
                    onClick={() => setShowInfo(true)}
                    title="More info about these steps"
                  >
                    <FiInfo size={20} />
                  </Button>
                </div>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`process-step ${activeIndex === index ? "active" : ""}`}
                    onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                  >
                    <div className="step-indicator">
                      <div className="step-number">{index + 1}</div>
                      {activeIndex === index && <div className="step-progress" />}
                    </div>
                    <div className="step-content">
                      <h6 className="mb-1">{step}</h6>
                      {activeIndex === index && (
                        <p className="text-muted small mb-0">
                          {stepDescriptions[index].content}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
                <span className="d-block fs-6 fw-400 mt-3 text-secondary">
                  Daily Cortex Tokens: {tokenCount}
                </span>
              </Card.Body>
            </Card>
          </Col>

          {/* Email Response Section */}
          <Col lg={8}>
            <Card className="h-100 border-0 shadow-sm d-flex flex-column">
              <Card.Body className="d-flex flex-column">
                <div className="email-editor-container">
                  <h5 className="text-uppercase mb-4">
                    <FiMail className="me-2" />
                    Adjuster Response
                  </h5>
                  <TinyEditor
                    setSummary={setContent}
                    title="Paste insurance adjuster's email response"
                  />
                </div>
                {/* Next Button */}
                <div className="mt-auto pt-4 border-top d-flex justify-content-end">
                  <Button onClick={handleNext} disabled={loading || !content}>
                    {loading ? "Processing..." : "Next Step"}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      
      {/* Global Styles */}
      <style jsx global>{`
        :root {
          --primary-gradient: linear-gradient(135deg, #0d6efd 0%, #0b5ed7 100%);
        }
        .modern-tabs .nav-link {
          border-radius: 0.75rem;
          padding: 0.75rem 1.5rem;
          transition: all 0.3s ease;
          border: 1px solid transparent;
        }
        .modern-tabs .nav-link.active {
          background: var(--primary-gradient);
          color: white !important;
          border-color: rgba(255, 255, 255, 0.15);
          box-shadow: 0 4px 15px rgba(13, 110, 253, 0.25);
        }
        /* Example styling for the steps */
        .process-tracker .process-step {
          padding: 1rem;
          margin: 0.5rem 0;
          border-radius: 0.75rem;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .process-tracker .process-step.active {
          background: rgba(13, 110, 253, 0.05);
          border-left: 3px solid #0d6efd;
        }
        .process-tracker .step-indicator {
          position: relative;
          width: 32px;
          height: 32px;
        }
        .process-tracker .step-indicator .step-number {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: #e9ecef;
          border-radius: 8px;
          font-weight: 600;
        }
        .process-tracker .step-indicator .step-progress {
          position: absolute;
          left: 50%;
          top: 100%;
          height: calc(100% + 1rem);
          width: 2px;
        }
      `}</style>
    </Container>
  );
};

export default Home;
