// XactimatePdfHeaderPage.jsx
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../assets/logo-2.png";

// Styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
  },
  logo: {
    width: 80,
    height: 40,
    marginRight: 10,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  companyInfo: {
    fontSize: 9,
    flex: 1,
  },
  companyInfoLine: {
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    width: '100%',
    marginTop: 2,
    marginBottom: 5,
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
  },

  // Layout blocks
  infoBlock: {
    marginBottom: 10,
  },
  infoLabel: {
    marginRight: 4,
    fontWeight: 'extrabold',
  },
  infoValue: {
    marginRight: 15, 
  },
  separatorLine: {
    marginTop: 8,
    marginBottom: 8,
  },

  // Table styles
  sectionContainer: {
    marginBottom: 20,
  },
  sectionHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  sectionHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  sectionHeaderUnderline: {
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    flex: 1,
    marginLeft: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
  },
  tableRowHeader: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
  },
  // "No." column (5% width)
  noCol: {
    width: '5%',
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold', 
  },
  // DESCRIPTION column (75% width)
  descriptionCol: {
    width: '75%',
    padding: 5,
    textAlign: 'left',
  },
  // QUANTITY column (20% width, right-aligned)
  quantityColHeader: {
    width: '20%',
    padding: 5,
    textAlign: 'right',
  },
  quantityCol: {
    width: '20%',
    padding: 5,
    textAlign: 'right',
  },
  tableCellHeader: {
    fontSize: 9,
    fontWeight: 'bold'
  },
  tableCell: {
    fontSize: 9
  },
  noteRow: {
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 2,
  },
  noteCell: {
    flex: 1,
    padding: 5,
    fontSize: 9,
    fontStyle: 'italic',
  },
  boldNote: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'black',
  },
});

const XactimatePdfHeaderPage = ({ data, clientInfo}) => {
  const sections = Object.keys(data.itemsBySection || {});

  // Safely unpack clientInfo.extracted_info (or default to an empty object)
  const claimInfo = clientInfo.extracted_info || {};

  // Provide default/fallback values if null/undefined
  const insuredName = claimInfo.estimator || "N/A";
  const propertyAddressLine = claimInfo.street_address || "Unknown Street";
  const propertyCity = claimInfo.city || "Unknown City";
  const propertyState = claimInfo.state || "Unknown State";
  const propertyZip = claimInfo.zip_code || "00000";
  const property = `${propertyAddressLine}\n${propertyCity}, ${propertyState} ${propertyZip}`;

  const claimRepName = claimInfo.estimator || "No Claim Rep";
  const claimRepBusiness = "(410) 555-3081"; // Hard-coded fallback
  const claimRepCompany = "AK Public Adjusters"; // Hard-coded fallback

  const estimatorName = claimInfo.estimator || "No Estimator";
  const estimatorBusiness = "(410) 555-3081"; // Hard-coded fallback
  const estimatorCompany = "AK Public Adjusters"; // Hard-coded fallback

  const claimNumber = claimInfo.claim_number || "N/A";
  const policyNumber = claimInfo.policy_number || "N/A";
  const typeOfLoss = "Windstorm, Hail, & WindBlown Debris"; // Hard-coded fallback

  const dateContacted = "7/31/2024 12:00 AM"; // Hard-coded fallback
  const dateOfLoss = claimInfo.date_of_loss || "N/A";
  const dateReceived = "7/31/2024 12:00 AM"; // Hard-coded fallback
  const dateEntered = "12/27/2024 11:08 AM"; // Hard-coded fallback

  const priceList = "MDRO8X_DEC24\nRestoration/Service/Remodel"; // Hard-coded fallback
  const estimate = "1 gazillion bucks :)"; // Hard-coded fallback

  return (
    <Document>
      {/* -- Page 1: Header Info -- */}
      <Page size="A4" style={styles.page}>
        {/* Header Logo and Company Info */}
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.companyInfo}>
            <Text>AK Public Adjusters</Text>
            <View style={styles.companyInfoLine} />
            <Text>11436 Cronridge Drive, Suite E</Text>
            <Text>Owings Mills MD 21117</Text>
          </View>
        </View>

        <Text style={styles.pageTitle}>Xactimate Scope Preview Page</Text>

        {/* Insured & Property */}
        <View style={styles.infoBlock}>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Insured:</Text>
              <Text style={styles.infoValue}>{insuredName}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Property:</Text>
              <Text>{property}</Text>
            </View>
          </View>
        </View>
        <View style={styles.separatorLine} />

        {/* Claim Rep & Estimator */}
        <View style={styles.infoBlock}>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Claim Rep.:</Text>
              <Text style={styles.infoValue}>{claimRepName}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Company:</Text>
              <Text>{claimRepCompany}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Business:</Text>
              <Text style={styles.infoValue}>{claimRepBusiness}</Text>
            </View>
          </View>

          <View style={[styles.infoRow, { marginTop: 8 }]}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Estimator:</Text>
              <Text style={styles.infoValue}>{estimatorName}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Company:</Text>
              <Text>{estimatorCompany}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Business:</Text>
              <Text style={styles.infoValue}>{estimatorBusiness}</Text>
            </View>
          </View>
        </View>
        <View style={styles.separatorLine} />

        {/* Claim Number & Policy Info */}
        <View style={styles.infoBlock}>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Claim Number:</Text>
              <Text style={styles.infoValue}>{claimNumber}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Policy Number:</Text>
              <Text style={styles.infoValue}>{policyNumber}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Type of Loss:</Text>
              <Text>{typeOfLoss}</Text>
            </View>
          </View>
        </View>
        <View style={styles.separatorLine} />

        {/* Dates */}
        <View style={styles.infoBlock}>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Date Contacted:</Text>
              <Text style={styles.infoValue}>{dateContacted}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Date of Loss:</Text>
              <Text>{dateOfLoss}</Text>
            </View>
          </View>

          <View style={[styles.infoRow, { marginTop: 3 }]}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Date Received:</Text>
              <Text style={styles.infoValue}>{dateReceived}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Date Entered:</Text>
              <Text>{dateEntered}</Text>
            </View>
          </View>
        </View>
        <View style={styles.separatorLine} />

        {/* Price List & Estimate */}
        <View style={styles.infoBlock}>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Price List:</Text>
              <Text style={styles.infoValue}>{priceList}</Text>
            </View>
          </View>
        </View>
        <View style={styles.infoBlock}>
          <View style={styles.infoRow}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.infoLabel}>Estimate:</Text>
              <Text>{estimate}</Text>
            </View>
          </View>
        </View>
        <View style={styles.separatorLine} />
      </Page>

      {/* -- Page 2: Table of Sections/Items -- */}
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.companyInfo}>
            <Text>AK Public Adjusters</Text>
            <View style={styles.companyInfoLine}></View>
            <Text>11436 Cronridge Drive, Suite E</Text>
            <Text>Owings Mills MD 21117</Text>
          </View>
        </View>

        {/* Title */}
        <Text style={styles.pageTitle}>Xactimate Scope Preview</Text>

        {/* Sections and Their Tables */}
        {sections.map((sectionName, idx) => (
          <View style={styles.sectionContainer} key={idx}>
            {/* Section Header */}
            <View style={styles.sectionHeaderContainer}>
              <Text style={styles.sectionHeader}>{sectionName}</Text>
              <View style={styles.sectionHeaderUnderline}></View>
            </View>

            {/* Table for the Section */}
            <View style={styles.table}>
              {/* Table Header Row */}
              <View style={styles.tableRowHeader}>
                {/* No. Column Header */}
                <View style={styles.noCol}>
                  <Text style={styles.tableCellHeader}>No.</Text>
                </View>
                {/* DESCRIPTION Header (middle) */}
                <View style={styles.descriptionCol}>
                  <Text style={styles.tableCellHeader}>DESCRIPTION</Text>
                </View>
                {/* QUANTITY Header (far right, right-aligned) */}
                <View style={styles.quantityColHeader}>
                  <Text style={styles.tableCellHeader}>QUANTITY</Text>
                </View>
              </View>

              {/* Items in Section */}
              {data.itemsBySection[sectionName].map((item, index) => (
                <React.Fragment key={index}>
                  {/* Line Item Row */}
                  <View style={styles.tableRow}>
                    <View style={styles.noCol}>
                      <Text style={styles.tableCell}>{index + 1}</Text>
                    </View>
                    <View style={styles.descriptionCol}>
                      <Text style={styles.tableCell}>{item.description || 'N/A'}</Text>
                    </View>
                    {/* Quantity Column (far right) */}
                    <View style={styles.quantityCol}>
                      <Text style={styles.tableCell}>{item.quantity || 0}</Text>
                    </View>
                  </View>

                  {/* Notes Rows (if any) */}
                  {item.notes && item.notes.map((note, noteIndex) => (
                    <View style={styles.noteRow} key={`note-${index}-${noteIndex}`}>
                      {/* Empty numbering column for alignment */}
                      <View style={styles.noCol}></View>
                      {/* Note text spans remaining columns */}
                      <View style={{ flexDirection: 'row', flex: 1 }}>
                        <View style={styles.noteCell}>
                          <Text style={note.bold ? styles.boldNote : {}}>
                            {note.text}
                          </Text>
                        </View>
                      </View>
                    </View>
                  ))}
                </React.Fragment>
              ))}
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default XactimatePdfHeaderPage;
