
// EditAccount.jsx
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Tabs, Tab, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css'; // Import the custom CSS for consistent styling
import { getAccount, updateAccount } from "../services/account/account.service";
import { getXactimateEstimate, getXactimateEstimates,getCompletedJobs} from "../services/uploads/upload.service";

import AIImage from '../assets/AI_Img.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios

const EditAccount = () => {
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        verifyNewPassword: '',
        tokenCount: 0,
    });
    const [key, setKey] = useState('editProfile'); // State to manage active tab
    const [files, setFiles] = useState([]); // State to hold files data

    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');
    const headers = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': localStorage.getItem('accountId')
    };

    useEffect(() => {
        handleGetUser();
        // Fetch files if needed
        handleGetFiles();
    }, []);

    const handleGetUser = async () => {
        const isAuthenticated = authToken && authToken.trim() !== '';
        if (isAuthenticated) {
            const accountId = localStorage.getItem('accountId');
            const requestData = { accountId: accountId };

            try {
                const response = await getAccount(JSON.stringify(requestData), headers);

                if (response && response.data) {
                    setProfileData({
                        ...profileData,
                        firstName: response.data["account"].FirstName || profileData.firstName,
                        lastName: response.data["account"].LastName || profileData.lastName,
                        email: response.data["account"].Email || profileData.email,
                        tokenCount: response.data["account"].Token || profileData.tokenCount,
                    });
                } else {
                    console.error("Invalid response data:", response);
                }
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        } else {
            navigate('/login');
        }
    };

    const handleGetFiles = async () => {
        try {
            const accountId = localStorage.getItem('accountId');
            const authToken = localStorage.getItem('authToken');
            const headers = {
                "Content-Type": "multipart/form-data"
            };
            const requestData = {
                account_id: accountId, // Ensure the key matches what the backend expects
            };
            const formData = new FormData();
            formData.append('account_id',  localStorage.getItem('accountId'));
            // Use getCompletedJobs API function
            const response = await getCompletedJobs(formData, {
                "Content-Type": "multipart/form-data"
            });
            console.log(response);
            // Check if response is successful
            if (response.status === 200) {
                // Transform the backend data to match dummyFiles structure
                const transformedFiles = response.data.completed_jobs.map((job, index) => ({
                    id: index + 1, // Use a unique identifier; adjust as needed
                    name: job.estimates_pdf_name,
                    uploadedAt: job.upload_date.split('T')[0], // Extract date part
                    status: job.job_status === 'completed' ? 'Completed' : job.job_status === 'started' ? 'In Progress' : 'Inactive',
                }));

                setFiles(transformedFiles);
            } else {
                console.error('Failed to fetch the jobs:', response.statusText);
                // Optionally, set dummy data or handle error appropriately
                setFiles([]);
            }
        } catch (error) {
            console.error('Error fetching the jobs:', error);
            // Optionally, set dummy data or handle error appropriately
            setFiles([]);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };
    
    const handleViewFile = async (fileId, fileName) => {
        try {
            const accountId = localStorage.getItem('accountId');
            const authToken = localStorage.getItem('authToken');
            const headers = {
                'Authorization': `${authToken}`,
                'Content-Type': 'application/json',
                'accountId': accountId,
            };
            const requestData = {
                accountId: accountId,
                pdf_file_name: fileName, // Pass the file name to the server
            };
    
            // Use getXactimateEstimate API function
            const response = await getXactimateEstimate(requestData, headers, 'blob');
    
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                // Open the PDF in a new window or tab
                window.open(url);
            } else {
                console.error('Failed to fetch the PDF');
            }
        } catch (error) {
            console.error('Error fetching the PDF:', error);
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (profileData.newPassword !== profileData.verifyNewPassword) {
                alert('Passwords do not match');
                return;
            }

            const isAuthenticated = authToken && authToken.trim() !== '';
            if (isAuthenticated) {
                const response = await updateAccount(JSON.stringify(profileData), headers);

                if (response.status === 200) {
                    alert('Account updated successfully');
                } else {
                    alert('Failed to update account');
                }
            } else {
                navigate('/login');
                return;
            }
        } catch (error) {
            console.error('Error during account update:', error);
        }
    };

    return (
        <Container className="mt-5 container-profile" style={{ marginBottom: '120px', marginTop: '120px' }}>
    <Row className="justify-content-center">
        {/* Edit Profile Content with Sidebar Tabs */}
        <Col md={8}>
            <Card className="card-profile" style={{
                backgroundImage: `url(${AIImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                padding: '20px',
                minHeight: '800px' // Set a consistent minimum height for the card
            }}>
                <Card.Body style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', borderRadius: '8px', padding: '20px' }}>
                    {/* Tabs at the Top */}
                    <Tabs
                        id="account-tabs"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        style={{ backgroundColor: '#ffffff', borderRadius: '8px' }}
                    >
                        <Tab eventKey="editProfile" title="Edit Profile"></Tab>
                        <Tab eventKey="files" title="Files"></Tab>
                    </Tabs>

                    {/* Edit Profile Content */}
                    {key === 'editProfile' && (
                        <div>
                            <Card.Title className="text-center card-title">Edit Profile</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={profileData.firstName}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={profileData.lastName}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formOldPassword">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="oldPassword"
                                        value={profileData.oldPassword}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formNewPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="newPassword"
                                        value={profileData.newPassword}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formVerifyNewPassword">
                                    <Form.Label>Verify New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="verifyNewPassword"
                                        value={profileData.verifyNewPassword}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formTokenCount" className="token-count-container">
                                    <Form.Label className="token-label">AI Tokens</Form.Label>
                                    <span className="token-count">{profileData.tokenCount}</span>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="w-100 mt-3"
                                    style={{ background: "linear-gradient(90deg, rgba(189, 38, 249, 1) 32%, rgba(82, 52, 226, 1) 100%)" }}
                                >
                                    Save Changes
                                </Button>
                            </Form>
                        </div>
                    )}

                    {/* Files Content */}
                    {key === 'files' && (
                        <div>
                            <Card.Title className="text-center card-title">Your Files</Card.Title>
                            {files.length > 0 ? (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>File Name</th>
                                            <th>Uploaded At</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {files.map((file, index) => (
                                            <tr key={file.id}>
                                                <td>{index + 1}</td>
                                                <td>{file.name}</td>
                                                <td>{file.uploadedAt}</td>
                                                <td>{file.status}</td>
                                                <td style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        disabled={file.status === 'Inactive'}
                                                        onClick={() => handleViewFile(file.id, file.name)}
                                                    >
                                                        View
                                                    </Button>
                                                    <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        disabled={file.status === 'Inactive'}
                                                    >
                                                        Restart
                                                    </Button>
                                                    <Button variant="danger" size="sm"
                                                        disabled={file.status === 'Inactive'}>
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>You have no files uploaded.</p>
                            )}
                        </div>
                    )}
                </Card.Body>
            </Card>
        </Col>
    </Row>
</Container>

    );
};

export default EditAccount;