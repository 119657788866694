import React, { useState } from "react";
import { Container } from "react-bootstrap";
import XactimateStepOne from "./XactimateStepOne";
import XactimateStepTwo from "./XactimateStepTwo";
import XactimateStepThree from "./XactimateStepThree";

const XactimateStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [estimatePdf, setEstimatePdf] = useState(null);
  const [customEstimateData, setCustomEstimateData] = useState(null);
  const [lineItems, setLineItems] = useState(null);
  const [propertyPdf, setPropertyPdf] = useState(null);

  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      if (currentStep === 2) {
        setEstimatePdf(null);
        setPropertyPdf(null);
      }
    }
  };

  const goToStepTwo = (step, estimate, property, customPdf) => {
    if (step === 1) {
      console.log("Step 1", estimate, property, customPdf);
      setEstimatePdf(estimate);
      setPropertyPdf(property);
      setCustomEstimateData(customPdf);
      setCurrentStep(1);
    }
  };

  const goToStepThree = (step, estimate, property, lineItems) => {
    if (step === 2) {
      setEstimatePdf(estimate);
      setPropertyPdf(property);
      // setCustomEstimateData(customPdf);
      setLineItems(lineItems);
      console.log("Step 2", estimate + property  + lineItems);
      setCurrentStep(2);
    }
  };

  const steps = [
    {
      label: "Upload Files",
      content: (
        <XactimateStepOne
          currentStep={currentStep}
          goToNextStep={goToNextStep}
          goToStepTwo={goToStepTwo}
        />
      ),
      subheading: "Upload estimate and property measurements files",
    },
    {
      label: "Step 2",
      content: (
        <XactimateStepTwo
          goToNextStep={goToNextStep}
          estimatePdf={estimatePdf}
          propertyPdf={propertyPdf}
          customEstimateData={customEstimateData}
          goToStepThree={goToStepThree}
        />
      ),
      subheading: "Process and verify data",
    },
    {
      label: "Step 3",
      content: (
        <XactimateStepThree
          estimatePdf={estimatePdf}
          propertyPdf={propertyPdf}
          customEstimateData={customEstimateData}
          data={lineItems}
        />
      ),
      subheading: "Review and finalize",
    },
  ];

  return (
    <div className="stepper">
  <div className="stepper-header">
    {steps.map((step, index) => (
      <div
        key={index}
        className={`stepper-step ${
          index === currentStep ? "active" : currentStep > index ? "completed" : ""
        }`}
      >
        <div className="d-flex justify-content-lg-center justify-content-start">
          <div className="stepper-step-number mx-2 fw-bold">
            {index < currentStep ? "✓" : index + 1}
          </div>
          <div className="stepper-step-label my-auto">
            <div className="label">{step.label}</div>
            <div className="subheading">{step.subheading}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
  <div className="stepper-content box-content">{steps[currentStep].content}</div>
  <div className="stepper-footer">
    <Container className="mx-auto">
      {/* {currentStep > 0 && <button onClick={goToPreviousStep}>Previous</button>} */}
      {/* {currentStep < steps.length - 1 && <button onClick={goToNextStep}>Next</button>} */}
    </Container>
  </div>
</div>

  );
};

export default XactimateStepper;
