import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  Image,
  Modal,
  ProgressBar // <-- Added ProgressBar import here
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo-2.png";
// Added all missing icons from react-icons
import { BiHelpCircle, BiArrowBack, BiCheckCircle } from "react-icons/bi";
import {
  GiEvilHand,
  GiNotebook,
  GiArchiveResearch,
  GiStairsGoal
} from "react-icons/gi";
import { toast } from "react-toastify";

const StartPage = () => {
  const navigate = useNavigate();

  // BANNER/MODAL state: whether to show the "go to billing" alert
  const [showBillingBanner, setShowBillingBanner] = useState(false);

  // Help modal
  const [showHelp, setShowHelp] = useState(false);
  const [helpPage, setHelpPage] = useState(1);
  const [helpClosed, setHelpClosed] = useState(false);

  // Token counts
  const [tokenCount, setTokenCount] = useState("0");
  const [tokenCountX, setTokenCountX] = useState("0");

  const helpContent = [
    {
      icon: <GiArchiveResearch className="help-icon bounce" />,
      title: "Getting Started Guide",
      text: "Welcome to the negotiation platform! Choose between starting a new negotiation with existing documents or creating a fresh Xactimate estimate from scratch."
    },
    {
      icon: <GiStairsGoal className="help-icon spin" />,
      title: "Navigation Steps",
      text: "1. Select your desired option\n2. Upload required documents\n3. Follow the guided process\n4. Review and submit your case"
    },
    {
      icon: <BiCheckCircle className="help-icon pulse" />,
      title: "Ready to Go!",
      text: "You're all set to start negotiating or creating estimates. Our system will guide you through each step of the process."
    }
  ];

  // 1) On load, check tokens in localStorage
  useEffect(() => {
    const storedTokenCountX = parseInt(localStorage.getItem("tokenCountX"), 10) || 0;
    const storedTokenCount = parseInt(localStorage.getItem("tokenCount"), 10) || 0;
    const tokenCountNum = storedTokenCount ? parseInt(storedTokenCount, 10) : 0;
    const tokenCountXNum = storedTokenCountX ? parseInt(storedTokenCountX, 10) : 0;

    setTokenCount(storedTokenCount || "0");
    setTokenCountX(storedTokenCountX || "0");

    // If either is 0 or null, show the banner
    if (
      (tokenCountNum === 0 || tokenCountNum === null || storedTokenCount === "0") &&
      (tokenCountXNum === 0 || tokenCountXNum === null || storedTokenCountX === "0")
    ) {
      setShowBillingBanner(true);
    }
  }, []);

  // 2) Show help modal 1s after page load


  const handleHelpClose = () => {
    setShowHelp(false);
    setHelpPage(1);
    setHelpClosed(true);
  };

  const handleHelpShow = () => setShowHelp(true);

  return (
    <Container className="my-5" style={{ minHeight: "80vh" }}>
      {/* MODAL for token alert */}
      <Modal
        show={showBillingBanner}
        onHide={() => setShowBillingBanner(false)}
        backdrop="static"
        keyboard={false}
        centered
        style={{
          fontFamily: "'Share Tech Mono', monospace", // Example futuristic font
        }}
      >
        <Modal.Header
          closeButton
          style={{
            background:
              "linear-gradient(45deg, rgba(66, 30, 78, 0.8), rgba(43, 37, 101, 0.8))",
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            color: "#fff",
          }}
        >
          <Modal.Title
            style={{
              fontWeight: "bold",
              letterSpacing: "1px",
              textShadow: "0 0 4px rgba(255,255,255,0.3)",
            }}
          >
            TOKEN ALERT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background:
              "linear-gradient(145deg, rgba(19, 19, 41, 0.9), rgba(36, 9, 56, 0.9))",
            color: "#fff",
            textAlign: "center",
            padding: "30px",
          }}
        >
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              textShadow: "0 0 4px rgba(255,255,255,0.2)",
              marginBottom: "10px",
            }}
          >
            YOU HAVE ZERO TOKENS AVAILABLE
          </p>
          <p
            style={{
              fontSize: "1rem",
              opacity: 0.85,
              marginBottom: "0",
            }}
          >
            PLEASE CHECK BILLING TO PURCHASE MORE.
          </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            background:
              "linear-gradient(145deg, rgba(19, 19, 41, 0.8), rgba(36, 9, 56, 0.8))",
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
            justifyContent: "center",
          }}
        >
          <Button
            variant="info"
            onClick={() => setShowBillingBanner(false)} // Closes the banner
            style={{
              fontWeight: "bold",
              letterSpacing: "1px",
              color: "#fff",
              background:
                "linear-gradient(45deg, rgba(31, 219, 255, 0.8), rgba(155, 81, 224, 0.8))",
              border: "none",
              boxShadow: "0 0 12px rgba(31, 219, 255, 0.5)",
            }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Main Card/Content */}
      <Card className="my-5 main-card justify-content-center flex-column gradient-bg mt-5 animate-pop-in">
        {/* Logo */}
        <Col
          lg={6}
          className="mx-auto text-center mb-3 logo-container"
          style={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <Image src={logo} fluid />
          <h1 className="mt-5 mb-4 slide-in-top">Choose Your Option</h1>
        </Col>

        {/* Enhanced Columns */}
        <Row className="justify-content-center">
          <Col
            lg={5}
            className="m-4 p-0 selection-column"
            onClick={() => navigate("/negotiator")}
          >
            <Card
              className="h-100 text-center ai-hover-card"
              style={{
                background:
                  "linear-gradient(145deg, rgba(224, 207, 241, 0.9), rgba(199, 211, 240, 0.9))",
                border: "1px solid rgba(0, 243, 255, 0.2)",
                borderRadius: "16px",
                boxShadow: "0 8px 32px rgba(0, 243, 255, 0.1)",
                minHeight: "50vh",
                backdropFilter: "blur(10px)",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div className="holographic-overlay"></div>
              <div className="particle-field">
                {[...Array(10)].map((_, i) => (
                  <div key={i} className="binary-particle">
                    {Math.random() > 0.5 ? "0" : "1"}
                  </div>
                ))}
              </div>

              <Card.Body className="d-flex flex-column justify-content-center p-4">
                <div className="icon-wrapper mb-4">
                  <div className="holographic-icon-container">
                    <GiEvilHand
                      className="selection-icon animate-float"
                      style={{
                        color: "#00f3ff",
                        fontSize: "4rem",
                        filter: "drop-shadow(0 0 12px rgba(0, 243, 255, 0.6))",
                      }}
                    />
                    <div className="icon-glow"></div>
                  </div>
                </div>

                <h2
                  className="neon-text mb-3"
                  style={{
                    fontWeight: 600,
                    background: "linear-gradient(45deg, #00f3ff, #8a2be2)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Start Negotiating
                </h2>

                <p
                  className="text-muted mb-4"
                  style={{
                    fontSize: "1.1rem",
                    color: "rgba(255, 255, 255, 0.8)",
                    textShadow: "0 0 8px rgba(0, 243, 255, 0.3)",
                  }}
                >
                  Upload existing estimates and property measurements to begin negotiations
                </p>
                <div
                  className="token-counter mb-2"
                  style={{
                    background: "rgba(0, 243, 255, 0.1)",
                    padding: "8px 15px",
                    borderRadius: "20px",
                    display: "inline-block",
                    margin: "0 auto",
                    border: "1px solid rgba(0, 243, 255, 0.3)"
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                      color: "#00f3ff",
                      textShadow: "0 0 5px rgba(0, 243, 255, 0.5)"
                    }}
                  >
                    Tokens: <b>{tokenCount}</b>
                  </span>
                </div>
                <div
                  className="hover-indicator"
                  style={{
                    width: "80px",
                    height: "4px",
                    background: "linear-gradient(90deg, #00f3ff, #8a2be2)",
                    margin: "0 auto",
                    borderRadius: "2px",
                    opacity: 0.8,
                    transition: "all 0.3s ease",
                    boxShadow: "0 0 12px rgba(149, 153, 153, 0.6)",
                  }}
                ></div>
              </Card.Body>
              <div className="edge-glow"></div>
            </Card>
          </Col>

          <Col
            lg={5}
            className="m-4 p-0"
            onClick={() => navigate("/estimateStepper")}
          >
            <Card
              className="h-100 text-center ai-hover-card slide-in-right"
              style={{
                background:
                  "linear-gradient(145deg, rgba(236, 214, 248, 0.9), rgba(195, 197, 244, 0.9))",
                border: "1px solid rgba(0, 242, 255, 0.4)",
                borderRadius: "16px",
                boxShadow: "0 8px 32px rgba(0, 243, 255, 0.1)",
                minHeight: "50vh",
                backdropFilter: "blur(10px)",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div className="holographic-overlay"></div>
              <div className="particle-field">
                {[...Array(10)].map((_, i) => (
                  <div key={i} className="binary-particle">
                    {Math.random() > 0.5 ? "0" : "1"}
                  </div>
                ))}
              </div>

              <div className="glossy-overlay"></div>
              <Card.Body className="d-flex flex-column justify-content-center p-4">
                <div className="icon-wrapper mb-4">
                  <div className="holographic-icon-container">
                    <GiNotebook
                      className="selection-icon animate-float-delayed"
                      style={{
                        color: "#00f3ff",
                        fontSize: "4rem",
                        filter: "drop-shadow(0 0 12px rgba(0, 243, 255, 0.6))",
                      }}
                    />
                    <div className="icon-glow"></div>
                  </div>
                </div>

                <h2
                  className="neon-text mb-3"
                  style={{
                    fontWeight: 600,
                    background: "linear-gradient(45deg, #00f3ff, #8a2be2)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Xactimate AI Super Agent
                </h2>

                <p
                  className="text-muted mb-4"
                  style={{
                    fontSize: "1.1rem",
                    color: "rgba(255, 255, 255, 0.8)",
                    textShadow: "0 0 8px rgba(0, 243, 255, 0.3)",
                  }}
                >
                  Create a new Xactimate estimate from scratch with AI-powered assistance
                </p>
                <div
                  className="token-counter mb-2"
                  style={{
                    background: "rgba(0, 243, 255, 0.1)",
                    padding: "8px 15px",
                    borderRadius: "20px",
                    display: "inline-block",
                    margin: "0 auto",
                    border: "1px solid rgba(0, 243, 255, 0.3)"
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                      color: "#00f3ff",
                      textShadow: "0 0 5px rgba(0, 243, 255, 0.5)"
                    }}
                  >
                    Xactimate Tokens: <b>{tokenCountX}</b>
                  </span>
                </div>
                <div
                  className="hover-indicator"
                  style={{
                    width: "80px",
                    height: "4px",
                    background: "linear-gradient(90deg, #00f3ff, #8a2be2)",
                    margin: "0 auto",
                    borderRadius: "2px",
                    opacity: 0.8,
                    transition: "all 0.3s ease",
                    boxShadow: "0 0 12px rgba(0, 243, 255, 0.6)",
                  }}
                ></div>
              </Card.Body>
              <div className="edge-glow"></div>
            </Card>
          </Col>
        </Row>

        {/* HELP BUTTON */}
        <Row className="justify-content-center">
          <Button
            variant="primary"
            className={`help-button rounded-circle ${helpClosed ? "pulse" : ""}`}
            style={{
              width: "60px",
              height: "60px",
              fontSize: "28px",
              boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(45deg,rgb(43, 157, 223),rgb(112, 11, 149))",
              border: "none",
            }}
            onClick={handleHelpShow}
          >
            <BiHelpCircle />
          </Button>
        </Row>
      </Card>

      {/* Help Modal */}
      <Modal
        show={showHelp}
        onHide={handleHelpClose}
        centered
        size="lg"
        className="help-modal"
      >
        <Modal.Header className="gradient-bg">
          <Modal.Title>
            Oliver's Guide - Step {helpPage} of 3
            <ProgressBar
              now={(helpPage / 3) * 100}
              style={{ height: "4px", marginTop: "15px" }}
              variant="warning"
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="gradient-bg text-center py-5">
          <div
            className="help-content"
            key={helpPage}
            style={{
              minHeight: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <div className="icon-wrapper mb-4" style={{ fontSize: "4rem" }}>
              {helpContent[helpPage - 1].icon}
            </div>

            <h3 className="text-white mb-3">
              {helpContent[helpPage - 1].title}
            </h3>

            <p className="text-gray px-4" style={{ whiteSpace: "pre-line" }}>
              {helpContent[helpPage - 1].text}
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className=" justify-content-between">
          <Button
            variant="outline-secondary"
            onClick={() => setHelpPage(Math.max(1, helpPage - 1))}
            disabled={helpPage === 1}
          >
            <BiArrowBack /> Previous
          </Button>

          {helpPage === 3 ? (
            <Button
              variant="success gradient-bg"
              onClick={handleHelpClose}
              className="pulse-button"
            >
              Ready to Use! <BiCheckCircle />
            </Button>
          ) : (
            <Button
              variant="primary gradient-bg"
              onClick={() => setHelpPage(Math.min(3, helpPage + 1))}
            >
              Next Step <GiStairsGoal />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default StartPage;
